import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
// Mis Componentes
import EmpleadosRoutes from 'routes/empleados.routes';
import { hasPermissionTo } from 'services/authorization.service';
// import './EmpleadosPage.css';
import { useTranslation } from 'react-i18next';

export interface EmpleadosPageProps { }

const EmpleadosPage: React.FC<EmpleadosPageProps> = () => {
	const { t } = useTranslation();
	return (
		<section id='empleados'>
			<Row className='align-items-center mb-3'>
				<Col>
					<h3 className='font-weight-bold'>{t('sidebar.employees')}</h3>
				</Col>
				{hasPermissionTo('CREATE_EMPLEADO') && (
					<Col className='text-right'>
						<Link to='/r/empleados/nuevo'>
							<Button variant='danger' className='text-capitalize'>
								<FontAwesomeIcon icon={faPlus} className='mr-3' size='lg' />
								{t('employees.new')}
							</Button>
						</Link>
					</Col>
				)}
			</Row>
			<Card>
				<Card.Header className='bg-dark-orange'></Card.Header>
				<Card.Body>
					<EmpleadosRoutes />
				</Card.Body>
			</Card>
		</section>
	);
};

export default EmpleadosPage;
