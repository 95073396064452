import React from 'react';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import 'moment/locale/es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import './Datepicker.css';

export interface DatepickerProps {
   name: string;
   // labelText: string;
   isValidDate?: (currentDate: any, selectedDate: any) => boolean;
   setFieldValue: any;
   value: string;
}

const Datepicker: React.FC<DatepickerProps> = ({ name, isValidDate, setFieldValue, value }) => {
   let date;
   const handleDateChange = (value: string | moment.Moment) => {
      if (value instanceof moment) {
         date = moment(value).toISOString();
         setFieldValue(name, date);
      } else {
         setFieldValue(name, 'invalid');
      }
   };

   return (
      <div className='date-container position-relative'>
         <FontAwesomeIcon icon={faCalendarAlt} className='position-absolute' size='lg' />
         <DateTime
            locale='es'
            inputProps={{ name, className: 'form-control date-input', readOnly: true }}
            closeOnSelect={true}
            dateFormat='DD/MM/YYYY'
            timeFormat={false}
            isValidDate={
               isValidDate
                  ? isValidDate
                  : (currentDate: any, selectedDate: any) => {
                       return true;
                    }
            }
            onChange={handleDateChange}
            value={value ? new Date(value) : undefined}
         />
      </div>
   );
};

export default Datepicker;
