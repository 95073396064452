import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ValidacionDetalles from 'components/Validaciones/Validacion';
import Validaciones from 'components/Validaciones/Validaciones';

const ValidacionRoutes: React.FC = () => {
   return (
      <Switch>
         <Route path={`/r/validaciones/:id/detalles`} component={ValidacionDetalles} />
         <Route path={`/r/validaciones`} component={Validaciones} />
      </Switch>
   );
};

export default ValidacionRoutes;
