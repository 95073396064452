import React from 'react';
import { Switch, Route } from 'react-router-dom';
// import MonitorPage from 'components/pages/MonitorPage';
import DrivesPage from 'components/pages/DrivesPage';
import NotificacionesPage from 'components/pages/NotificacionesPage';
import ReportesPage from 'components/pages/ReportesPage';
import EmpleadosPage from 'components/pages/EmpleadosPage';
import UsuariosPage from 'components/pages/UsuariosPage';
import PosicionamientoPage from 'components/pages/PosicionamientoPage';
import ValidacionesPage from 'components/pages/ValidacionesPage';

const DashboardRoutes: React.FC = () => {
	return (
		<Switch>
			{/* <Route path={`/r/monitor`} component={MonitorPage} /> */}

			<Route path={`/r/drives`} component={DrivesPage} />

			<Route path={`/r/empleados`} component={EmpleadosPage} />

			<Route path={`/r/usuarios`} component={UsuariosPage} />

			<Route path={`/r/reportes`} component={ReportesPage} />

			<Route path={`/r/notificaciones`} component={NotificacionesPage} />

			<Route path={`/r/posicionamientos`} component={PosicionamientoPage} />

			<Route path={`/r/validaciones`} component={ValidacionesPage} />
		</Switch>
	);
};

export default DashboardRoutes;
