import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { Tab, Tabs, Button, Form, InputGroup, FormControl, Row, Col, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faSyncAlt, faEraser, faSearch, faEdit } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import 'moment/locale/es';
import Select from 'react-virtualized-select';
// Mis Componentes
import http from 'services/http.service';
import { hasPermissionTo } from 'services/authorization.service';
// Mis Types
import { Pagination, Filters } from 'typings/tablas';
import { Option } from 'typings';
import useDebounce from 'hooks/useDebounce';

import i18n from '../../utils/i18n';

const estatusOptions: Option[] = [
	{
		value: 1,
		label: i18n.t('datatable.active'),
	},
	{
		value: 0,
		label: i18n.t('datatable.inactive'),
	},
];
const estatusFormatter = (cell, row, rowIndex) => {
	if (row.activo) {
		return <Badge variant='success'>{i18n.t('datatable.active')}</Badge>;
	}
	return <Badge variant='danger'>{i18n.t('datatable.inactive')}</Badge>;
};
const optionsFormatter = (cell, row, rowIndex) => {
	return (
		<>
			{hasPermissionTo('UPDATE_DRIVE') && (
				<Link className='btn btn-warning mr-2' to={`/r/drives/${row.options}/editar`}>
					<FontAwesomeIcon icon={faEdit} size='lg' />
				</Link>
			)}
			<Link className='btn btn-outline-primary' to={`/r/drives/${row.options}/detalles`}>
				<FontAwesomeIcon icon={faList} size='lg' className='mr-2' />
				{i18n.t('datatable.details')}
			</Link>
		</>
	);
};
const columns = [
	{
		dataField: 'nombre',
		text: 'Drive',
		headerAlign: 'center',
		align: 'center',
		headerClasses: 'font-weight-bold text-dark',
		// filter: textFilter
	},
	{
		dataField: 'direccion',
		text: i18n.t('drives.drive_address'),
		headerAlign: 'center',
		align: 'center',
		headerClasses: 'font-weight-bold text-dark',
	},
	{
		dataField: 'segmento',
		text: i18n.t('drives.segment'),
		headerAlign: 'center',
		align: 'center',
		headerClasses: 'font-weight-bold text-dark',
	},
	{
		dataField: 'posicionamiento',
		text: i18n.t('drives.drive_positioning'),
		headerAlign: 'center',
		align: 'center',
		headerClasses: 'font-weight-bold text-dark',
	},
	{
		dataField: 'fechaRegistro',
		text: i18n.t('drives.opening_date'),
		headerAlign: 'center',
		align: 'center',
		headerClasses: 'font-weight-bold text-dark',
	},
	{
		dataField: 'activo',
		text: i18n.t('drives.status'),
		headerAlign: 'center',
		align: 'center',
		headerClasses: 'font-weight-bold text-dark',
		formatter: estatusFormatter,
	},
	{
		dataField: 'options',
		text: i18n.t('users.options'),
		isDummyField: true,
		headerAlign: 'center',
		align: 'center',
		headerClasses: 'font-weight-bold text-dark',
		formatter: optionsFormatter,
	},
];

export interface DrivesProps { }

const Drives: React.FC<DrivesProps> = () => {
	const [tabSeccionKey, setTabSeccionKey] = useState<string>('Lista');
	const [drives, setDrives] = useState<any[]>([]);
	const [segmentos, setSegmentos] = useState<any[]>([]);
	const [search, setSearch] = useState<string>('');
	const debouncedSearch = useDebounce(search.trim(), 500);
	const [filtros, setFiltros] = useState<Filters>({
		activo: null,
		segmento: null
	});
	const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
	const [pagination, setPagination] = useState<Pagination>({
		page: 1,
		totalSize: 10,
		sizePerPage: 10,
	});

	// Obtener la lista de grupos
	useEffect(() => {
		fetchDrives();
		fetchSegmentos();
		// eslint-disable-next-line
	}, [debouncedSearch, pagination.sizePerPage, pagination.page, filtros.estatus, filtros.segmento]);

	const fetchDrives = async () => {
		const { estatus, segmento } = filtros;
		const { page, sizePerPage: limit } = pagination;
		try {
			setIsTableLoading(true);

			const params = {
				...(debouncedSearch && { search: debouncedSearch }),
				...(estatus !== null && { activo: estatus }),
				...(segmento !== null && { segmento_id: segmento }),
				limit,
				page,
			};
			const { rows: drivesList, count: totalSize }: any = await http.get('drive', { params });
			setDrives(mapDrivesToViewModel(drivesList));
			setPagination({ ...pagination, totalSize });

			setIsTableLoading(false);
		} catch (error) {
			setIsTableLoading(false);
		}
	};

	const fetchSegmentos = async () => {
		try {
			const { rows: segmentosList }: any = await http.get('drive/segmentos');
			setSegmentos(mapSegmentosToSelect(segmentosList));
		} catch (error) {
			setIsTableLoading(false);
		}
	};

	const syncDrives = async () => {
		try {
			setIsTableLoading(true);

			await http.post('drive/caffeniodrives');

			setIsTableLoading(false);
		} catch (error) {
			setIsTableLoading(false);
			console.error(error);
		}
	};

	const mapDrivesToViewModel = (drives: any[]) => {
		return drives.map(drive => {
			return {
				id: drive.drive_id,
				nombre: drive.nombre,
				direccion: drive.direccion,
				posicionamiento: drive?.posicionamiento?.nombre || 'N/A',
				segmento: drive?.segmento?.nombre || 'N/A',
				fechaRegistro: moment(drive.fecha_registro).format('L'),
				activo: drive.activo,
				options: drive.drive_id,
			};
		});
	};

	const mapSegmentosToSelect = (segmentos: any[]) => {
		return segmentos.map(segmento => {
			return {
				value: segmento.segmento_id,
				label: segmento.nombre,
			};
		});
	};

	const handleSearchChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(input.value);
	};

	const handleRegistrosChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
		setPagination({
			...pagination,
			sizePerPage: Number(input.value),
		});
	};

	const handleSelectChange = option => {
		setFiltros({
			estatus: option ? option.value : null,
			segmento: filtros.segmento
		});
	};

	const handleSegmentoChange = option => {
		setFiltros({
			estatus: filtros.estatus,
			segmento: option ? option.value : null,
		});
	};

	const handleRefresh = async () => {
		await syncDrives();
		fetchDrives();
		fetchSegmentos();
	};

	const handleCleanFilters = () => {
		setSearch('');
		setFiltros({
			estatus: null,
		});
		setPagination({
			...pagination,
			sizePerPage: 10,
		});
	};

	const handleTableChange = (type, { page, sizePerPage }) => {
		setPagination({
			...pagination,
			page,
		});
	};

	return (
		<Tabs id='secciones' className='tabs-secciones mb-4' activeKey={tabSeccionKey} onSelect={key => setTabSeccionKey(key)}>
			<Tab eventKey='Lista' title={i18n.t('datatable.tab_title')}>
				<div className='row'>
					<div className='col-md-6'>
						<Form.Group as={Row} controlId='formHorizontalEmail'>
							<Form.Label column md={3} className='pr-md-0'>
								{i18n.t('datatable.records')}
							</Form.Label>
							<div className='col-md-2 pl-md-0'>
								<Form.Control
									as='select'
									name='sizePerPage'
									onChange={handleRegistrosChange}
									value={pagination.sizePerPage.toString()}>
									<option>10</option>
									<option>25</option>
									<option>50</option>
									<option>100</option>
								</Form.Control>
							</div>
						</Form.Group>
					</div>
					<div className='col-md-6'>
						<InputGroup className='mb-3'>
							<Button variant='danger' className='mr-3' onClick={handleRefresh}>
								<FontAwesomeIcon icon={faSyncAlt} size='lg' /> <span>{i18n.t('drives.sync')}</span>
							</Button>
							<OverlayTrigger
								key={'tooltip-filtros'}
								placement={'top'}
								overlay={<Tooltip id={`tooltip-filtros`}>{i18n.t('datatable.clean')}</Tooltip>}>
								<Button variant='light' className='mr-3' onClick={handleCleanFilters}>
									<FontAwesomeIcon icon={faEraser} size='lg' />
								</Button>
							</OverlayTrigger>
							<OverlayTrigger
								key={'tooltip-refresh'}
								placement={'top'}
								overlay={<Tooltip id={`tooltip-refresh`}>{i18n.t('datatable.refresh')}</Tooltip>}>
								<Button variant='light' className='mr-3' onClick={fetchDrives}>
									<FontAwesomeIcon icon={faSyncAlt} size='lg' />
								</Button>
							</OverlayTrigger>
							<InputGroup.Prepend>
								<InputGroup.Text id='basic-addon1'>
									<FontAwesomeIcon icon={faSearch} size='lg' />
								</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl placeholder={i18n.t('datatable.search')} onChange={handleSearchChange} />
						</InputGroup>
					</div>
				</div>

				<Row className='mb-3'>
					<Col md='3'>
						<Select
							name='estatus'
							placeholder={i18n.t('drives.status')}
							options={estatusOptions}
							searchable={false}
							clearable={true}
							onChange={handleSelectChange}
							value={filtros.estatus}
						/>
					</Col>
					<Col md='3'>
						<Select
							name='segmento'
							placeholder={i18n.t('drives.segment')}
							options={segmentos}
							searchable={false}
							clearable={true}
							onChange={handleSegmentoChange}
							value={filtros.segmento}
						/>
					</Col>
				</Row>

				<BootstrapTable
					bootstrap4
					striped
					classes='drives-table'
					keyField='id'
					data={drives}
					columns={columns}
					remote={{
						pagination: true,
						search: true,
					}}
					loading={isTableLoading}
					overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
					hover
					bordered={false}
					noDataIndication={i18n.t('datatable.nodata')}
					rowClasses={''}
					pagination={paginationFactory({
						page: pagination.page, // Specify the current page.
						totalSize: pagination.totalSize, // Total data size.
						sizePerPage: pagination.sizePerPage, // Specify the size per page.
						withFirstAndLast: false, // hide the going to first and last page button
						alwaysShowAllBtns: true, // always show the next and previous page button
						prePageText: i18n.t('datatable.previous'),
						nextPageText: i18n.t('datatable.next'),
						hideSizePerPage: true, // hide the size per page dropdown
						hidePageListOnlyOnePage: true,
					})}
					onTableChange={handleTableChange}
				/>

				<p className='text-right mb-0'>
					{i18n.t('datatable.showing')} {drives.length} {i18n.t('datatable.total_entries')} {pagination.totalSize} {i18n.t('datatable.entries')}
				</p>
			</Tab>
			{/* <Tab eventKey='Nuevo' title='Agregar nuevo Drive'></Tab> */}
		</Tabs>
	);
};

export default Drives;
