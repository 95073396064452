import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
import { Form as RBForm, Button, Row, Col } from 'react-bootstrap';
import Select from 'components/shared/SelectInput';
import Swal from 'sweetalert2';
// import moment from 'moment';
// Mis Componentes
import http from 'services/http.service';
import apiErrorHandler from 'services/apiErrorHandler.service';
import ErrorFeedback from 'components/shared/ErrorFeedback';
import { mapOptionsToViewModel } from 'utils';
// Mis typings
import { Option } from 'typings';
import { Pagination } from 'typings/tablas';

import i18n from '../../utils/i18n';

const tipoUsuarioOptions: Option[] = [
	{
		value: 'Coordinador',
		label: i18n.t('users.coordinator'),
	},
	{
		value: 'Supervisor',
		label: 'Supervisor',
	},
	{
		value: 'Lider',
		label: i18n.t('users.leader'),
	},
];

export interface UsuarioProps { }

const Usuario: React.FC<UsuarioProps> = () => {
	const optionsFormatter = (cell, row, rowIndex) => {
		return (
			<>
				<Button variant='danger' className='' onClick={() => handleRevokeDriveAssignment(row.id)}>
					<FontAwesomeIcon icon={faTimes} size='lg' />
				</Button>
			</>
		);
	};
	const columns = [
		{
			dataField: 'id',
			text: 'ID',
			headerAlign: 'center',
			align: 'center',
			headerClasses: 'font-weight-bold text-dark',
		},
		{
			dataField: 'nombre',
			text: 'Drive',
			headerAlign: 'center',
			align: 'center',
			headerClasses: 'font-weight-bold text-dark',
		},
		{
			dataField: 'direccion',
			text: i18n.t('users.address'),
			headerAlign: 'center',
			align: 'center',
			headerClasses: 'font-weight-bold text-dark',
		},
		{
			dataField: 'tipo',
			text: i18n.t('users.type'),
			headerAlign: 'center',
			align: 'center',
			headerClasses: 'font-weight-bold text-dark',
		},
		{
			dataField: 'options',
			text: i18n.t('users.options'),
			isDummyField: true,
			headerAlign: 'center',
			align: 'center',
			headerClasses: 'font-weight-bold text-dark',
			formatter: optionsFormatter,
		},
	];
	let { id } = useParams();
	let history = useHistory();
	const [usuario, setUsuario] = useState<any>({
		name: '',
		full_name: '',
		email: '',
		isAdmin: false,
	});
	const [drivesOptions, setDrivesOptions] = useState<Option[]>([]);
	const [drivesFullList, setDrivesFullList] = useState<any[]>([]);
	const [drives, setDrives] = useState<any[]>([]);
	const [pagination, setPagination] = useState<Pagination>({
		page: 1,
		totalSize: 10,
		sizePerPage: 10,
	});

	const usuarioSchema = Yup.object().shape({
		name: Yup.string().min(3, i18n.t('form.minchar')).max(50, i18n.t('login.maxchar')).required(i18n.t('form.required')),
		full_name: Yup.string().min(3, i18n.t('form.minchar')).max(50, i18n.t('login.maxchar')).required(i18n.t('form.required')),
		email: Yup.string().email(i18n.t('form.valid_email')).required(i18n.t('form.required')),
		isAdmin: Yup.boolean().required(i18n.t('form.required')),
	});

	// Obtener datos del Usuario
	useEffect(() => {
		const fetchUsuario = async () => {
			try {
				const usuarioDetalles: any = await http.get(`usuarios/${id}`);
				populate(usuarioDetalles);
			} catch (error) {
				apiErrorHandler('Usuarios', error);
			}
		};
		const populate = usuarioData => {
			setUsuario({
				name: usuarioData.unique_name,
				full_name: usuarioData.fullname,
				email: usuarioData.email,
				isAdmin: usuarioData.isAdmin,
			});
			setDrives(mapDrivesToViewModel(usuarioData.drives));
		};
		const mapDrivesToViewModel = (drivesData: any[]) => {
			return drivesData.map(drive => {
				return {
					id: drive.drive.drive_id,
					nombre: drive.drive.nombre,
					direccion: drive.drive.direccion,
					tipo: getTipoUsuario(drive),
					options: drive.drive.drive_id,
				};
			});
		};

		const fetchDrives = async () => {
			try {
				const params = {
					limit: 9999,
				};
				const { rows: drivesList }: any = await http.get(`drive`, { params });
				setDrivesOptions(mapOptionsToViewModel(drivesList));
				setDrivesFullList(drivesList);
			} catch (error) {
				apiErrorHandler('Drives', error);
			}
		};

		if (id) {
			fetchUsuario();
		} else {
			setUsuario({
				name: '',
				full_name: '',
				email: '',
				isAdmin: false,
			});
		}
		fetchDrives();
	}, [id]);

	const handleFormSubmit = async (values, actions) => {
		try {
			id ? await updateUsuario(values) : await createUsuario(values);

			const result = await Swal.fire({
				title: `${id ? i18n.t('users.swal_update') : i18n.t('users.swal_create')}`,
				icon: 'success',
				confirmButtonText: i18n.t('form.swal_button'),
				allowOutsideClick: false,
				customClass: {
					confirmButton: 'btn btn-primary px-5',
				},
				buttonsStyling: false,
			});
			if (result.value) {
				history.push('/r/usuarios');
			}
		} catch (error) {
			apiErrorHandler('Usuarios', error);
		}
	};
	const createUsuario = async (values: any) => {
		const body = {
			usuario: {
				unique_name: values.name,
				fullname: values.full_name,
				email: values.email,
				isAdmin: values.isAdmin,
			},
			drives: mapDrivesToBodyModel(),
		};
		await http.post('usuarios', body);
	};
	const mapDrivesToBodyModel = () => {
		return drives.map(drive => {
			return {
				drive_id: drive.id,
				...(drive.tipo === 'Coordinador' && { coordinador: true }),
				...(drive.tipo === 'Supervisor' && { supervisor: true }),
				...(drive.tipo === 'Lider' && { encargado: true }),
			};
		});
	};
	const updateUsuario = async (values: any) => {
		const body = {
			unique_name: values.name,
			fullname: values.full_name,
			email: values.email,
			isAdmin: values.isAdmin,
		};
		await http.put(`usuarios/${id}`, body);
	};

	const handleAssignDrive = async (driveId: number, tipoUsuario: string) => {
		const getTranslations = async () => {
			return {
				title: i18n.t('drives.swal_attention'),
				text: i18n.t('users.drive_exists')
			}
		}
		const { title, text } = await getTranslations();
		if (isDriveAssignedToUsuario(driveId)) {
			Swal.fire(title, text, 'warning');
			return;
		}
		if (id) {
			assignDriveToUsuario(driveId, tipoUsuario);
		} else {
			const driveFound = drivesFullList.find(drive => drive.drive_id === driveId);
			const newDrives = drives.concat({
				id: driveFound.drive_id,
				nombre: driveFound.nombre,
				direccion: driveFound.direccion,
				tipo: getTipoUsuario({
					...(tipoUsuario === 'Coordinador' && { coordinador: true }),
					...(tipoUsuario === 'Supervisor' && { supervisor: true }),
					...(tipoUsuario === 'Lider' && { encargado: true }),
				}),
				options: driveFound.drive_id,
			});
			setDrives(newDrives);
		}
	};
	const isDriveAssignedToUsuario = (driveId: number): boolean => {
		const driveFound = drives.find(drive => drive.id === driveId);
		if (driveFound) {
			return true;
		}
		return false;
	};
	const assignDriveToUsuario = async (driveId: number, tipoUsuario: string) => {
		try {
			const getTranslations = async () => {
				return {
					title: i18n.t('drive.swal_processing'),
					text: i18n.t('drive.swal_wait')
				}
			}
			const { title, text } = await getTranslations();
			Swal.fire(title, text, 'info');
			Swal.showLoading();

			const body = {
				...(tipoUsuario === 'Coordinador' && { coordinador: true }),
				...(tipoUsuario === 'Supervisor' && { supervisor: true }),
				...(tipoUsuario === 'Lider' && { encargado: true }),
			};
			await http.put(`usuarios/${id}/drives/${driveId}`, body);

			const driveFound = drivesFullList.find(drive => drive.drive_id === driveId);
			const newDrives = drives.concat({
				id: driveFound.drive_id,
				nombre: driveFound.nombre,
				direccion: driveFound.direccion,
				tipo: getTipoUsuario({
					...(tipoUsuario === 'Coordinador' && { coordinador: true }),
					...(tipoUsuario === 'Supervisor' && { supervisor: true }),
					...(tipoUsuario === 'Lider' && { encargado: true }),
				}),
				options: driveFound.drive_id,
			});
			setDrives(newDrives);

			Swal.close();
		} catch (error) {
			apiErrorHandler('Usuarios', error);
		}
	};

	const handleRevokeDriveAssignment = async (driveId: number) => {
		if (id) {
			const getTranslations = async () => {
				return {
					title: i18n.t('drives.swal_warning_title'),
					text: i18n.t('users.unbind_drive'),
					confirm: i18n.t('drives.swal_accept_button'),
					cancel: i18n.t('drives.swal_cancel_button')
				}
			}
			const { title, text, confirm, cancel } = await getTranslations();
			const result = await Swal.fire({
				title,
				text,
				icon: 'warning',
				confirmButtonText: confirm,
				cancelButtonText: cancel,
				showCancelButton: true,
				focusCancel: true,
				allowOutsideClick: false,
				customClass: {
					confirmButton: 'btn btn-success mr-3 px-4',
					cancelButton: 'btn btn-danger px-4',
				},
				buttonsStyling: false,
			});
			if (result.value) {
				revokeDriveAssignmentFromUsuario(driveId);
			}
		} else {
			setDrives(drives.filter(drive => drive.id !== driveId));
		}
	};
	const revokeDriveAssignmentFromUsuario = async (driveId: number) => {
		try {
			const getTranslations = async () => {
				return {
					title: i18n.t('drive.swal_processing'),
					text: i18n.t('drive.swal_wait')
				}
			}
			const { title, text } = await getTranslations();
			Swal.fire(title, text, 'info');
			Swal.showLoading();

			await http.delete(`usuarios/${id}/drives/${driveId}`);
			setDrives(drives.filter(drive => drive.id !== driveId));

			Swal.close();
		} catch (error) {
			apiErrorHandler('Usuarios', error);
		}
	};

	const handleTableChange = (type, { page, sizePerPage }) => {
		setPagination({
			...pagination,
			page,
		});
	};

	const getTipoUsuario = (drive: any) => {
		if (drive.coordinador) {
			return 'Coordinador';
		}
		if (drive.supervisor) {
			return 'Supervisor';
		}
		if (drive.encagado) {
			return 'Encargado';
		}
	};

	if (drivesOptions.length < 1) {
		return null;
	}
	return (
		<>
			<div className='mb-3'>
				<Link className='text-dark font-weight-bold' to='/r/usuarios'>
					<FontAwesomeIcon icon={faArrowLeft} className='mr-2' size='lg' />
					{id ? usuario?.fullname : i18n.t('users.create_title')}
				</Link>
			</div>
			<Formik initialValues={usuario} enableReinitialize={true} validationSchema={usuarioSchema} onSubmit={handleFormSubmit}>
				{({ isSubmitting, setFieldValue, values }: FormikProps<any>) => (
					<Form>
						<Row>
							<Col md='4'>
								<RBForm.Group controlId='name'>
									<RBForm.Label>{i18n.t('users.username')}</RBForm.Label>
									<Field name='name'>{({ field }) => <RBForm.Control {...field} placeholder='Nombre' />}</Field>
									<ErrorMessage name='name' component={ErrorFeedback} />
								</RBForm.Group>
							</Col>
							<Col md='4'>
								<RBForm.Group controlId='full_name'>
									<RBForm.Label>{i18n.t('users.fullname')}</RBForm.Label>
									<Field name='full_name'>
										{({ field }) => <RBForm.Control {...field} placeholder='Nombre completo' />}
									</Field>
									<ErrorMessage name='full_name' component={ErrorFeedback} />
								</RBForm.Group>
							</Col>
							<Col md='4'>
								<RBForm.Group controlId='email'>
									<RBForm.Label>{i18n.t('users.email')}</RBForm.Label>
									<Field name='email'>
										{({ field }) => <RBForm.Control {...field} placeholder='ejemplo@caffenio.com' />}
									</Field>
									<ErrorMessage name='email' component={ErrorFeedback} />
								</RBForm.Group>
							</Col>
						</Row>

						<Row>
							<Col md='4'>
								<RBForm.Group controlId='isAdmin'>
									<Field name='isAdmin'>
										{({ field }) => (
											<RBForm.Check
												{...field}
												custom
												type={'checkbox'}
												id={`custom-checkbox`}
												label={i18n.t('users.is_admin')}
												onChange={() => setFieldValue('isAdmin', !values.isAdmin)}
												checked={values.isAdmin}
											/>
										)}
									</Field>
									<ErrorMessage name='isAdmin' component={ErrorFeedback} />
								</RBForm.Group>
							</Col>
						</Row>

						<hr className='mb-3' />

						<Row>
							<Col md='4'>
								<RBForm.Group controlId='drive_id'>
									<RBForm.Label>{i18n.t('users.assign_drive')}</RBForm.Label>
									<Field name='drive_id'>
										{({ field }) => (
											<Select
												{...field}
												placeholder={i18n.t('users.select_drive_2')}
												options={drivesOptions}
												searchable={true}
												clearable={true}
												setFieldValue={setFieldValue}
											/>
										)}
									</Field>
									<ErrorMessage name='drive_id' component={ErrorFeedback} />
								</RBForm.Group>
							</Col>
							<Col md='4'>
								<RBForm.Group controlId='tipoUsuario'>
									<RBForm.Label>{i18n.t('users.user_type')}</RBForm.Label>
									<Field name='tipoUsuario'>
										{({ field }) => (
											<Select
												{...field}
												placeholder={i18n.t('users.select_type')}
												options={tipoUsuarioOptions}
												setFieldValue={setFieldValue}
											/>
										)}
									</Field>
									<ErrorMessage name='tipoUsuario' component={ErrorFeedback} />
								</RBForm.Group>
							</Col>
							<Col md='4' className='align-self-end'>
								<RBForm.Group controlId='drive_id'>
									<Button
										variant='danger'
										className='px-5'
										onClick={() => handleAssignDrive(values.drive_id, values.tipoUsuario)}
										disabled={!values.drive_id || !values.tipoUsuario}>
										{i18n.t('users.assign')}
									</Button>
								</RBForm.Group>
							</Col>
						</Row>

						<div className='font-weight-bold text-center mb-3'>{i18n.t('users.assigned_drives')}</div>
						<BootstrapTable
							bootstrap4
							striped
							classes='drives-table'
							keyField='id'
							data={drives}
							columns={columns}
							remote={{
								pagination: true,
								search: true,
							}}
							hover
							bordered={false}
							noDataIndication={i18n.t('datatable.nodata')}
							pagination={paginationFactory({
								page: pagination.page, // Specify the current page.
								totalSize: pagination.totalSize, // Total data size.
								sizePerPage: pagination.sizePerPage, // Specify the size per page.
								withFirstAndLast: false, // hide the going to first and last page button
								alwaysShowAllBtns: true, // always show the next and previous page button
								prePageText: i18n.t('datatable.previous'),
								nextPageText: i18n.t('datatable.next'),
								hideSizePerPage: true, // hide the size per page dropdown
								hidePageListOnlyOnePage: true,
							})}
							onTableChange={handleTableChange}
						/>
						<div className='text-center mt-4'>
							<Button variant='danger' className='font-weight-bold px-5' type='submit' disabled={isSubmitting}>
								{isSubmitting ? <FontAwesomeIcon icon={faSpinner} pulse size='lg' /> : id ? i18n.t('form.save_button') : i18n.t('form.create_button')}
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default Usuario;
