import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import ValidacionesRoutes from 'routes/validacion.routes';
import './DrivesPage.css';

import i18n from '../../utils/i18n';

export interface ValidacionesPageProps { }

const ValidacionesPage: React.FC<ValidacionesPageProps> = () => {
	return (
		<section id='validaciones'>
			<Row className='align-items-center mb-3'>
				<Col>
					<h3 className='font-weight-bold'>{i18n.t('sidebar.validations')}</h3>
				</Col>
			</Row>
			<Card>
				<Card.Header className='bg-dark-orange'></Card.Header>
				<Card.Body>
					<ValidacionesRoutes />
				</Card.Body>
			</Card>
		</section>
	);
};

export default ValidacionesPage;
