import React, { useCallback, useContext } from 'react';
// Mis Componentes
import GuiaHorariosTabContext from '../GuiaHorariosTabContext';
import { CELL_COLORS, CELL_STATUS, HORAS_JORNADA_LABORAL } from 'utils/constants';

export interface HoraCellProps {
   rowNumber: number;
   hora: string;
   totalHorasxDia: number;
}

const HoraCell: React.FC<HoraCellProps> = ({ rowNumber, hora, totalHorasxDia }) => {
   const {
      tablaProyecciones,
      tablaHorarioOriginal,
      tablaHorario,
      totalesHorario,
      // UTILS
      isHoraInactiva,
   } = useContext(GuiaHorariosTabContext);

   const getCellColor = useCallback(
      (row, hora, totalHorasxDia) => {
         if (
            (!tablaHorarioOriginal[row][hora]?.value &&
               tablaHorario[row][hora]?.value &&
               tablaHorario[row][hora]?.status === CELL_STATUS.Recomendado) ||
            (tablaHorarioOriginal[row][hora]?.value && tablaHorario[row][hora]?.value && totalHorasxDia > HORAS_JORNADA_LABORAL)
         ) {
            if (totalesHorario[`row-${row}`] > HORAS_JORNADA_LABORAL && totalHorasxDia > HORAS_JORNADA_LABORAL) {
               // Experimento. Horas extra ya guardadas.
               return CELL_COLORS.horaExtra;
            }
            return CELL_COLORS.manual;
         } else if (
            tablaHorarioOriginal[row][hora]?.value &&
            tablaHorario[row][hora]?.value &&
            tablaHorario[row][hora]?.status === CELL_STATUS.Recomendado
         ) {
            return CELL_COLORS.recomendado;
         } else if (
            !tablaHorarioOriginal[row][hora]?.value &&
            tablaHorario[row][hora]?.value &&
            tablaHorario[row][hora]?.status === CELL_STATUS.HoraExtra
         ) {
            if (totalHorasxDia > HORAS_JORNADA_LABORAL) {
               return CELL_COLORS.horaExtra;
            }
            return CELL_COLORS.manual;
         } else if (
            !tablaHorarioOriginal[row][hora]?.value &&
            tablaHorario[row][hora]?.value &&
            tablaHorario[row][hora]?.status === CELL_STATUS.Manual
         ) {
            return CELL_COLORS.manual;
         } else if (tablaProyecciones[`tickets_${hora}`] > 35) {
            return CELL_COLORS.horaPico;
         }
         return CELL_COLORS.horaLibre;
      },
      [tablaProyecciones, tablaHorarioOriginal, tablaHorario, totalesHorario],
   );

   return (
      <td>
         <div
            data-row={`${rowNumber}`}
            data-hora={hora}
            className={`d-flex align-items-center justify-content-center cell ${
               isHoraInactiva(hora) ? 'cell-disabled' : getCellColor(rowNumber, hora, totalHorasxDia)
            }`}>
            {tablaHorario[`${rowNumber}`]?.disponibilidad && tablaHorario[`${rowNumber}`]?.disponibilidad[hora] ? (
               <span className='font-weight-bold'>D</span>
            ) : null}
         </div>
      </td>
   );
};

export default HoraCell;
