import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Table, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
import { Form as RBForm, Button } from 'react-bootstrap';
import Datepicker from 'components/shared/Datepicker';
import Select from 'components/shared/SelectInput';
import Swal from 'sweetalert2';
// Mis Componentes
import http from 'services/http.service';
import ErrorFeedback from 'components/shared/ErrorFeedback';
import apiErrorHandler from 'services/apiErrorHandler.service';
import { mapOptionsToViewModel } from 'utils';
// Types
import { Option } from 'typings';
import { CELL_COLORS, DAYS } from 'utils/constants';

import i18n from '../../utils/i18n';

const estatusOptions: Option[] = [
	{
		value: true,
		label: i18n.t('datatable.active'),
	},
	{
		value: false,
		label: i18n.t('datatable.inactive'),
	},
];

export interface DriveFormProps { }

const DriveForm: React.FC<DriveFormProps> = () => {
	let { id } = useParams();
	let history = useHistory();
	const [drive, setDrive] = useState<any>({
		caffenio_uid: '',
		nombre: '',
		direccion: '',
		posicionamiento_id: null,
		fecha_registro: '',
		...(id ? { activo: true } : {}),
	});
	const [tablaHorarioDrive, setTablaHorarioDrive] = useState<any[]>([]);
	const [posicionamientoOptions, setPosicionamientoOptions] = useState<any[]>([]);

	const driveSchema = Yup.object().shape({
		caffenio_uid: Yup.number()
			.typeError(i18n.t('form.just_numbers'))
			.integer(i18n.t('form.just_integer'))
			.positive(i18n.t('form.just_positive'))
			.required(i18n.t('form.required')),
		nombre: Yup.string().min(3, i18n.t('form.minchar')).max(255, i18n.t('form.maxchar')).trim().required(i18n.t('form.required')),
		direccion: Yup.string().min(3, i18n.t('form.minchar')).max(255, i18n.t('form.maxchar')).trim().required(i18n.t('form.required')),
		posicionamiento_id: Yup.number().nullable(),
		...(id
			? { activo: Yup.boolean().required(i18n.t('form.required')) }
			: { fecha_registro: Yup.string().required(i18n.t('form.required')) }),
	});

	const fetchPosicionamientos = useCallback(async () => {
		try {
			const params = {
				limit: 999,
			};
			const { rows: PosicionamientosList }: any = await http.get('posicionamiento', { params });
			setPosicionamientoOptions(mapOptionsToViewModel(PosicionamientosList));
		} catch (error) {
			console.error(error);
		}
	}, []);
	useEffect(() => {
		fetchPosicionamientos();
	}, [fetchPosicionamientos]);

	// Obtener datos del drive
	useEffect(() => {
		const fetchDrive = async () => {
			try {
				const driveDetalles: any = await http.get(`drive/${id}`);
				populate(driveDetalles);
				setTablaHorarioDrive(mapHorarioDriveToTableModel(driveDetalles.horario_empresa));
			} catch (error) {
				console.error(error);
				apiErrorHandler('Drives', error);
			}
		};
		const populate = driveData => {
			setDrive({
				caffenio_uid: driveData.caffenio_uid,
				nombre: driveData.nombre,
				direccion: driveData.direccion,
				posicionamiento_id: driveData?.posicionamiento_id || null,
				fecha_registro: driveData.fecha_registro,
				activo: driveData.activo,
			});
		};
		const mapHorarioDriveToTableModel = disponibilidad => {
			return disponibilidad.map(dia => {
				delete dia.drive_id;
				delete dia.dia_semana;
				return {
					...dia,
				};
			});
		};

		if (id) {
			fetchDrive();
		} else {
			setDrive({
				caffenio_uid: '',
				nombre: '',
				direccion: '',
				fecha_registro: '',
				...(id ? { activo: true } : {}),
			});
		}
	}, [id]);

	const handleFormSubmit = async (values, actions) => {
		try {
			id ? await updateDrive(values) : await createDrive(values);

			const result = await Swal.fire({
				title: `${id ? i18n.t('form.swal_update') : i18n.t('form.swal_create')}`,
				icon: 'success',
				confirmButtonText: i18n.t('form.swal_button'),
				allowOutsideClick: false,
				customClass: {
					confirmButton: 'btn btn-primary px-5',
				},
				buttonsStyling: false,
			});
			if (result.value) {
				history.push('/r/drives');
			}
		} catch (error) {
			apiErrorHandler('Drives', error);
		}
	};
	const createDrive = async (values: any) => {
		const body = getBody(values);
		await http.post('drive', body);
	};
	const updateDrive = async (values: any) => {
		const body: any = getBody(values);
		delete body.fecha_registro;
		await http.put(`drive/${id}`, body);
		// Actualizar horario del drive
		await http.put(`drive/horario/${id}`, { horario: tablaHorarioDrive });
	};
	const getBody = values => {
		const body = {};
		for (const key of Object.keys(values)) {
			if (values[key] || values[key] === false) {
				body[key] = values[key];
			}
		}
		return body;
	};

	const getHorarioxDia = () => {
		return DAYS.map((day, index) => (
			<tr key={index}>
				<td className='text-left'>
					<div className='nombre-dia bg-white p-2'>{day}</div>
				</td>
				{getHorasColumns(index)}
			</tr>
		));
	};
	const getHorasColumns = (rowNumber: number) => {
		let columnas: any[] = [];
		for (const hora of Object.keys(tablaHorarioDrive[rowNumber])) {
			if (hora.includes('horario_drive')) {
				continue;
			}
			columnas.push(
				<td key={`${rowNumber}-${hora}`}>
					<div
						data-row={`${rowNumber}`}
						data-hora={`${hora}`}
						className={`cell ${getCellColor(rowNumber, hora)}`}
						onClick={handleHoraClick}></div>
				</td>,
			);
		}
		return columnas;
	};
	const getCellColor = (row, hora) => {
		if (tablaHorarioDrive[row][hora]) {
			return CELL_COLORS.manual;
		}
		return CELL_COLORS.horaLibre;
	};

	const handleHoraClick = ({ currentTarget }: React.MouseEvent<HTMLDivElement>) => {
		let row = currentTarget.attributes.getNamedItem('data-row')?.value || '';
		let hora = currentTarget.attributes.getNamedItem('data-hora')?.value || '';
		if (row && hora) {
			const tablaHorarioDriveMapped = tablaHorarioDrive.map((dia, index) => {
				if (Number(row) === index) {
					return {
						...dia,
						[hora]: !dia[hora],
					};
				}
				return dia;
			});
			setTablaHorarioDrive(tablaHorarioDriveMapped);
		}
	};

	return (
		<>
			<div className='mb-3'>
				<Link className='text-dark font-weight-bold' to='/r/drives'>
					<FontAwesomeIcon icon={faArrowLeft} className='mr-2' size='lg' />
					{drive?.nombre ? `${drive?.nombre}` : i18n.t('drives.create_title')}
				</Link>
			</div>
			<Formik initialValues={drive} enableReinitialize={true} validationSchema={driveSchema} onSubmit={handleFormSubmit}>
				{({ isSubmitting, setFieldValue }: FormikProps<any>) => (
					<Form>
						<Row>
							<Col md='4'>
								<RBForm.Group controlId='caffenio_uid'>
									<RBForm.Label>Caffenio ID</RBForm.Label>
									<Field name='caffenio_uid'>
										{({ field }) => <RBForm.Control {...field} type='caffenio_uid' placeholder='Caffenio ID' />}
									</Field>
									<ErrorMessage name='caffenio_uid' component={ErrorFeedback} />
								</RBForm.Group>
							</Col>
							<Col md='4'>
								<RBForm.Group controlId='nombre'>
									<RBForm.Label>{i18n.t('drives.drive_name')}</RBForm.Label>
									<Field name='nombre'>
										{({ field }) => <RBForm.Control {...field} type='nombre' placeholder={i18n.t('drives.drive_name')} />}
									</Field>
									<ErrorMessage name='nombre' component={ErrorFeedback} />
								</RBForm.Group>
							</Col>
							<Col md='4'>
								<RBForm.Group controlId='direccion'>
									<RBForm.Label>{i18n.t('drives.drive_address')}</RBForm.Label>
									<Field name='direccion'>
										{({ field }) => <RBForm.Control {...field} type='direccion' placeholder={i18n.t('drives.drive_address')} />}
									</Field>
									<ErrorMessage name='direccion' component={ErrorFeedback} />
								</RBForm.Group>
							</Col>
							<Col md='4'>
								<RBForm.Group controlId='posicionamiento_id'>
									<RBForm.Label>{i18n.t('drives.drive_positioning')}</RBForm.Label>
									<Field name='posicionamiento_id'>
										{({ field }) => (
											<Select
												{...field}
												placeholder={i18n.t('drives.drive_positioning')}
												options={posicionamientoOptions}
												clearable={false}
												searchable={true}
												setFieldValue={setFieldValue}
											/>
										)}
									</Field>
									<ErrorMessage name='posicionamiento_id' component={ErrorFeedback} />
								</RBForm.Group>
							</Col>

							{id ? (
								<Col md='4'>
									<RBForm.Group controlId='activo'>
										<RBForm.Label>{i18n.t('drives.status')}</RBForm.Label>
										<Field name='activo'>
											{({ field }) => (
												<Select
													{...field}
													options={estatusOptions}
													clearable={false}
													searchable={false}
													setFieldValue={setFieldValue}
												/>
											)}
										</Field>
										<ErrorMessage name='activo' component={ErrorFeedback} />
									</RBForm.Group>
								</Col>
							) : (
								<Col md='4'>
									<RBForm.Group controlId='fecha_registro'>
										<RBForm.Label>{i18n.t('drives.opening_date')}</RBForm.Label>
										<Field name='fecha_registro'>
											{({ field }) => <Datepicker {...field} setFieldValue={setFieldValue} />}
										</Field>
										<ErrorMessage name='fecha_registro' component={ErrorFeedback} />
									</RBForm.Group>
								</Col>
							)}
						</Row>

						{tablaHorarioDrive.length > 0 && (
							<>
								<p className='font-weight-bold text-center'>{i18n.t('schedules.title')}</p>
								<div className='row mb-4 justify-content-center'>
									<div className='col-md-10 bg-light'>
										<Table className='tabla-horario-disponibilidad-empleado' responsive borderless size='sm'>
											<thead className='text-center'>
												<tr>
													<th></th>
													<th>5am</th>
													<th>6am</th>
													<th>7am</th>
													<th>8am</th>
													<th>9am</th>
													<th>10am</th>
													<th>11am</th>
													<th>12pm</th>
													<th>1pm</th>
													<th>2pm</th>
													<th>3pm</th>
													<th>4pm</th>
													<th>5pm</th>
													<th>6pm</th>
													<th>7pm</th>
													<th>8pm</th>
													<th>9pm</th>
													<th>10pm</th>
													<th>11pm</th>
												</tr>
											</thead>
											<tbody className='text-center'>{getHorarioxDia()}</tbody>
										</Table>
									</div>
								</div>
							</>
						)}

						<div className='text-center mt-4'>
							<Button variant='danger' className='font-weight-bold px-5' type='submit' disabled={isSubmitting}>
								{isSubmitting ? <FontAwesomeIcon icon={faSpinner} pulse size='lg' /> : id ? i18n.t('form.save_button') : i18n.t('form.create_button')}
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default DriveForm;
