import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Alert as RBAlert, Button, Table } from 'react-bootstrap';
import Select from 'react-virtualized-select';
// Mis Types
import { Option } from 'typings';
import http from 'services/http.service';
import { DAYS } from 'utils/constants';

import { useTranslation } from 'react-i18next';

const CELL_COLORS = {
	recomendado: 'bg-green-recomendado',
	horaLibre: 'bg-white',
	horaPico: 'bg-green-horas-pico',
	manual: 'bg-green-manual',
	horaExtra: 'bg-green-hora-extra',
};

export interface PersonalTabProps {
	empleados: { list: any[]; options: Option[] };
}

const PersonalTab: React.FC<PersonalTabProps> = ({ empleados }) => {
	const [tablaDisponibilidad, setTablaDisponibilidad] = useState<any[]>([]);
	const [empleadoId, setEmpleadoId] = useState<number | null>(null);

	const { t } = useTranslation();

	useEffect(() => {
		const fetchEmpleado = async () => {
			try {
				const empleadoData: any = await http.get(`empleados/${empleadoId}`);
				setTablaDisponibilidad(mapDisponibilidadToTableModel(empleadoData.disponibilidad));
			} catch (error) {
				console.error(error);
			}
		};

		if (empleadoId) {
			fetchEmpleado();
		}
	}, [empleadoId]);

	const mapDisponibilidadToTableModel = disponibilidad => {
		return disponibilidad.map(dia => {
			delete dia.empleado_id;
			delete dia.disponibilidad_id;
			delete dia.dia;
			return {
				...dia,
			};
		});
	};

	const getHorarioRows = () => {
		return DAYS.map((day, index) => (
			<tr key={index}>
				<td className='text-left'>
					<div className='nombre-dia bg-white p-2'>{day}</div>
				</td>
				{getHorarioColumns(index)}
			</tr>
		));
	};

	const getHorarioColumns = (rowNumber: number) => {
		const horas = Object.keys(tablaDisponibilidad[rowNumber]);
		return horas.map(hora => (
			<td key={`${rowNumber}-${hora}`}>
				<div
					data-row={`${rowNumber}`}
					data-hora={`${hora}`}
					className={`cell cursor-disabled ${getCellColor(rowNumber, hora)}`}></div>
			</td>
		));
	};

	const getCellColor = (row, hora) => {
		if (tablaDisponibilidad[row][hora]) {
			return CELL_COLORS.manual;
		}
		return CELL_COLORS.horaLibre;
	};

	const handleEmpleadoChange = (option: Option) => {
		setEmpleadoId(Number(option.value));
	};

	return (
		<React.Fragment>
			<div className='row mb-3'>
				{/* <div className='col-md-3'>
               <div className='form-group'>
                  <label className='font-weight-bold' htmlFor='semana-select'>
                     Elige la semana a mostrar
                  </label>
                  <Select
                     name='semanaPersonal'
                     options={semanaOptions}
                     searchable={false}
                     clearable={false}
                     onChange={onSemanaPersonalChange}
                     value={semana}
                  />
               </div>
            </div> */}
				<div className='col-md-3'>
					<div className='form-group'>
						<label className='font-weight-bold' htmlFor='semana-select'>
							{t('drives.employee')}
						</label>
						<Select
							name={`empleado_id`}
							noResultsText={t('drives.no_employee')}
							placeholder={t('drives.employee_select')}
							options={empleados.options}
							searchable={false}
							clearable={false}
							onChange={handleEmpleadoChange}
							value={empleadoId}
						/>
					</div>
				</div>
				<div className='col-md-3 text-left align-self-end'>
					{empleadoId ? (
						<Link
							to={{
								pathname: `/r/empleados/${empleadoId}/editar`,
							}}>
							<Button variant='success' className='mb-3 mr-3'>
								<FontAwesomeIcon icon={faEdit} size='lg' className='mr-2' />
								{t('drives.edit_button')}
							</Button>
						</Link>
					) : (
						<Button variant='success' className='mb-3 mr-3' disabled>
							<FontAwesomeIcon icon={faEdit} size='lg' className='mr-2' />
							{t('drives.edit_button')}
						</Button>
					)}
					{/* <Button variant='danger' className='mb-3'>
                  <FontAwesomeIcon icon={faPrint} size='lg' className='mr-2' />
                  Imprimir horarios semanales
               </Button> */}
				</div>
			</div>

			<div className='row'>
				<div className='col-12'>
					<p className='font-weight-bold'>{t('drives.employee_availability')}</p>
					{Object.keys(tablaDisponibilidad).length <= 0 ? (
						<RBAlert className='text-left' variant='info'>
							{t('drives.employee_unselected')}
						</RBAlert>
					) : (
						<div className='col-md-10 bg-light'>
							<Table className='tabla-horario-disponibilidad-empleado' responsive borderless size='sm'>
								<thead className='text-center'>
									<tr>
										<th></th>
										<th>5am</th>
										<th>6am</th>
										<th>7am</th>
										<th>8am</th>
										<th>9am</th>
										<th>10am</th>
										<th>11am</th>
										<th>12pm</th>
										<th>1pm</th>
										<th>2pm</th>
										<th>3pm</th>
										<th>4pm</th>
										<th>5pm</th>
										<th>6pm</th>
										<th>7pm</th>
										<th>8pm</th>
										<th>9pm</th>
										<th>10pm</th>
										<th>11pm</th>
									</tr>
								</thead>
								<tbody className='text-center'>{getHorarioRows()}</tbody>
							</Table>
						</div>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

export default PersonalTab;
