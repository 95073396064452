import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
// Mis Componentes
import UsuariosRoutes from 'routes/usuarios.routes';
import { useTranslation } from 'react-i18next';

export interface UsuariosPageProps { }

const UsuariosPage: React.FC<UsuariosPageProps> = () => {
	const { t } = useTranslation();
	return (
		<section id='usuarios'>
			<Row className='align-items-center mb-3'>
				<Col>
					<h3 className='font-weight-bold'>{t('sidebar.users')}</h3>
				</Col>
				<Col className='text-right'>
					<Link to='/r/usuarios/nuevo'>
						<Button variant='danger' className='text-capitalize'>
							<FontAwesomeIcon icon={faPlus} className='mr-3' size='lg' />
							{t('users.new')}
						</Button>
					</Link>
				</Col>
			</Row>
			<Card>
				<Card.Header className='bg-dark-orange'></Card.Header>
				<Card.Body>
					<UsuariosRoutes />
				</Card.Body>
			</Card>
		</section>
	);
};

export default UsuariosPage;
