import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
import { Form as RBForm, Button } from 'react-bootstrap';
import Select from 'components/shared/SelectInput';
import Swal from 'sweetalert2';
// Mis Componentes
import http from 'services/http.service';
import ErrorFeedback from 'components/shared/ErrorFeedback';
import { Row, Col } from 'react-bootstrap';
import { Option } from 'typings';
import { mapOptionsToViewModel } from 'utils';
import apiErrorHandler from 'services/apiErrorHandler.service';
import EmpleadoDisponibilidad from './EmpleadoDisponibilidad';
import { getCurrentUser } from 'services/authentication.service';
import { hasPermissionTo, getRol } from 'services/authorization.service';
import EmpleadoCapacitaciones from './EmpleadoCapacitaciones';
import { PUESTOS } from 'utils/constants';

import i18n from '../../utils/i18n';

const CAPACITACIONES_DEFAULT = [
	{
		rol_id: PUESTOS.Caja1,
		rol: i18n.t('roles.cash_register') + ' 1',
		nivel: 1,
	},
	{
		rol_id: PUESTOS.Caja2,
		rol: i18n.t('roles.cash_register') + ' 2',
		nivel: 1,
	},
	{
		rol_id: PUESTOS.Barista1,
		rol: i18n.t('roles.bartender') + ' 1',
		nivel: 1,
	},
	{
		rol_id: PUESTOS.Barista2,
		rol: i18n.t('roles.bartender') + ' 2',
		nivel: 1,
	},
	{
		rol_id: PUESTOS.Barista3,
		rol: i18n.t('roles.bartender') + ' 3',
		nivel: 1,
	},
	{
		rol_id: PUESTOS.Barista4,
		rol: i18n.t('roles.bartender') + ' 4',
		nivel: 1,
	},
	{
		rol_id: PUESTOS['Tablet/Corredor'],
		rol: i18n.t('roles.tablet'),
		nivel: 1,
	},
	{
		rol_id: PUESTOS.Corredor,
		rol: i18n.t('roles.hallway'),
		nivel: 1,
	},
	{
		rol_id: PUESTOS.Pantalla,
		rol: i18n.t('roles.screen'),
		nivel: 1,
	},
	{
		rol_id: PUESTOS.Preparador,
		rol: i18n.t('roles.preparer'),
		nivel: 1,
	},
];
const estatusOptions: Option[] = [
	{
		value: true,
		label: i18n.t('datatable.active'),
	},
	{
		value: false,
		label: i18n.t('datatable.inactive'),
	},
];

export interface EmpleadoProps { }

const Empleado: React.FC<EmpleadoProps> = () => {
	let { id } = useParams();
	let history = useHistory();
	const [empleado, setEmpleado] = useState<any>({
		nombre: '',
		apellidos: '',
		drive_id: null,
		caffenio_post_idEmpleado: null,
		capacitaciones: CAPACITACIONES_DEFAULT,
		...(id ? { activo: true } : {}),
	});
	const [tablaDisponibilidad, setTablaDisponibilidad] = useState<any[]>([]);
	const [drives, setDrives] = useState<Option[]>([]);

	const empleadoSchema = Yup.object().shape({
		nombre: Yup.string().min(3, i18n.t('form.minchar')).max(50, i18n.t('login.maxchar')).required(i18n.t('form.required')),
		apellidos: Yup.string().min(3, i18n.t('form.minchar')).max(50, i18n.t('login.maxchar')).required(i18n.t('form.required')),
		drive_id: Yup.number().nullable(),
		caffenio_post_idEmpleado: Yup.number().nullable(),
		...(id ? { activo: Yup.boolean().required(i18n.t('form.required')) } : {}),
	});

	// Obtener datos del empleado
	useEffect(() => {
		const fetchEmpleado = async () => {
			try {
				const empleadoDetalles: any = await http.get(`empleados/${id}`);
				populate(empleadoDetalles);
				setTablaDisponibilidad(mapDisponibilidadToTableModel(empleadoDetalles.disponibilidad));
			} catch (error) {
				console.error(error);
				apiErrorHandler('Empleados', error);
			}
		};
		const populate = empleadoData => {
			setEmpleado({
				nombre: empleadoData.nombre,
				apellidos: empleadoData.apellidos,
				drive_id: empleadoData.drive_id,
				caffenio_post_idEmpleado: empleadoData.caffenio_post_idEmpleado,
				capacitaciones: mapCapacitacionesToViewModel(empleadoData.capacitaciones),
				activo: empleadoData.activo,
			});
		};
		const mapDisponibilidadToTableModel = disponibilidad => {
			return disponibilidad.map(dia => {
				delete dia.empleado_id;
				// delete dia.disponibilidad_id;
				delete dia.dia;
				return {
					...dia,
				};
			});
		};
		const mapCapacitacionesToViewModel = (capacitaciones: any[]) => {
			if (capacitaciones.length > 0) {
				return capacitaciones.map(capacitacion => ({
					rol_id: capacitacion.empleado_capacitaciones.rol_id,
					rol: capacitacion.nombre,
					nivel: capacitacion.empleado_capacitaciones.nivel,
				}));
			}
			return CAPACITACIONES_DEFAULT;
		};
		if (id) {
			fetchEmpleado();
		} else {
			const user = getCurrentUser();
			const userRol = getRol(user);
			const isLider = !['administrador', 'supervisor'].includes(userRol);
			setEmpleado({
				nombre: '',
				apellidos: '',
				drive_id: isLider ? user?.drives[0]?.drive_id : null,
				caffenio_post_idEmpleado: null,
				capacitaciones: CAPACITACIONES_DEFAULT,
				...(id ? { activo: true } : {}),
			});
			setTablaDisponibilidad([]);
		}

		const fetchDrives = async () => {
			try {
				const params = {
					limit: 9999,
				};
				const { rows: drivesList }: any = await http.get(`drive`, { params });
				setDrives(mapOptionsToViewModel(drivesList));
			} catch (error) {
				console.error(error);
				apiErrorHandler('Drives', error);
			}
		};
		fetchDrives();
	}, [id]);

	const handleFormSubmit = async (values, actions) => {
		try {
			id ? await updateEmpleado(values) : await createEmpleado(values);

			const result = await Swal.fire({
				title: `${id ? i18n.t('employees.swal_update') : i18n.t('employees.swal_create')}`,
				icon: 'success',
				confirmButtonText: i18n.t('form.swal_button'),
				allowOutsideClick: false,
				customClass: {
					confirmButton: 'btn btn-primary px-5',
				},
				buttonsStyling: false,
			});
			if (result.value) {
				history.push('/r/empleados');
			}
		} catch (error) {
			apiErrorHandler('Empleados', error);
		}
	};
	const createEmpleado = async (values: any) => {
		const body = getBody(values);
		await http.post('empleados', body);
		// Actualizar disponibilidad
		// await http.put(`empleados/disponibilidad/${id}`, { disponibilidad: tablaDisponibilidad });
	};
	const updateEmpleado = async (values: any) => {
		const body = getBody(values);
		await http.put(`empleados/${id}`, body);
		// Actualizar disponibilidad
		await http.put(`empleados/disponibilidad/${id}`, { disponibilidad: tablaDisponibilidad });
	};
	const getBody = values => {
		const body = {};
		for (const key of Object.keys(values)) {
			if (values[key] || values[key] === false) {
				if (key === 'capacitaciones') {
					body[key] = values[key].map(capacitacion => ({
						rol_id: capacitacion.rol_id,
						nivel: capacitacion.nivel,
					}));
				} else {
					body[key] = values[key];
				}
			}
		}
		return body;
	};

	if (drives.length < 1) {
		return null;
	}
	return (
		<>
			<div className='mb-3'>
				<Link className='text-dark font-weight-bold' to='/r/empleados'>
					<FontAwesomeIcon icon={faArrowLeft} className='mr-2' size='lg' />
					{empleado?.nombre ? `${empleado?.nombre} ${empleado?.apellidos}` : i18n.t('employees.create_title')}
				</Link>
			</div>
			<Formik initialValues={empleado} enableReinitialize={true} validationSchema={empleadoSchema} onSubmit={handleFormSubmit}>
				{({ isSubmitting, setFieldValue, values }: FormikProps<any>) => (
					<Form>
						<Row>
							<Col md='4'>
								<RBForm.Group controlId='nombre'>
									<RBForm.Label>{i18n.t('employees.name')}</RBForm.Label>
									<Field name='nombre'>
										{({ field }) => <RBForm.Control {...field} type='nombre' placeholder={i18n.t('employees.name')} />}
									</Field>
									<ErrorMessage name='nombre' component={ErrorFeedback} />
								</RBForm.Group>
							</Col>
							<Col md='4'>
								<RBForm.Group controlId='apellidos'>
									<RBForm.Label>{i18n.t('employees.last_name')}</RBForm.Label>
									<Field name='apellidos'>
										{({ field }) => <RBForm.Control {...field} type='apellidos' placeholder={i18n.t('employees.last_name')} />}
									</Field>
									<ErrorMessage name='apellidos' component={ErrorFeedback} />
								</RBForm.Group>
							</Col>
							<Col md='4'>
								<RBForm.Group controlId='drive_id'>
									<RBForm.Label>{i18n.t('employees.assign_to_drive')}</RBForm.Label>
									<Field name='drive_id'>
										{({ field }) => (
											<Select
												{...field}
												isDisabled={!hasPermissionTo('ASSIGN_DRIVE_TO_EMPLEADO')}
												options={drives}
												placeholder={i18n.t('employees.select_drive_2')}
												searchable={true}
												setFieldValue={setFieldValue}
											/>
										)}
									</Field>
									<ErrorMessage name='drive_id' component={ErrorFeedback} />
								</RBForm.Group>
							</Col>
							<Col md='4'>
								<RBForm.Group controlId='caffenio_post_idEmpleado'>
									<RBForm.Label>{i18n.t('employees.employee_number')}</RBForm.Label>
									<Field name='caffenio_post_idEmpleado'>
										{({ field }) => <RBForm.Control {...field} type='caffenio_post_idEmpleado' placeholder={i18n.t('employees.employee_number')} />}
									</Field>
									<ErrorMessage name='caffenio_post_idEmpleado' component={ErrorFeedback} />
								</RBForm.Group>
							</Col>
							{id ? (
								<Col md='4'>
									<RBForm.Group controlId='activo'>
										<RBForm.Label>{i18n.t('drives.status')}</RBForm.Label>
										<Field name='activo'>
											{({ field }) => <Select {...field} options={estatusOptions} setFieldValue={setFieldValue} />}
										</Field>
										<ErrorMessage name='activo' component={ErrorFeedback} />
									</RBForm.Group>
								</Col>
							) : null}
						</Row>

						{tablaDisponibilidad.length > 0 && (
							<EmpleadoDisponibilidad
								tablaDisponibilidad={tablaDisponibilidad}
								setTablaDisponibilidad={setTablaDisponibilidad}
							/>
						)}

						<EmpleadoCapacitaciones capacitaciones={values.capacitaciones} setFieldValue={setFieldValue} />

						{hasPermissionTo('UPDATE_EMPLEADO') && (
							<div className='text-center mt-4'>
								<Button variant='danger' className='font-weight-bold px-5' type='submit' disabled={isSubmitting}>
									{isSubmitting ? (
										<FontAwesomeIcon icon={faSpinner} pulse size='lg' />
									) : id ? (
										i18n.t('form.save_button')
									) : (
										i18n.t('form.create_button')
									)}
								</Button>
							</div>
						)}
					</Form>
				)}
			</Formik>
		</>
	);
};

export default Empleado;
