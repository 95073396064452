import React, { useCallback, useContext } from 'react';
import { Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
// Mis Componentes
import GuiaHorariosTabContext from '../GuiaHorariosTabContext';
import HorarioEmpleadoRow from './HorarioEmpleadoRow';
import i18n from '../../../../utils/i18n';

export interface TablaHorariosProps { }

const TablaHorarios: React.FC<TablaHorariosProps> = () => {
	const { tablaHorario, tablaHorarioOriginal, empleadosDisponibles } = useContext(GuiaHorariosTabContext);

	const getHorariosEmpleados = useCallback((): JSX.Element[] => {
		let horarioEmpleado: any[] = [];
		for (let i = 0; i < empleadosDisponibles; i++) {
			horarioEmpleado.push(<HorarioEmpleadoRow key={i} rowIndex={i} />);
		}
		return horarioEmpleado;
	}, [empleadosDisponibles]);

	return (
		<Table className='tabla-horario' borderless size='sm'>
			<thead className='text-center'>
				<tr>
					<th></th>
					<th>{i18n.t('drives.position')}</th>
					<th>{i18n.t('drives.sec_activity')}</th>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th>Hrs.</th>
					<OverlayTrigger placement={'top'} overlay={<Tooltip id={`tooltip-puesto`}>{i18n.t('drives.hours_per_week')}</Tooltip>}>
						<th>HS*</th>
					</OverlayTrigger>
				</tr>
			</thead>
			{Object.keys(tablaHorario).length > 0 &&
				Object.keys(tablaHorarioOriginal).length > 0 &&
				Object.keys(tablaHorario).length === empleadosDisponibles && (
					<tbody className='text-center'>{getHorariosEmpleados()}</tbody>
				)}
		</Table>
	);
};

export default TablaHorarios;
