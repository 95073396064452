import moment from 'moment';
import 'moment/locale/es';
import { Option } from 'typings';

export const mapOptionsToViewModel = (options: any[]): Option[] => {
   return options.map(option => {
      return {
         value: option[Object.keys(option)[0]],
         label: option.nombre || option.drive.nombre || `${option.nombre} ${option.apellidos}`,
      };
   });
};

export const generateYears = () => {
   let years: Option[] = [];
   const currentYear = new Date().getFullYear() + 1;
   for (let startYear = 2005; startYear <= currentYear + 1; startYear++) {
      years.push({ value: startYear, label: startYear.toString() });
   }
   return years;
};

export const generarSemanaOptions = (negativeOffset: number = 4, positiveNegative: number = 0) => {
   let options: any[] = [];
   const currentWeek = moment().week();
   for (let i = currentWeek - negativeOffset; i <= currentWeek + positiveNegative; i++) {
      let week = `${moment().week(i).startOf('week').format('L')} - ${moment().week(i).endOf('week').format('L')}`;
      let weekISO = `${moment().week(i).startOf('week').toISOString()},${moment().week(i).endOf('week').toISOString()}`;

      options.push({
         value: weekISO,
         label: week,
      });
   }

   return options;
};

export const capitalizeWord = (word: string) => {
   return word?.charAt(0) + word?.substring(1).toLowerCase();
};
