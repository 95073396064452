// LoadingOverlay.tsx
import React from 'react';
import './Loading.css'; // Asegúrate de importar los estilos

const LoadingOverlay: React.FC = () => {
	return (
		<div className="loading-overlay">
			<div className="spinner"></div>
		</div>
	);
};

export default LoadingOverlay;