import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// Mis Componentes
import GuiaHorariosTab from 'components/Validaciones/GuiaHorarioTab/GuiaHorariosTab';
import http from 'services/http.service';
import apiErrorHandler from 'services/apiErrorHandler.service';
import Swal from 'sweetalert2';
import { mapOptionsToViewModel } from 'utils';
// Mis Typings
import { Option } from 'typings';

import i18n from '../../utils/i18n';

export interface ValidacionProps { }

const Validacion: React.FC<ValidacionProps> = () => {
	let { id } = useParams();
	const [drive, setDrive] = useState<any>();
	const [tabSeccionKey, setTabSeccionKey] = useState<string>('Guia');

	// Selects
	const [empleados, setEmpleados] = useState<{ list: any[]; options: Option[] }>({
		list: [],
		options: [],
	});

	// Obtener datos del drive
	useEffect(() => {
		const fetchDrive = async () => {
			try {

				const getTranslations = async () => {
					return {
						title: i18n.t('drives.swal_processing'),
						text: i18n.t('drives.swal_wait')
					}
				}

				const { title, text } = await getTranslations();

				Swal.fire(title, text, 'info');
				Swal.showLoading();

				const driveDetalles: any = await http.get(`drive/${id}`);
				setDrive(driveDetalles);

				Swal.close();
			} catch (error) {
				apiErrorHandler('Guia', error);
			}
		};

		if (id) {
			fetchDrive();
		}
	}, [id]);

	useEffect(() => {
		const fetchEmpleados = async () => {
			try {
				const params = {
					activo: 1,
					drive_id: drive.drive_id,
					limit: 9999,
				};
				const { rows: empleadosList }: any = await http.get('empleados', { params });
				setEmpleados({
					list: empleadosList,
					options: mapOptionsToViewModel(empleadosList),
				});
			} catch (error) {
				apiErrorHandler('Empleados', error);
			}
		};

		if (drive) {
			fetchEmpleados();
		}
	}, [drive]);

	return (
		<>
			<Link className='text-dark font-weight-bold' to='/r/validaciones'>
				<FontAwesomeIcon icon={faArrowLeft} className='mr-2' size='lg' />
				{i18n.t('validations.validation')} {drive?.nombre}
			</Link>
			<Tabs
				id='secciones'
				className='tabs-secciones mt-3 mb-4'
				activeKey={tabSeccionKey}
				onSelect={key => setTabSeccionKey(key)}>
				<Tab eventKey='Guia' title={i18n.t('drives.schedule_guide')}>
					<GuiaHorariosTab drive={drive} empleadosData={empleados} isActiveTab={tabSeccionKey === 'Guia'} />
				</Tab>
			</Tabs>
		</>
	);
};

export default Validacion;
