import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
import { Alert as RBAlert, InputGroup, FormControl, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import ErrorFeedback from 'components/shared/ErrorFeedback';
import http from 'services/http.service';
import { Alert } from 'typings';

const initialValues = {
   username: '',
};
export interface ForgotPasswordProps {}

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
   const [genericAlert, setGenericAlert] = useState<Alert>({
      isOpen: false,
      variant: undefined,
      message: '',
   });

   const forgotSchema = Yup.object().shape({
      username: Yup.string().min(6, 'Mínimo 6 caracteres').required('Campo requerido'),
   });

   const handleFormSubmit = async (values, actions) => {
      try {
         await http.post('auth/forgot', {
            username: values.username,
         });
         setGenericAlert({
            isOpen: true,
            variant: 'success',
            message: 'Por favor, revisa tu bandeja de correos para más instrucciones.',
         });
         actions.setSubmitting(false);
      } catch (error) {
         actions.setSubmitting(false);
         if (error.type === 'Auth/AccountNotFound') {
            setGenericAlert({
               isOpen: true,
               variant: 'danger',
               message: 'El correo introducido no existe.',
            });
         }
      }
   };

   return (
      <>
         <div className='mb-3'>
            <small className='text-muted'>
               Puedes recuperar tu contraseña con el coreo electrónico asociado a tú cuenta. Las instrucciones serán enviadas a tu
               correo.
            </small>
         </div>
         {genericAlert.isOpen ? (
            <RBAlert className='text-left' variant={genericAlert.variant}>
               {genericAlert.message}
            </RBAlert>
         ) : null}
         <Formik initialValues={initialValues} validationSchema={forgotSchema} onSubmit={handleFormSubmit}>
            {({ isSubmitting }: FormikProps<any>) => (
               <Form className='login-form'>
                  <div className='mb-3'>
                     <Field name='username'>
                        {({ field }) => (
                           <InputGroup>
                              <InputGroup.Prepend>
                                 <InputGroup.Text id='username'>
                                    <FontAwesomeIcon icon={faUserCircle} />
                                 </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                 {...field}
                                 type='text'
                                 placeholder='Nombre de usuario'
                                 aria-label='username'
                                 aria-describedby='username'
                              />
                           </InputGroup>
                        )}
                     </Field>
                     <ErrorMessage name='username' component={ErrorFeedback} />
                  </div>

                  <Button variant='danger' type='submit' block disabled={isSubmitting}>
                     {isSubmitting ? <FontAwesomeIcon icon={faSpinner} size='lg' pulse /> : 'Enviar correo'}
                  </Button>

                  <hr className='my-3' />

                  <Link to='/login' className='text-danger'>
                     Ingresar
                  </Link>
               </Form>
            )}
         </Formik>
      </>
   );
};

export default ForgotPassword;
