import React from 'react';
import { Table } from 'react-bootstrap';
// Mis Componentes
import { CELL_COLORS, DAYS } from 'utils/constants';
import { useTranslation } from 'react-i18next';

export interface EmpleadoDisponibilidadProps {
	tablaDisponibilidad: any[];
	setTablaDisponibilidad: React.Dispatch<React.SetStateAction<any[]>>;
}

const EmpleadoDisponibilidad: React.FC<EmpleadoDisponibilidadProps> = ({ tablaDisponibilidad, setTablaDisponibilidad }) => {
	const { t } = useTranslation();
	// TODO: falta validar las horas especiales para que no puedan seleccionarlas.
	const getHorarioRows = () => {
		let rows: any[] = [];
		for (let i = 0; i < DAYS.length; i++) {
			rows.push(
				<tr key={i}>
					<td className='text-left'>
						<div className='nombre-dia bg-white p-2'>{DAYS[i]}</div>
					</td>
					{getHorarioColumns(i)}
				</tr>,
			);
		}
		return rows;
	};
	const getHorarioColumns = (rowNumber: number) => {
		let columnas: any[] = [];
		for (const hora of Object.keys(tablaDisponibilidad[rowNumber])) {
			if (hora.includes('disponibilidad')) {
				continue;
			}
			columnas.push(
				<td key={`${rowNumber}-${hora}`}>
					<div
						data-row={`${rowNumber}`}
						data-hora={`${hora}`}
						className={`cell ${getCellColor(rowNumber, hora)}`}
						onClick={handleHoraClick}></div>
				</td>,
			);
		}
		return columnas;
	};
	const getCellColor = (row, hora) => {
		if (tablaDisponibilidad[row][hora]) {
			return CELL_COLORS.manual;
		}
		return CELL_COLORS.horaLibre;
	};

	const handleHoraClick = ({ currentTarget }: React.MouseEvent<HTMLDivElement>) => {
		let row = currentTarget.attributes.getNamedItem('data-row')?.value || '';
		let hora = currentTarget.attributes.getNamedItem('data-hora')?.value || '';
		if (row && hora) {
			// Checar si el total es mayor a 8 horas y que la celda no estuviera seleccionada
			// if (getTotalHorasxDia(row) > 8 && !tablaDisponibilidad[row][hora]) {
			//    Swal.fire('Advertencia', 'Atención, estas asignando mas de 8 horas en un mismo día al empleado.', 'warning');
			// }

			const tablaDisponibilidadMapped = tablaDisponibilidad.map((dia, index) => {
				if (Number(row) === index) {
					return {
						...dia,
						[hora]: !dia[hora],
					};
				}
				return dia;
			});
			setTablaDisponibilidad(tablaDisponibilidadMapped);
		}
	};

	// const getTotalHorasxDia = (dia): number => {
	//    let horasTotalesxDia = 1;
	//    for (const [key, value] of Object.entries(tablaDisponibilidad[dia])) {
	//       if (!key.includes('disponibilidad') && value) {
	//          horasTotalesxDia += 1;
	//       }
	//    }
	//    return horasTotalesxDia;
	// };

	return (
		<>
			<p className='font-weight-bold text-center'>{t('employees.availability')}</p>
			<div className='row mb-4 justify-content-center'>
				<div className='col-md-10 bg-light'>
					<Table className='tabla-horario-disponibilidad-empleado' responsive borderless size='sm'>
						<thead className='text-center'>
							<tr>
								<th></th>
								<th>5am</th>
								<th>6am</th>
								<th>7am</th>
								<th>8am</th>
								<th>9am</th>
								<th>10am</th>
								<th>11am</th>
								<th>12pm</th>
								<th>1pm</th>
								<th>2pm</th>
								<th>3pm</th>
								<th>4pm</th>
								<th>5pm</th>
								<th>6pm</th>
								<th>7pm</th>
								<th>8pm</th>
								<th>9pm</th>
								<th>10pm</th>
								<th>11pm</th>
							</tr>
						</thead>
						<tbody className='text-center'>{getHorarioRows()}</tbody>
					</Table>
				</div>
			</div>
		</>
	);
};

export default EmpleadoDisponibilidad;
