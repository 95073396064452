import React from 'react';
import { NavLink } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCarSide, faFileAlt, faUsers, faUserTie, faTable, faCheckCircle, faBell } from '@fortawesome/free-solid-svg-icons';
// Mis Componentes
import Can from 'components/shared/Can';
import './Sidebar.css';

import i18n from '../../../utils/i18n';

export interface SidebarProps {
	onToggleClick: () => void;
	toggled: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ toggled, onToggleClick }) => {
	return (
		<div className={['sidebar', toggled ? 'collapsed' : ''].join(' ')}>
			<header className='sidebar-header' style={{ height: '55px' }}>
				<h3 className='text-uppercase'>{i18n.t('schedules.title')}</h3>
				<button type='button' className='btn btn-outline-light btn-toggle' onClick={onToggleClick}>
					<FontAwesomeIcon icon={faBars} />
				</button>
			</header>
			<ListGroup className='sidebar-links list-group-flush'>
				{/* <Can I="access" a="seccion_monitor">
          <NavLink className="mt-3" to="/r/monitor" activeClassName="active">
            <ListGroup.Item>
              <FontAwesomeIcon
                icon={faTachometerAlt}
                className="mr-2"
                fixedWidth
              />
              <span>Monitor</span>
            </ListGroup.Item>
          </NavLink>
        </Can> */}
				<Can I='access' a='seccion_drives'>
					<NavLink to='/r/drives' activeClassName='active'>
						<ListGroup.Item>
							<FontAwesomeIcon icon={faCarSide} className='mr-2' fixedWidth />
							<span>Drives</span>
						</ListGroup.Item>
					</NavLink>
				</Can>
				<Can I='access' a='seccion_empleados'>
					<NavLink to='/r/empleados' activeClassName='active'>
						<ListGroup.Item>
							<FontAwesomeIcon icon={faUsers} className='mr-2' fixedWidth />
							<span>{i18n.t('sidebar.employees')}</span>
						</ListGroup.Item>
					</NavLink>
				</Can>
				<Can I='access' a='seccion_usuarios'>
					<NavLink to='/r/usuarios' activeClassName='active'>
						<ListGroup.Item>
							<FontAwesomeIcon icon={faUserTie} className='mr-2' fixedWidth />
							<span>{i18n.t('sidebar.users')}</span>
						</ListGroup.Item>
					</NavLink>
				</Can>
				<Can I='access' a='seccion_reportes'>
					<NavLink to='/r/reportes' activeClassName='active'>
						<ListGroup.Item>
							<FontAwesomeIcon icon={faFileAlt} className='mr-2' fixedWidth />
							<span>{i18n.t('sidebar.reports')}</span>
						</ListGroup.Item>
					</NavLink>
				</Can>
				<Can I='access' a='seccion_notificaciones'>
					<NavLink to='/r/notificaciones' activeClassName='active'>
						<ListGroup.Item>
							<FontAwesomeIcon icon={faBell} className='mr-2' fixedWidth />
							<span>{i18n.t('sidebar.notifications')}</span>
						</ListGroup.Item>
					</NavLink>
				</Can>
				<Can I='access' a='seccion_posicionamiento'>
					<NavLink to='/r/posicionamientos' activeClassName='active'>
						<ListGroup.Item>
							<FontAwesomeIcon icon={faTable} className='mr-2' fixedWidth />
							<span>{i18n.t('sidebar.positioning')}</span>
						</ListGroup.Item>
					</NavLink>
				</Can>
				<Can I='access' a='seccion_validaciones'>
					<NavLink to='/r/validaciones' activeClassName='active'>
						<ListGroup.Item>
							<FontAwesomeIcon icon={faCheckCircle} className='mr-2' fixedWidth />
							<span>{i18n.t('sidebar.validations')}</span>
						</ListGroup.Item>
					</NavLink>
				</Can>
			</ListGroup>
		</div>
	);
};

export default Sidebar;
