import jwtDecode from 'jwt-decode';
import ability from 'services/authorization.service';

export function storeToken(token) {
   sessionStorage.setItem('token', token);
}

export function getToken() {
   return sessionStorage.getItem('token');
}

export function isTokenExpired(token) {
   try {
      const decoded = jwtDecode(token);
      if (decoded.exp < Date.now() / 1000) {
         return true;
      }
      return false;
   } catch (error) {
      return false;
   }
}

export async function refreshToken() {
   try {
      const token = getToken();
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}auth/refresh`, {
         headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         }),
         method: 'GET',
      });
      if (!response.ok) {
         logout();
         return null;
      }
      const newToken = await response.json();
      storeToken(newToken.token);
      return newToken.token;
   } catch (error) {
      console.error(error);
   }
}

export async function fetchUserInfo() {
   try {
      const token = getToken();
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}auth/me`, {
         headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         }),
         method: 'GET',
      });
      if (!response.ok) {
         logout();
         return;
      }
      const userInfo = await response.json();
      sessionStorage.setItem('user', JSON.stringify(userInfo));
      return userInfo;
   } catch (error) {
      console.error(error);
      return null;
   }
}

export function getCurrentUser() {
   const userInfo = sessionStorage.getItem('user');
   if (userInfo) {
      return JSON.parse(userInfo);
   } else {
      return null;
   }
}

export function logout() {
   sessionStorage.removeItem('token');
   sessionStorage.removeItem('user');
   ability.update([]); // Quita todas las reglas de los permisos
}
