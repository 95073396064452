import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import resources from '../locales/resources.json';

i18n
	.use(Backend)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: localStorage.getItem("language"), // if you're using a language detector, do not define the lng option
		fallbackLng: 'es',
		react: {
			useSuspense: false //   <---- this will do the magic
		},
		interpolation: {
			escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
		},
	});

export default i18n;