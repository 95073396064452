import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// Mis Componentes
import InformacionTab from 'components/Drives/driveTabs/InformacionTab';
import PersonalTab from 'components/Drives/driveTabs/PersonalTab';
import GuiaHorariosTab from 'components/Drives/driveTabs/GuiaHorariosTab/GuiaHorariosTab';
import http from 'services/http.service';
import apiErrorHandler from 'services/apiErrorHandler.service';
import Swal from 'sweetalert2';
import { mapOptionsToViewModel } from 'utils';
// Mis Typings
import { Option } from 'typings';

import { useTranslation } from 'react-i18next';

export interface DriveProps { }

const Drive: React.FC<DriveProps> = () => {
	let { id } = useParams();
	const [drive, setDrive] = useState<any>();
	const [tabSeccionKey, setTabSeccionKey] = useState<string>('Guia');

	// Selects
	const [empleados, setEmpleados] = useState<{ list: any[]; options: Option[] }>({
		list: [],
		options: [],
	});

	const { t } = useTranslation();

	// Obtener datos del drive
	useEffect(() => {

		const getTranslations = async () => {
			return {
				processing: t('drives.swal_processing'),
				waiting: t('drives.swal_wait')
			}
		}

		const fetchDrive = async () => {
			try {
				const { processing, waiting } = await getTranslations();
				Swal.fire(processing, waiting, 'info');
				Swal.showLoading();

				const driveDetalles: any = await http.get(`drive/${id}`);
				setDrive(driveDetalles);

				Swal.close();
			} catch (error) {
				apiErrorHandler('Guia', error);
			}
		};

		if (id) {
			fetchDrive();
		}
	}, [id]);

	useEffect(() => {
		const fetchEmpleados = async () => {
			try {
				const params = {
					activo: 1,
					drive_id: drive.drive_id,
					limit: 9999,
				};
				const { rows: empleadosList }: any = await http.get('empleados', { params });
				setEmpleados({
					list: empleadosList,
					options: mapOptionsToViewModel(empleadosList),
				});
			} catch (error) {
				apiErrorHandler('Empleados', error);
			}
		};

		if (drive) {
			fetchEmpleados();
		}
	}, [drive]);

	return (
		<>
			<Link className='text-dark font-weight-bold' to='/r/drives'>
				<FontAwesomeIcon icon={faArrowLeft} className='mr-2' size='lg' />
				Drive {drive?.nombre}
			</Link>
			<Tabs
				id='secciones'
				className='tabs-secciones mt-3 mb-4'
				activeKey={tabSeccionKey}
				onSelect={key => setTabSeccionKey(key)}>
				<Tab eventKey='Guia' title={t('drives.schedule_guide')}>
					<GuiaHorariosTab drive={drive} empleadosData={empleados} isActiveTab={tabSeccionKey === 'Guia'} />
				</Tab>
				<Tab eventKey='Personal' title={t('drives.staff_info')}>
					<PersonalTab empleados={empleados} />
				</Tab>
				<Tab eventKey='Informacion' title={t('drives.information')}>
					<InformacionTab drive={drive} />
				</Tab>
			</Tabs>
		</>
	);
};

export default Drive;
