import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Table, Button, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faEdit, faSpinner, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import cloneDeep from 'lodash/cloneDeep';
import Swal from 'sweetalert2';
import http from 'services/http.service';
import './Posicionamiento.css';
import apiErrorHandler from 'services/apiErrorHandler.service';
import i18n from '../../utils/i18n';

const RANGO_PATTERN = /^[0-9]{1,3}-[0-9]{1,3}$/;
const RANGOS_DEFAULT = [
	{
		rango_id: -1,
		rol_rango_id: -1,
		rango_ticket_inicio: 0,
		rango_ticket_fin: 14,
		roles_rangos: [],
	},
	{
		rango_id: -2,
		rol_rango_id: -2,
		rango_ticket_inicio: 15,
		rango_ticket_fin: 23,
		roles_rangos: [],
	},
	{
		rango_id: -3,
		rol_rango_id: -3,
		rango_ticket_inicio: 24,
		rango_ticket_fin: 34,
		roles_rangos: [],
	},
	{
		rango_id: -4,
		rol_rango_id: -4,
		rango_ticket_inicio: 35,
		rango_ticket_fin: 47,
		roles_rangos: [],
	},
	{
		rango_id: -5,
		rol_rango_id: -5,
		rango_ticket_inicio: 48,
		rango_ticket_fin: 61,
		roles_rangos: [],
	},
	{
		rango_id: -6,
		rol_rango_id: -6,
		rango_ticket_inicio: 62,
		rango_ticket_fin: 76,
		roles_rangos: [],
	},
	{
		rango_id: -7,
		rol_rango_id: -7,
		rango_ticket_inicio: 77,
		rango_ticket_fin: 92,
		roles_rangos: [],
	},
	{
		rango_id: -8,
		rol_rango_id: -8,
		rango_ticket_inicio: 93,
		rango_ticket_fin: 110,
		roles_rangos: [],
	},
];
export interface PosicionamientoTableProps { }

const PosicionamientoTable: React.FC<PosicionamientoTableProps> = () => {
	let { id } = useParams();
	let history = useHistory();
	const [posicionamiento, setPosicionamiento] = useState<any>({
		nombre: '',
	});
	const [roles, setRoles] = useState<any[]>([]);
	const [rangos, setRangos] = useState<any[]>(RANGOS_DEFAULT);
	const [rolesRangos, setRolesRangos] = useState<any[]>([]);
	const [rolRangoSelected, setRolRangoSelected] = useState({
		rango_id: null,
		rango: '',
	});
	const [isSubmitting, setIsSubmitting] = useState(false);

	const getRoles = useCallback(async () => {
		const getTranslations = async () => {
			return {
				title: i18n.t('drives.swal_processing'),
				text: i18n.t('drives.swal_wait')
			}
		}
		const { title, text } = await getTranslations();
		Swal.fire(title, text, 'info');
		Swal.showLoading();
		try {
			const params = {
				limit: 999,
			};
			const { rows: rolesData }: any = await http.get('roles', { params });
			setRoles(rolesData);
		} catch (error) {
			console.error(error);
		}
		Swal.close();
	}, []);

	const getPosicionamiento = useCallback(async () => {
		const getTranslations = async () => {
			return {
				title: i18n.t('drives.swal_processing'),
				text: i18n.t('drives.swal_wait')
			}
		}
		const { title, text } = await getTranslations();
		Swal.fire(title, text, 'info');
		Swal.showLoading();
		try {
			const posicionamientoData: any = await http.get(`posicionamiento/${id}`);
			setPosicionamiento({
				nombre: posicionamientoData.nombre,
			});
			setRangos(posicionamientoData?.posicionamiento_rangos);
		} catch (error) {
			console.error(error);
		}
		Swal.close();
	}, [id]);
	useEffect(() => {
		getRoles();
		if (id) {
			getPosicionamiento();
		}
	}, [id, getRoles, getPosicionamiento]);

	const rangosMemo = useMemo(() => {
		return rangos
			.sort((a: any, b: any) => a.rango_ticket_inicio - b.rango_ticket_inicio)
			.reduce((rangosAcc: any[], posicionamiento_rango: any) => {
				const rango = posicionamiento_rango.rango_ticket_inicio
					.toString()
					.concat('-', posicionamiento_rango.rango_ticket_fin);
				return [
					...rangosAcc,
					{
						rango_id: posicionamiento_rango.rango_id,
						rango,
					},
				];
			}, []);
	}, [rangos]);

	useEffect(() => {
		const newRolesRangos = roles.reduce((rolesAcc: any[], rol: any) => {
			const tableData = { ...rol, rolRangos: [] };

			rangos.forEach(posicionamiento_rango => {
				const asignado = posicionamiento_rango.roles_rangos.find(x => x.rol_id === rol.rol_id);
				const newCell: any = {
					rol_rango_id: asignado?.rol_rango_id || posicionamiento_rango.rol_rango_id,
					rango_id: posicionamiento_rango.rango_id,
					rango_ticket_inicio: posicionamiento_rango.rango_ticket_inicio,
					rango_ticket_fin: posicionamiento_rango.rango_ticket_fin,
					activo: asignado ? asignado.activo : false,
				};
				tableData.rolRangos.push(newCell);
			});

			return [...rolesAcc, tableData];
		}, []);

		setRolesRangos(newRolesRangos);
	}, [roles, rangos]);

	const handleRolRangoClick = async posicion => {
		const newRolesRangos = rolesRangos.map(combinado => {
			if (combinado.rol_id === posicion.rol_id) {
				return {
					...combinado,
					rolRangos: combinado.rolRangos.map(rolRango => {
						if (rolRango.rango_id === posicion.rango_id) {
							return {
								...rolRango,
								activo: !rolRango.activo,
							};
						}
						return rolRango;
					}),
				};
			}
			return combinado;
		});
		setRolesRangos(newRolesRangos);
	};

	const createPosicionamientoClick = async () => {
		const getTranslations = async () => {
			return {
				title: i18n.t('drives.swal_processing'),
				title_attention: i18n.t('drives.swal_attention'),
				text: i18n.t('drives.swal_wait'),
				text_attention: i18n.t('positioning.set_name')
			}
		}
		const { title, text, title_attention, text_attention } = await getTranslations();
		if (!posicionamiento.nombre.trim()) {
			Swal.fire(title_attention, text_attention, 'warning');
			return;
		}
		try {
			setIsSubmitting(true);
			Swal.fire(title, text, 'info');
			Swal.showLoading();

			const body = {
				nombre: posicionamiento.nombre,
				posicionamiento: mapPosicionamientoToBodyModel(),
			};
			await http.post(`posicionamiento`, body);

			Swal.close();
			setIsSubmitting(false);
			history.push('/r/posicionamientos');
		} catch (error) {
			console.error(error);
			setIsSubmitting(false);
			apiErrorHandler('Posicionamientos', error);
		}
	};
	const mapPosicionamientoToBodyModel = () => {
		return rolesRangos.map(rol => ({
			rol_id: rol.rol_id,
			rolRangos: rol.rolRangos.map(rolRango => ({
				rango_ticket_inicio: rolRango.rango_ticket_inicio,
				rango_ticket_fin: rolRango.rango_ticket_fin,
				activo: rolRango.activo,
			})),
		}));
	};

	const updatePosicionamientoClick = async () => {
		const getTranslations = async () => {
			return {
				title: i18n.t('drives.swal_processing'),
				title_attention: i18n.t('drives.swal_attention'),
				text: i18n.t('drives.swal_wait'),
				text_attention: i18n.t('positioning.set_name')
			}
		}
		const { title, text, title_attention, text_attention } = await getTranslations();
		if (!posicionamiento.nombre.trim()) {
			Swal.fire(title_attention, text_attention, 'warning');
			return;
		}
		try {
			setIsSubmitting(true);
			Swal.fire(title, text, 'info');
			Swal.showLoading();

			const body = {
				nombre: posicionamiento.nombre,
				posicionamiento: mapPosicionamientoUpdateToBodyModel(),
			};
			await http.put(`posicionamiento/${id}`, body);

			Swal.close();
			setIsSubmitting(false);
			history.push('/r/posicionamientos');
		} catch (error) {
			console.error(error);
			setIsSubmitting(false);
			apiErrorHandler('Posicionamientos', error);
		}
	};
	const mapPosicionamientoUpdateToBodyModel = () => {
		return rolesRangos.map(rol => ({
			rol_id: rol.rol_id,
			rolRangos: rol.rolRangos.map(rolRango => ({
				rol_rango_id: rolRango.rol_rango_id,
				rango_id: rolRango.rango_id,
				rango_ticket_inicio: rolRango.rango_ticket_inicio,
				rango_ticket_fin: rolRango.rango_ticket_fin,
				activo: rolRango.activo,
			})),
		}));
	};

	const updateRangoClick = async (rangoID: number) => {
		try {
			const rangoTickets = rolRangoSelected.rango.split('-');
			if (RANGO_PATTERN.test(rolRangoSelected.rango)) {
				let rango_ticket_inicio = Number(rangoTickets[0].trim());
				let rango_ticket_fin = Number(rangoTickets[1].trim());
				const newRangos = calcularRangos(rangoID, { rango_ticket_inicio, rango_ticket_fin });
				setRangos(newRangos);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setRolRangoSelected({
				rango_id: null,
				rango: '',
			});
		}
	};

	const calcularRangos = (rangoID: number, { rango_ticket_inicio, rango_ticket_fin }: any) => {
		const rangosClone = cloneDeep(rangos);
		let rangoActualizadoIndex: null | number = null;
		return rangosClone.map((rango, index) => {
			// Rango para actualizar
			if (rango.rango_id === rangoID) {
				rangoActualizadoIndex = index;
				if (rangosClone[index - 1] && rango_ticket_inicio !== rangosClone[index - 1].rango_ticket_fin + 1) {
					return {
						...rango,
						rango_ticket_inicio: rangosClone[index - 1].rango_ticket_fin + 1,
						rango_ticket_fin,
					};
				} else {
					return {
						...rango,
						rango_ticket_inicio,
						rango_ticket_fin,
					};
				}
			}
			// Rangos despues del rango actualizado
			if (rangoActualizadoIndex != null && index > rangoActualizadoIndex) {
				// Rango inmediatamente despues del que se actualizo
				if (index === rangoActualizadoIndex + 1) {
					if (rango.rango_ticket_inicio !== rango_ticket_fin + 1) {
						return {
							...rango,
							rango_ticket_inicio: rango_ticket_fin + 1,
							rango_ticket_fin: rango.rango_ticket_fin,
						};
					}
				}
				// Rangos restantes
				if (rango.rango_ticket_inicio !== rangosClone[index - 1].rango_ticket_fin + 1) {
					return {
						...rango,
						rango_ticket_inicio: rangosClone[index - 1].rango_ticket_fin + 1,
						rango_ticket_fin,
					};
				}
				return rango;
			}
			return rango;
		});
	};

	return (
		<div>
			<div className='mb-3'>
				<Link className='text-dark font-weight-bold' to='/r/posicionamientos'>
					<FontAwesomeIcon icon={faArrowLeft} className='mr-2' size='lg' />
					{`${posicionamiento?.nombre || i18n.t('positioning.back')}`}
				</Link>
			</div>
			<Row className='mb-3 justify-content-between'>
				<Col md='3'>
					<Form.Control
						name='nombre'
						type='text'
						placeholder={i18n.t('positioning.name')}
						onChange={({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) =>
							setPosicionamiento({ nombre: input.value })
						}
						value={posicionamiento.nombre}
					/>
				</Col>
			</Row>

			<Row className='row mb-3'>
				<Table className='tabla-posicionamiento' size='sm'>
					<thead className='text-center'>
						<tr>
							<th>Roles / Tickets</th>
							{rangosMemo.map(rango => {
								if (rolRangoSelected.rango_id === rango.rango_id) {
									return (
										<th key={rango.rango_id} className='rangeInput'>
											<Form.Control
												name='rango'
												type='text'
												onChange={({ target: input }) =>
													setRolRangoSelected(prev => ({ ...prev, rango: input.value }))
												}
												value={rolRangoSelected.rango}
											/>
											<button
												type='button'
												className='btn btn-success'
												onClick={() => updateRangoClick(rango.rango_id)}>
												<FontAwesomeIcon icon={faCheck} />
											</button>
										</th>
									);
								}
								return (
									<th key={rango.rango_id}>
										<button
											type='button'
											className='btn btn-link posButtonHeader'
											onClick={() => setRolRangoSelected({ rango_id: rango.rango_id, rango: rango.rango })}>
											<span>{rango.rango}</span>
											<span>
												<FontAwesomeIcon icon={faEdit} />
											</span>
										</button>
									</th>
								);
							})}
						</tr>
					</thead>
					<tbody className='text-center'>
						{rolesRangos.map(rol => (
							<tr key={rol.rol_id}>
								<td>{rol.nombre}</td>
								{rol.rolRangos.map(rango => (
									<td key={rango.rango_id}>
										<button
											type='button'
											className={`btn btn-sm posButton ${rango.activo ? 'btn-success' : 'btn-danger'}`}
											onClick={() => handleRolRangoClick({ rol_id: rol.rol_id, ...rango })}>
											<FontAwesomeIcon icon={rango.activo ? faCheck : faTimes} />
										</button>
									</td>
								))}
							</tr>
						))}
					</tbody>
				</Table>
			</Row>
			<div className='text-center mt-4'>
				<Button
					variant='danger'
					className='font-weight-bold px-5'
					disabled={isSubmitting}
					onClick={id ? updatePosicionamientoClick : createPosicionamientoClick}>
					{isSubmitting ? <FontAwesomeIcon icon={faSpinner} pulse size='lg' /> : id ? i18n.t('form.save_button') : i18n.t('form.create_button')}
				</Button>
			</div>
		</div>
	);
};

export default PosicionamientoTable;
