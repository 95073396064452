import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PosicionamientoTable from 'components/Posicionamiento/PosicionamientoTable';
import Posicionamientos from 'components/Posicionamiento/Posicionamientos';

const PosicionamientoRoutes: React.FC = () => {
   return (
      <Switch>
         <Route path={`/r/posicionamientos/:id/editar`} component={PosicionamientoTable} />
         <Route path={`/r/posicionamientos/nuevo`} component={PosicionamientoTable} />
         <Route path={`/r/posicionamientos`} component={Posicionamientos} />
      </Switch>
   );
};

export default PosicionamientoRoutes;
