import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export interface InformacionTabProps {
	drive: any;
}

const InformacionTab: React.FC<InformacionTabProps> = ({ drive }) => {
	const { t } = useTranslation();
	const isDriveMaduro = (): boolean => {
		const fecha_registro = moment(drive?.fecha_registro);
		const now = moment();
		return now.diff(fecha_registro, 'months') >= 3;
	};
	return (
		<div className='row'>
			<div className='col-md-2'>
				<label className='font-weight-bold'>Drive ID</label>
				<p>{drive?.drive_id}</p>
			</div>
			<div className='col-md-4'>
				<label className='font-weight-bold'>{t('drives.drive_address')}</label>
				<p>{drive?.direccion}</p>
			</div>
			<div className='col-md-3'>
				<label className='font-weight-bold'>{t('drives.opening_date')}</label>
				<p>{moment(drive?.fecha_registro).format('L')}</p>
			</div>
			<div className='col-md-3'>
				<label className='font-weight-bold'>{t('drives.is_mature')}</label>
				<p>{isDriveMaduro() ? t('drives.yes') : t('drives.no')}</p>
			</div>
		</div>
	);
};

export default InformacionTab;
