import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { Tab, Tabs, Form, Button, InputGroup, FormControl, Row, Col, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSyncAlt, faEraser, faSearch, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-virtualized-select';
// Mis Componentes
import http from 'services/http.service';
import useDebounce from 'hooks/useDebounce';
// Mis Types
import { Pagination, Filters } from 'typings/tablas';
import { Option } from 'typings';
import apiErrorHandler from 'services/apiErrorHandler.service';
import i18n from '../../utils/i18n';

const estatusOptions: Option[] = [
	{
		value: 1,
		label: i18n.t('datatable.active'),
	},
	{
		value: 0,
		label: i18n.t('datatable.inactive'),
	},
];

export interface UsuariosProps { }

const Usuarios: React.FC<UsuariosProps> = () => {
	const estatusFormatter = (cell, row, rowIndex) => {
		if (row.activo) {
			return <Badge variant='success'>{i18n.t('datatable.active')}</Badge>;
		}
		return <Badge variant='danger'>{i18n.t('datatable.inactive')}</Badge>;
	};
	const optionsFormatter = (cell, row, rowIndex) => {
		return (
			<>
				<Button variant={row.activo ? 'success' : 'danger'} className='mr-2' onClick={() => handleDesactivarUsuario(row.id)}>
					<FontAwesomeIcon icon={faPowerOff} size='lg' />
				</Button>
				<Link className='btn btn-warning' to={`/r/usuarios/${row.options}/editar`}>
					<FontAwesomeIcon icon={faEdit} size='lg' />
				</Link>
			</>
		);
	};
	const columns = [
		{
			dataField: 'id',
			text: 'ID',
			headerAlign: 'center',
			align: 'center',
			headerClasses: 'font-weight-bold text-dark',
		},
		{
			dataField: 'nombre',
			text: i18n.t('users.name'),
			headerAlign: 'center',
			align: 'center',
			headerClasses: 'font-weight-bold text-dark',
		},
		{
			dataField: 'correo',
			text: i18n.t('users.email'),
			headerAlign: 'center',
			align: 'center',
			headerClasses: 'font-weight-bold text-dark',
		},
		{
			dataField: 'drive',
			text: 'Drive',
			headerAlign: 'center',
			align: 'center',
			headerClasses: 'font-weight-bold text-dark',
		},
		{
			dataField: 'activo',
			text: i18n.t('users.status'),
			headerAlign: 'center',
			align: 'center',
			headerClasses: 'font-weight-bold text-dark',
			formatter: estatusFormatter,
		},
		{
			dataField: 'options',
			text: i18n.t('users.options'),
			isDummyField: true,
			headerAlign: 'center',
			align: 'center',
			headerClasses: 'font-weight-bold text-dark',
			formatter: optionsFormatter,
		},
	];
	const [tabSeccionKey, setTabSeccionKey] = useState<string>('Lista');
	const [usuarioDrives, setUsuarioDrives] = useState<any>();
	const [usuarios, setUsuarios] = useState<any[]>([]);
	const [search, setSearch] = useState<string>('');
	const debouncedSearch = useDebounce(search.trim(), 500);
	const [filtros, setFiltros] = useState<Filters>({
		activo: null,
		drive: null,
	});
	const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
	const [pagination, setPagination] = useState<Pagination>({
		page: 1,
		totalSize: 10,
		sizePerPage: 10,
	});

	useEffect(() => {
		const fetchEstadisticasDrives = async () => {
			try {
				const usuarioDrivesData: any[] = await http.get('estadistica/drives');
				setUsuarioDrives(usuarioDrivesData);
			} catch (error) {
				console.error(error);
			}
		};

		fetchEstadisticasDrives();
		// eslint-disable-next-line
	}, []);

	// Obtener la lista de grupos
	useEffect(() => {
		if (filtros.drive || debouncedSearch) {
			fetchEmpleados();
		}
		// eslint-disable-next-line
	}, [debouncedSearch, pagination.page, pagination.sizePerPage, filtros]);

	const fetchEmpleados = async () => {
		const { estatus } = filtros;
		const { page, sizePerPage: limit } = pagination;
		try {
			setIsTableLoading(true);

			const params = {
				...(debouncedSearch && { search: debouncedSearch }),
				...(estatus !== null && { activo: estatus }),
				drive_id: filtros.drive,
				limit,
				page,
				order: 'desc',
			};
			const { rows: usuariosList, count: totalSize }: any = await http.get('usuarios', { params });
			setUsuarios(mapUsuariosToViewModel(usuariosList));
			setPagination({ ...pagination, totalSize });

			setIsTableLoading(false);
		} catch (error) {
			setIsTableLoading(false);
			console.error(error);
		}
	};

	const mapUsuariosToViewModel = (usuariosData: any[]) => {
		return usuariosData.map((usuario: any) => {
			return {
				id: usuario.user_id,
				nombre: usuario.fullname,
				correo: usuario.email || 'N/D',
				drive: usuario.isAdmin
					? 'Todos'
					: usuario?.drives.length === 0
						? 'Sin asignar'
						: usuario?.drives.length > 1
							? 'Varios'
							: usuario?.drives.length === 1 && usuario?.drives[0].drive.nombre,
				activo: usuario.activo,
				options: usuario.user_id,
			};
		});
	};

	const syncEmpleados = async () => {
		try {
			setIsTableLoading(true);

			await http.post('empleados/caffenioEmpleados', {
				drive_id: filtros.drive,
			});

			setIsTableLoading(false);
		} catch (error) {
			setIsTableLoading(false);
			console.error(error);
		}
	};

	const handleSearchChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(input.value);
	};

	const handleRegistrosChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
		setPagination({
			...pagination,
			sizePerPage: Number(input.value),
		});
	};

	const handleRefresh = async () => {
		await syncEmpleados();
		fetchEmpleados();
	};

	const handleCleanFilters = () => {
		setSearch('');
		setFiltros({
			estatus: null,
			drive: null,
		});
		setPagination({
			...pagination,
			sizePerPage: 10,
		});
	};

	const handleSelectChange = option => {
		setFiltros(prev => ({
			...prev,
			[option.name]: option.value !== undefined ? option.value : null,
		}));
	};

	const handleDesactivarUsuario = async (usuarioId: number) => {
		try {
			await http.put(`usuarios/activo/${usuarioId}`);

			const usuariosMapped = usuarios.map((usuario, index) => {
				if (usuarioId === usuario.id) {
					return {
						...usuario,
						activo: !usuario.activo,
					};
				}
				return usuario;
			});
			setUsuarios(usuariosMapped);
		} catch (error) {
			apiErrorHandler('Usuarios', error);
		}
	};

	const handleTableChange = (type, { page, sizePerPage }) => {
		setPagination({
			...pagination,
			page,
		});
	};

	const drivesOptions = React.useMemo(() => {
		if (!usuarioDrives || !usuarioDrives.length) return [];
		return usuarioDrives.map(drive => ({
			value: drive.drive_id,
			label: drive.nombre,
		}));
	}, [usuarioDrives]);

	return (
		<Tabs id='secciones' className='tabs-secciones mb-4' activeKey={tabSeccionKey} onSelect={key => setTabSeccionKey(key)}>
			<Tab eventKey='Lista' title={i18n.t('datatable.tab_title')}>
				<div className='row'>
					<div className='col-md-6'>
						<Form.Group as={Row} controlId='formHorizontalEmail'>
							<Form.Label column md={3} className='pr-md-0'>
								{i18n.t('datatable.records')}
							</Form.Label>
							<div className='col-md-2 pl-md-0'>
								<Form.Control
									as='select'
									name='sizePerPage'
									onChange={handleRegistrosChange}
									value={pagination.sizePerPage.toString()}>
									<option>10</option>
									<option>25</option>
									<option>50</option>
									<option>100</option>
								</Form.Control>
							</div>
						</Form.Group>
					</div>
					<div className='col-md-6'>
						<InputGroup className='mb-3'>
							<OverlayTrigger
								key={'tooltip-filtros'}
								placement={'top'}
								overlay={<Tooltip id={`tooltip-filtros`}>{i18n.t('datatable.clean')}</Tooltip>}>
								<Button variant='light' className='mr-3' onClick={handleCleanFilters}>
									<FontAwesomeIcon icon={faEraser} size='lg' />
								</Button>
							</OverlayTrigger>
							<OverlayTrigger
								key={'tooltip-refresh'}
								placement={'top'}
								overlay={<Tooltip id={`tooltip-refresh`}>{i18n.t('datatable.refresh')}</Tooltip>}>
								<Button variant='light' className='mr-3' onClick={handleRefresh}>
									<FontAwesomeIcon icon={faSyncAlt} size='lg' />
								</Button>
							</OverlayTrigger>
							<InputGroup.Prepend>
								<InputGroup.Text id='basic-addon1'>
									<FontAwesomeIcon icon={faSearch} size='lg' />
								</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl placeholder={i18n.t('datatable.search')} onChange={handleSearchChange} />
						</InputGroup>
					</div>
				</div>

				<Row className='mb-3'>
					<Col md='3'>
						<Select
							name='drive'
							placeholder='Drive'
							options={drivesOptions}
							searchable={true}
							clearable={true}
							onChange={options => handleSelectChange({ ...options, name: 'drive' })}
							value={filtros.drive}
						/>
					</Col>
					<Col md='3'>
						<Select
							name='estatus'
							placeholder={i18n.t('users.status')}
							options={estatusOptions}
							searchable={false}
							clearable={true}
							onChange={options => handleSelectChange({ ...options, name: 'estatus' })}
							value={filtros.estatus}
						/>
					</Col>
				</Row>

				<BootstrapTable
					bootstrap4
					striped
					classes='usuarios-table'
					keyField='id'
					data={usuarios}
					columns={columns}
					remote={{
						pagination: true,
						search: true,
					}}
					loading={isTableLoading}
					overlay={overlayFactory({
						spinner: true,
						background: 'rgba(192,192,192,0.3)',
					})}
					hover
					bordered={false}
					noDataIndication={filtros.drive ? i18n.t('datatable.nodata') : i18n.t('users.select_drive')}
					rowClasses={''}
					pagination={paginationFactory({
						page: pagination.page, // Specify the current page.
						totalSize: pagination.totalSize, // Total data size.
						sizePerPage: pagination.sizePerPage, // Specify the size per page.
						withFirstAndLast: false, // hide the going to first and last page button
						alwaysShowAllBtns: true, // always show the next and previous page button
						prePageText: i18n.t('datatable.previous'),
						nextPageText: i18n.t('datatable.next'),
						hideSizePerPage: true, // hide the size per page dropdown
						hidePageListOnlyOnePage: true,
					})}
					onTableChange={handleTableChange}
				/>

				<p className='text-right mb-0'>
					{i18n.t('datatable.showing')} {usuarios.length} {i18n.t('datatable.total_entries')} {pagination.totalSize} {i18n.t('datatable.entries')}
				</p>
			</Tab>
			{/* <Tab eventKey='Nuevo' title='Agregar nuevo Drive'></Tab> */}
		</Tabs>
	);
};

export default Usuarios;
