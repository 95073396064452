import React from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-select/dist/react-select.css';
import 'react-virtualized-select/styles.css';
import './index.css';
import App from 'components/App';
import logger from 'services/logger.service';
import * as serviceWorker from 'serviceWorker';
import './utils/i18n';

toast.configure({
	bodyClassName: 'text-dark'
});
if (process.env.NODE_ENV === 'production') {
	logger.init();
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
