import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { logout } from 'services/authentication.service';
import logo from '../../../assets/images/logo.png';

import i18n from '../../../utils/i18n';
import Select from 'react-virtualized-select';
import { Option } from 'typings';
export interface NavbarProps {
	user: any;
}

const languageOptions: Option[] = [
	{
		value: "es",
		label: i18n.t('system.spanish'),
	},
	{
		value: "en",
		label: i18n.t('system.english'),
	},
];

const TopNavbar: React.FC<NavbarProps> = ({ user }) => {
	let history = useHistory();

	const [language, setLanguage] = useState<string>(localStorage.getItem("language")!);

	useEffect(() => {
		if (!localStorage.getItem("language")) {
			localStorage.setItem('language', window.navigator.languages[1]);
			setLanguage(localStorage.getItem("language")!);
		}
	}, []);

	useEffect(() => {
		i18n.changeLanguage(language);
	}, [language]);

	const handleSelectChange = option => {
		setLanguage(option.value);
		localStorage.setItem('language', option.value);
		window.location.reload();
	};

	return (
		<Navbar bg='light'>
			<Nav className='mr-auto'>
				<img className='logo img-fluid mr-3' src={logo} alt='Logo' />
			</Nav>
			<Nav className='ml-auto'>
				<Select
					name='language'
					placeholder={i18n.t('system.language')}
					options={languageOptions}
					searchable={false}
					clearable={false}
					style={{ width: "200px", marginRight: "20px" }}
					onChange={options => handleSelectChange({ ...options })}
					value={language}
				/>
				<NavDropdown
					title={
						<>
							<FontAwesomeIcon icon={faUserCircle} size='lg' className='mr-2' />
							{user.fullname}
						</>
					}
					alignRight
					id='basic-nav-dropdown'>
					<NavDropdown.Item
						onClick={() => {
							logout();
							history.replace('/login');
						}}>
						<FontAwesomeIcon icon={faSignOutAlt} size='lg' className='mr-2' />
						{i18n.t('system.logout')}
					</NavDropdown.Item>
				</NavDropdown>
			</Nav>
		</Navbar>
	);
};

export default TopNavbar;
