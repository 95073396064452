import React, { createContext } from 'react';

interface GuiaHorarioTabContext {
   tabDiasKey: string;
   horarioAperturaDrive: any;
   tablaProyecciones: any;
   setTablaProyecciones: React.Dispatch<any>;
   tablaProyeccionesOriginal: any;
   totalesProyecciones: any;
   tablaHorario: any;
   setTablaHorario: React.Dispatch<any>;
   tablaHorarioOriginal: any;
   totalesHorario: any;
   setTotalesHorario: React.Dispatch<any>;
   horarioSemanal: any[];
   horarioSemanalPasado: any[];
   guiasHistorial: any;
   totalesHorasxSemanaHorario: any;
   empleadosDisponibles: number;
   empleados: any[];
   empleadosOptions: {
      lists: any[][];
      selected: any[];
   };
   setEmpleadosOptions: React.Dispatch<
      React.SetStateAction<{
         lists: any[][];
         selected: any[];
      }>
   >;
   // UTILS
   isHoraInactiva: (hora: string) => boolean;
   obtenerDiferenciaDeOperadores: (oldValue: string, newValue: string) => string;
   obtenerDiferenciaEnTotales: (oldValue: number, newValue: number) => number;
   calcularTotales: (rowName: string, fieldValue: number) => void;
   calcularProductividadxHora: (rowName: string, columnName: string, fieldValue: number) => number;
}
const GuiaHorariosTabContext = createContext<GuiaHorarioTabContext>(null!);

export default GuiaHorariosTabContext;
