import React, { useCallback, useContext, useState, useEffect } from 'react';
import Swal from 'sweetalert2';
// Mis Componentes
import GuiaHorariosTabContext from '../GuiaHorariosTabContext';
import { CELL_COLORS, CELL_STATUS, HORAS_JORNADA_LABORAL, SEMANA_DIAS } from 'utils/constants';

export interface HoraCellProps {
   rowNumber: number;
   hora: string;
   totalHorasxDia: number;
   checkBoxChecked: boolean;
}

const HoraCell: React.FC<HoraCellProps> = ({ rowNumber, hora, totalHorasxDia, checkBoxChecked }) => {
   const {
      tablaProyeccionesOriginal,
      tablaProyecciones,
      setTablaProyecciones,
      tablaHorarioOriginal,
      tablaHorario,
      setTablaHorario,
      totalesHorario,
      setTotalesHorario,
      horarioSemanal,
      horarioSemanalPasado,
      tabDiasKey,
      // UTILS
      isHoraInactiva,
      obtenerDiferenciaDeOperadores,
      obtenerDiferenciaEnTotales,
      calcularTotales,
      calcularProductividadxHora,
   } = useContext(GuiaHorariosTabContext);

   const [borderCellStyle, setBorderCellStyle] = useState({
      width: '49.1166px',
      height: 'calc(1.5em + 0.75rem + 2px)',
      borderRadius: '4px',
      cursor: 'pointer',
      border: '1px solid #ced4da',
   });

   useEffect(() => {
      if (tablaHorario[`${rowNumber}`]?.disponibilidad && tablaHorario[`${rowNumber}`]?.disponibilidad[hora])
         setBorderCellStyle({
            width: '49.1166px',
            height: 'calc(1.5em + 0.75rem + 2px)',
            borderRadius: '4px',
            cursor: 'pointer',
            border: '5px solid #3e6f00',
         });
   }, []);

   const handleHoraClick = ({ currentTarget }: React.MouseEvent<HTMLDivElement>) => {
      let row = currentTarget.attributes.getNamedItem('data-row')?.value || '';
      let hora = currentTarget.attributes.getNamedItem('data-hora')?.value || '';
      if (row && hora) {
         const newTotalHoras = tablaHorario[row][hora].value ? --totalesHorario[`row-${row}`] : ++totalesHorario[`row-${row}`];
         if (isHoraExtra(row, hora)) {
            Swal.fire('Advertencia', 'Atención, estas asignando horas extras al colaborador.', 'warning');
         }

         if (
            hora === '6am' &&
            empleadoId &&
            horarioDiaAnteriorEmpleado &&
            horarioDiaAnteriorEmpleado?._10pm &&
            !tablaHorario[row][hora].value
         ) {
            Swal.fire(
               'Advertencia',
               'Atención, esta persona cerro a las 10pm el día anterior, no se le puede asignar esta hora.',
               'warning',
            );
            return;
         }

         // Actualizar tabla de horarios
         const tempTablaHorario = {
            ...tablaHorario,
            [row]: {
               ...tablaHorario[row],
               [hora]: {
                  value: !tablaHorario[row][hora].value,
                  status: getStatusHora(row, hora),
               },
            },
         };
         setTablaHorario({
            ...tablaHorario,
            [row]: {
               ...tablaHorario[row],
               [hora]: {
                  value: !tablaHorario[row][hora].value,
                  status: getStatusHora(row, hora),
               },
            },
         });
         setTotalesHorario({
            ...totalesHorario,
            [`row-${row}`]: newTotalHoras,
         });

         // Actualizar la tabla de proyecciones
         let empleadosxHora = 0;
         Object.keys(tempTablaHorario).forEach(key => {
            if (tempTablaHorario[key][hora].value) {
               empleadosxHora++;
            }
         });

         let oldValue: number = tablaProyeccionesOriginal[`operadores_${hora}`];
         let operadoresDifference = obtenerDiferenciaDeOperadores(oldValue.toString(), empleadosxHora.toString());
         let totalesDifference = obtenerDiferenciaEnTotales(oldValue, empleadosxHora);
         calcularTotales(row, totalesDifference);
         let newProductividad = calcularProductividadxHora(row, hora, empleadosxHora);

         setTablaProyecciones({
            ...tablaProyecciones,
            [`operadores_${hora}`]: empleadosxHora,
            [`operadores_diff_${hora}`]: operadoresDifference,
            [`productividad_${hora}`]: newProductividad,
         });
      }
   };
   const isHoraExtra = (row: string, hora: string) => {
      if (totalesHorario[`row-${row}`] > HORAS_JORNADA_LABORAL && !tablaHorario[row][hora].value) {
         return true;
      }
      return false;
   };
   const getStatusHora = (row: string, hora: string) => {
      if (tablaHorarioOriginal[row][hora].value === !tablaHorario[row][hora].value) {
         return CELL_STATUS.Recomendado;
      } else if (tablaHorario[row][hora].value) {
         return CELL_STATUS.HoraLibre;
      } else if (isHoraExtra(row, hora)) {
         return CELL_STATUS.HoraExtra;
      } else {
         return CELL_STATUS.Manual;
      }
   };

   const getCellColor = useCallback(
      (row, hora, totalHorasxDia) => {
         if (
            (!tablaHorarioOriginal[row][hora]?.value &&
               tablaHorario[row][hora]?.value &&
               tablaHorario[row][hora]?.status === CELL_STATUS.Recomendado) ||
            (tablaHorarioOriginal[row][hora]?.value && tablaHorario[row][hora]?.value && totalHorasxDia > HORAS_JORNADA_LABORAL)
         ) {
            if (totalesHorario[`row-${row}`] > HORAS_JORNADA_LABORAL && totalHorasxDia > HORAS_JORNADA_LABORAL) {
               // Experimento. Horas extra ya guardadas.
               return CELL_COLORS.horaExtra;
            }
            return CELL_COLORS.manual;
         } else if (
            tablaHorarioOriginal[row][hora]?.value &&
            tablaHorario[row][hora]?.value &&
            tablaHorario[row][hora]?.status === CELL_STATUS.Recomendado
         ) {
            return CELL_COLORS.recomendado;
         } else if (
            !tablaHorarioOriginal[row][hora]?.value &&
            tablaHorario[row][hora]?.value &&
            tablaHorario[row][hora]?.status === CELL_STATUS.HoraExtra
         ) {
            if (totalHorasxDia > HORAS_JORNADA_LABORAL) {
               return CELL_COLORS.horaExtra;
            }
            return CELL_COLORS.manual;
         } else if (
            !tablaHorarioOriginal[row][hora]?.value &&
            tablaHorario[row][hora]?.value &&
            tablaHorario[row][hora]?.status === CELL_STATUS.Manual
         ) {
            return CELL_COLORS.manual;
         } else if (tablaProyecciones[`tickets_${hora}`] > 35) {
            return CELL_COLORS.horaPico;
         }
         return CELL_COLORS.horaLibre;
      },
      [tablaProyecciones, tablaHorarioOriginal, tablaHorario, totalesHorario],
   );

   const getDiaAnterior = () => {
      const keyDia = SEMANA_DIAS.indexOf(tabDiasKey);
      if (keyDia !== 0) return SEMANA_DIAS[keyDia - 1];
      return SEMANA_DIAS[6];
   };

   const getHorarioDiaAnterior = () => {
      if (tabDiasKey === 'LUNES') return horarioSemanalPasado.find(horario => horario.dia === 'DOMINGO');
      return horarioSemanal.find(horario => horario.dia === getDiaAnterior());
   };

   const horarioDiaAnterior = getHorarioDiaAnterior();
   const empleadoId = tablaHorario[`${rowNumber}`].empleado_id;
   const horarioDiaAnteriorEmpleado = horarioDiaAnterior.empleados_asignados.find(
      empleado => empleado.empleado_id === empleadoId,
   );

   return (
      <td>
         <div
            data-row={`${rowNumber}`}
            data-hora={hora}
            className={`d-flex align-items-center justify-content-center ${
               isHoraInactiva(hora) || checkBoxChecked ? 'cell-disabled' : getCellColor(rowNumber, hora, totalHorasxDia)
            }`}
            style={borderCellStyle}
            {...(!isHoraInactiva(hora) ? { onClick: handleHoraClick } : {})}></div>
      </td>
   );
};

export default HoraCell;
