import Swal from 'sweetalert2';
import logger from './logger.service';

type Entity = 'Empleados' | 'Usuarios' | 'Drives' | 'Reportes' | 'Guia' | 'Posicionamientos' | 'Server';
/**
 * Function para personalizar los errores provenientes de los endpoints.
 * @param {Entity} entity
 * @param {*} error
 */
export default function apiErrorHandler(entity: Entity, error) {
   // Client Errors
   if (error.type) {
      switch (entity) {
         case 'Empleados':
            Swal.fire('Error!', error.message, 'error');
            break;
         case 'Usuarios':
            Swal.fire('Error!', error.message, 'error');
            break;
         case 'Drives':
            Swal.fire('Error!', error.message, 'error');
            break;
         case 'Reportes':
            Swal.fire('Error!', error.message, 'error');
            break;
         case 'Guia':
            Swal.fire('Error!', error.message, 'error');
            break;
      }
   }

   // Server Errors
   if (error.status === 500) {
      // Enviar error a sentry
      logger.log(error.response);
      // Mostrar error generico al usuario
      Swal.fire('Ocurrió un error inesperado', 'Espere un momento e intente de nuevo.', 'error');
   }
}
