import { AbilityBuilder, Ability } from '@casl/ability';
import { getCurrentUser } from 'services/authentication.service';

const SECCIONES = ['seccion_monitor', 'seccion_drives', 'seccion_empleados', 'seccion_reportes'];

const user = getCurrentUser();
let ability = new Ability([]);

export function updateAbilities() {
	const user = getCurrentUser();
	defineAbilitiesFor(user);
}
function defineAbilitiesFor(user) {
	const { can: allow, rules } = AbilityBuilder.extract();
	if (!user) {
		return ability;
	}
	const rol = getRol(user);
	switch (rol) {
		case 'administrador':
			allow('access', [...SECCIONES, 'seccion_notificaciones', 'seccion_usuarios', 'seccion_posicionamiento', 'seccion_validaciones']);
			break;
		case 'supervisor':
			allow('access', [...SECCIONES, 'seccion_validaciones']);
			break;
		case 'lider':
			allow('access', SECCIONES);
			break;
		default:
			allow('access', SECCIONES);
			break;
	}
	return ability.update(rules);
}

type Action =
	| 'FILTER_BY_DRIVE'
	| 'CREATE_DRIVE'
	| 'UPDATE_DRIVE'
	| 'UPDATE_GUIA_HORARIO'
	| 'ASSIGN_DRIVE_TO_EMPLEADO'
	| 'CREATE_EMPLEADO'
	| 'UPDATE_EMPLEADO';
export function hasPermissionTo(action: Action): boolean {
	const user = getCurrentUser();
	const rol = getRol(user);
	if (action === 'FILTER_BY_DRIVE' && ['administrador', 'supervisor'].includes(rol)) return true;
	if (action === 'CREATE_DRIVE' && ['administrador'].includes(rol)) return true;
	if (action === 'UPDATE_DRIVE' && ['administrador', 'coordinador'].includes(rol)) return true;
	if (action === 'UPDATE_GUIA_HORARIO' && ['administrador', 'lider', 'supervisor'].includes(rol)) return true;
	if (action === 'ASSIGN_DRIVE_TO_EMPLEADO' && ['administrador'].includes(rol)) return true;
	if (action === 'CREATE_EMPLEADO' && ['administrador', 'lider'].includes(rol)) return true;
	if (action === 'UPDATE_EMPLEADO' && ['administrador', 'lider'].includes(rol)) return true;
	return false;
}
export function getRol(user: any) {
	if (user?.isAdmin) {
		return 'administrador';
	} else if (user?.drives.length >= 1 && user?.drives.some(drive => drive.coordinador)) {
		return 'coordinador';
	} else if (user?.drives.length >= 1 && user?.drives.some(drive => drive.supervisor)) {
		return 'supervisor';
	} else {
		return 'lider';
	}
}

export default defineAbilitiesFor(user);
