import React from 'react';
import { Switch, Route } from 'react-router-dom';
import DriveDetalles from 'components/Drives/Drive';
import DriveForm from 'components/Drives/DriveForm';
import Drives from 'components/Drives/Drives';

const DriveRoutes: React.FC = () => {
   return (
      <Switch>
         <Route path={`/r/drives/:id/editar`} component={DriveForm} />
         <Route path={`/r/drives/:id/detalles`} component={DriveDetalles} />
         <Route path={`/r/drives/nuevo`} component={DriveForm} />
         <Route path={`/r/drives`} component={Drives} />
      </Switch>
   );
};

export default DriveRoutes;
