import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import PosicionamientoRoutes from 'routes/posicionamiento.routes';
import i18n from '../../utils/i18n';

export interface PosicionamientoPageProps { }

const PosicionamientoPage: React.FC<PosicionamientoPageProps> = () => {
	return (
		<section id='posicionamiento'>
			<Row className='align-items-center mb-3'>
				<Col>
					<h3 className='font-weight-bold'>{i18n.t('sidebar.positioning')}</h3>
				</Col>
				<Col className='text-right'>
					<Link to='/r/posicionamientos/nuevo'>
						<Button variant='danger' className='text-capitalize'>
							<FontAwesomeIcon icon={faPlus} className='mr-3' size='lg' />
							{i18n.t('positioning.new')}
						</Button>
					</Link>
				</Col>
			</Row>
			<Card>
				<Card.Header className='bg-dark-orange'></Card.Header>
				<Card.Body>
					<PosicionamientoRoutes />
				</Card.Body>
			</Card>
		</section>
	);
};

export default PosicionamientoPage;
