import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Empleado from 'components/Empleados/Empleado';
import Empleados from 'components/Empleados/Empleados';

const EmpleadosRoutes: React.FC = () => {
   return (
      <Switch>
         <Route path={`/r/empleados/:id/editar`} component={Empleado} />
         <Route path={`/r/empleados/nuevo`} component={Empleado} />
         <Route path={`/r/empleados`} component={Empleados} />
      </Switch>
   );
};

export default EmpleadosRoutes;
