import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Usuarios from 'components/Usuarios/Usuarios';
import Usuario from 'components/Usuarios/Usuario';

const UsuariosRoutes: React.FC = () => {
   return (
      <Switch>
         <Route path={`/r/usuarios/:id/editar`} component={Usuario} />
         <Route path={`/r/usuarios/nuevo`} component={Usuario} />
         <Route path={`/r/usuarios`} component={Usuarios} />
      </Switch>
   );
};

export default UsuariosRoutes;
