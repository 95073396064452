import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from 'guards/private-route';
import Dashboard from 'components/Dashboard/Dashboard';
import AuthPage from 'components/pages/AuthPage';

const AppRoutes: React.FC = () => {
   return (
      <Router>
         <Switch>
            {/* Auth */}
            <Route exact path='/' render={() => <Redirect to='/login' />} />
            <Route path='/login' component={AuthPage} />
            {/* Dashboard */}
            <PrivateRoute path='/r' component={Dashboard} />
         </Switch>
      </Router>
   );
};

export default AppRoutes;
