import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Form as RBForm, Button } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
import ErrorFeedback from 'components/shared/ErrorFeedback';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import SelectInput from 'components/shared/SelectInput';
import MultiSelect from 'react-multi-select-component';
import moment from 'moment';
import 'moment/locale/es';
import { saveAs } from 'file-saver';
// Mis Componentes
import Datepicker from 'components/shared/Datepicker';
import http from 'services/http.service';
import { mapOptionsToViewModel, generarSemanaOptions } from 'utils';
import apiErrorHandler from 'services/apiErrorHandler.service';
import { hasPermissionTo, getRol } from 'services/authorization.service';
import { getCurrentUser } from 'services/authentication.service';
import { Option } from 'typings';
import { REPORTES } from 'utils/constants';
import { getToken } from '../../services/authentication.service';
import i18n from '../../utils/i18n';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from '../shared/loading/Loading';

const reporteOptions: Option[] = [
	{
		value: 1,
		label: i18n.t('reports.employees_schedule'),
	},
	{
		value: 2,
		label: i18n.t('reports.floor_board'),
	},
	{
		value: 3,
		label: i18n.t('reports.productivity'),
	},
	{
		value: 4,
		label: i18n.t('reports.worked_hours_per_employee'),
	},
];
const currentWeek = `${moment().startOf('week').toISOString()},${moment().endOf('week').toISOString()}`;

export interface ReportesPageProps { }

const ReportesPage: React.FC<ReportesPageProps> = () => {
	const [reporteFiltro, setReporteFiltro] = useState<any>({
		reporte: null,
		drive_id: null,
		fecha_inicio: '',
		fecha_fin: '',
		drives: [],
		semana: currentWeek,
		empleado_id: null,
		dia: null,
	});
	const [drivesOptions, setDrivesOptions] = useState<Option[]>([]);
	const [loading, setLoading] = useState(false);

	const reporteSchema = Yup.object().shape(
		{
			reporte: Yup.number().required(i18n.t('form.required')).nullable(),
			fecha_inicio: Yup.string().when(['reporte'], {
				is: reporte => {
					if ([REPORTES.Productividad, REPORTES.HorasTrabajadasEmpleado].includes(reporte)) return true;
				},
				then: Yup.string().required(i18n.t('form.required')),
				otherwise: Yup.string(),
			}),
			fecha_fin: Yup.string().when(['reporte'], {
				is: reporte => {
					if ([REPORTES.Productividad, REPORTES.HorasTrabajadasEmpleado].includes(reporte)) return true;
				},
				then: Yup.string().required(i18n.t('form.required')),
				otherwise: Yup.string(),
			}),
			drive_id: Yup.number().when(['reporte'], {
				is: reporte => {
					if (![REPORTES.Productividad, REPORTES.HorasTrabajadasEmpleado].includes(reporte)) return true;
				},
				then: Yup.number().required(i18n.t('form.required')).nullable(),
				otherwise: Yup.number().nullable(),
			}),
			drives: Yup.array().when(['reporte'], {
				is: reporte => {
					if ([REPORTES.Productividad, REPORTES.HorasTrabajadasEmpleado].includes(reporte)) return true;
				},
				then: Yup.array().min(1, i18n.t('form.required')),
				otherwise: Yup.array(),
			}),
			semana: Yup.string().required(i18n.t('form.required')),
		},
		[
			['reporte', 'drive_id'],
			['reporte', 'fecha_inicio'],
			['reporte', 'fecha_fin'],
			['reporte', 'drives'],
		],
	);

	useEffect(() => {
		const fetchDrives = async () => {
			try {
				const params = {
					limit: 9999,
				};
				const { rows: drivesList }: any = await http.get(`drive`, { params });
				setDrivesOptions(mapOptionsToViewModel(drivesList));
			} catch (error) {
				console.error(error);
			}
		};

		fetchDrives();
	}, []);

	useEffect(() => {
		if (drivesOptions.length > 0) {
			const user = getCurrentUser();
			const userRol = getRol(user);
			setReporteFiltro(reporteFiltro => ({
				...reporteFiltro,
				drive_id: !['administrador', 'supervisor'].includes(userRol) ? user.drives[0].drive_id : null,
			}));
		}
	}, [drivesOptions]);

	const handleDownloadReporte = async (values, actions) => {
		const { reporte, semana, drive_id, fecha_inicio, fecha_fin, drives } = values;
		const drivesMapped = drives.map(drive => drive.value);

		//const loadingToast = toast.warn('Generando reporte, favor de esperar...', { autoClose: false, closeButton: false, closeOnClick: false });

		try {
			const body = {
				...([REPORTES.Productividad, REPORTES.HorasTrabajadasEmpleado].includes(reporte) && {
					fecha_inicio: moment(fecha_inicio).format('YYYY-MM-DD'),
					fecha_fin: moment(fecha_fin).format('YYYY-MM-DD'),
					drives: drivesMapped,
				}),
				...(![REPORTES.Productividad, REPORTES.HorasTrabajadasEmpleado].includes(reporte) && {
					fecha_inicio: moment(semana.split(',')[0]).format('YYYY-MM-DD'),
					fecha_fin: moment(semana.split(',')[1]).format('YYYY-MM-DD'),
					drive_id,
				}),
			};
			let endpoint = '';
			let extension = 'xlsx';
			switch (reporte) {
				case REPORTES.HorarioEmpleados:
					endpoint = 'horario-empleados';
					break;
				case REPORTES.TablaPiso:
					endpoint = 'tabla-piso';
					extension = 'pdf';
					break;
				case REPORTES.Productividad:
					endpoint = 'productividad';
					break;
				case REPORTES.HorasTrabajadasEmpleado:
					endpoint = 'horas-trabajadas-empleado';
					break;
			}

			setLoading(true);

			// Verifica si el endpoint es 'productividad' para usar WebSocket
			if (endpoint === 'productividad') {
				// Crea una conexión WebSocket
				const socket = new WebSocket('wss://apihorarios.caffenio.com');

				// Evento cuando la conexión se abre
				socket.onopen = () => {
					console.log('WebSocket connection opened');
					http.post(`reporte/${endpoint}`, body, {
						responseType: 'blob',
					});

					const token = getToken() || '';

					// Envía el cuerpo de la solicitud al servidor a través del WebSocket
					socket.send(token.toString());
				};

				// Evento cuando se recibe un mensaje del servidor
				socket.onmessage = async (event) => {
					console.log('Received from server:', event.data);

					const message = JSON.parse(event.data);

					if (message.type == "ReporteGenerado") {
						// Descargar el archivo cuando la URL esté disponible
						window.open(`https://apihorarios.caffenio.com/reportes/${message.message}`, '_blank');

						// Cerrar el socket después de descargar
						//toast.dismiss(loadingToast); // Cierra el toast de carga
						socket.close();
						setLoading(false);
						actions.setSubmitting(false);
					}
				};

				// Evento cuando hay un error en la conexión WebSocket
				socket.onerror = (error) => {
					console.error('WebSocket error:', error);
					socket.close();
					setLoading(false);
					//toast.dismiss(loadingToast);
					actions.setSubmitting(false);
				};

				// Evento cuando la conexión WebSocket se cierra
				socket.onclose = () => {
					console.log('WebSocket connection closed');
				};


			} else {
				const response: any = await http.post(`reporte/${endpoint}`, body, {
					responseType: 'blob',
				});

				saveAs(response.data, `${endpoint}.${extension}`);

				setLoading(false);
				//toast.dismiss(loadingToast);

				actions.setSubmitting(false);
			}

		} catch (error) {
			setLoading(false);
			//toast.dismiss(loadingToast);
			//actions.setSubmitting(false);
			apiErrorHandler('Reportes', error);
		}
	};

	return (
		<section id='reportes'>
			<h3 className='font-weight-bold'>{i18n.t('sidebar.reports')}</h3>
			<Card>
				<Card.Header className='bg-dark-orange'></Card.Header>
				<Card.Body>
					<Formik
						initialValues={reporteFiltro}
						enableReinitialize={true}
						validationSchema={reporteSchema}
						onSubmit={handleDownloadReporte}>
						{({ isSubmitting, setFieldValue, values, errors }: FormikProps<any>) => (
							<Form>
								<Row>
									<Col md={4}>
										<RBForm.Group controlId='reporte'>
											<RBForm.Label className='font-weight-bold'>{i18n.t('reports.report_type')}</RBForm.Label>
											<Field name='reporte'>
												{({ field }) => (
													<SelectInput
														{...field}
														placeholder={i18n.t('reports.select')}
														options={reporteOptions}
														searchable={false}
														clearable={false}
														setFieldValue={setFieldValue}
													/>
												)}
											</Field>
											<ErrorMessage name='reporte' component={ErrorFeedback} />
										</RBForm.Group>
									</Col>
									{![REPORTES.Productividad, REPORTES.HorasTrabajadasEmpleado].includes(values.reporte) ? (
										<Col md={4}>
											<RBForm.Group controlId='semana'>
												<RBForm.Label className='font-weight-bold'>{i18n.t('reports.week')}</RBForm.Label>
												<Field name='semana'>
													{({ field }) => (
														<SelectInput
															{...field}
															placeholder={i18n.t('reports.select')}
															options={generarSemanaOptions(4, 2)}
															searchable={false}
															clearable={false}
															setFieldValue={setFieldValue}
														/>
													)}
												</Field>
												<ErrorMessage name='semana' component={ErrorFeedback} />
											</RBForm.Group>
										</Col>
									) : (
										<>
											<Col md={4}>
												<RBForm.Group controlId='fecha_inicio'>
													<RBForm.Label className='font-weight-bold'>{i18n.t('reports.start_date')}</RBForm.Label>
													<Field name='fecha_inicio'>
														{({ field }) => (
															<Datepicker
																{...field}
																setFieldValue={setFieldValue}
																isValidDate={(currentDate: any, selectedDate: any) => currentDate.weekday() === 0}
															/>
														)}
													</Field>
													<ErrorMessage name='fecha_inicio' component={ErrorFeedback} />
												</RBForm.Group>
											</Col>
											<Col md={4}>
												<RBForm.Group controlId='fecha_fin'>
													<RBForm.Label className='font-weight-bold'>{i18n.t('reports.end_date')}</RBForm.Label>
													<Field name='fecha_fin'>
														{({ field }) => (
															<Datepicker
																{...field}
																setFieldValue={setFieldValue}
																isValidDate={(currentDate: any, selectedDate: any) => currentDate.weekday() === 6}
															/>
														)}
													</Field>
													<ErrorMessage name='fecha_fin' component={ErrorFeedback} />
												</RBForm.Group>
											</Col>
										</>
									)}
									{![REPORTES.Productividad, REPORTES.HorasTrabajadasEmpleado].includes(values.reporte) ? (
										<Col md={4}>
											<RBForm.Group controlId='drive_id'>
												<RBForm.Label className='font-weight-bold'>Drive: </RBForm.Label>
												<Field name='drive_id'>
													{({ field }) => (
														<SelectInput
															{...field}
															placeholder={i18n.t('reports.select')}
															options={drivesOptions}
															searchable
															clearable
															isDisabled={!hasPermissionTo('FILTER_BY_DRIVE')}
															setFieldValue={setFieldValue}
														/>
													)}
												</Field>
												<ErrorMessage name='drive_id' component={ErrorFeedback} />
											</RBForm.Group>
										</Col>
									) : (
										<Col md={4}>
											<RBForm.Group controlId='drives'>
												<RBForm.Label className='font-weight-bold'>Drive: </RBForm.Label>
												<Field name='drives'>
													{({ field }) => (
														<MultiSelect
															{...field}
															labelledBy='Select'
															onChange={options => setFieldValue('drives', options)}
															options={drivesOptions}
															value={values.drives}
														/>
													)}
												</Field>
												<ErrorMessage name='drives' component={ErrorFeedback} />
											</RBForm.Group>
										</Col>
									)}
								</Row>
								<div className='d-flex justify-content-center mt-4'>
									<Button variant='danger' className='px-5' type='submit' disabled={loading}>

										<FontAwesomeIcon icon={faDownload} size='lg' className='mr-2' />
										{i18n.t('reports.download_button')}
									</Button>
								</div>
								<ToastContainer />
							</Form>
						)}
					</Formik>
				</Card.Body>
			</Card>
			{loading && <LoadingOverlay />}
		</section>
	);
};

export default ReportesPage;
