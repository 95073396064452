import * as Sentry from '@sentry/browser';

function init() {
   Sentry.init({
      dsn: 'https://817147879cac4b9d9715a731788a6322@sentry.io/1530868'
   });
}

function log(error) {
   Sentry.captureException(error);
}

export default {
   init,
   log
};
