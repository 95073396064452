import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Form as RBForm, Button } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import 'moment/locale/es';
import http from 'services/http.service';
import apiErrorHandler from 'services/apiErrorHandler.service';
import i18n from '../../utils/i18n';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';

export interface NotificacionesPageProps { }

const validationSchema = Yup.object().shape({
	email: Yup.string().email('Invalid email address').required('Email is required'),
	password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
});

const initialValues = { email: '', password: '' };

const NotificacionesPage: React.FC<NotificacionesPageProps> = () => {

	const [mailForm, setMailForm] = useState(initialValues);
	//const { setFieldValue, setValues, values } = useFormikContext();

	const fetchMail = async () => {
		try {
			const { email }: any = await http.get(`notificaciones`);
			//setFieldValue('email', email);
			setMailForm({ email, password: '' });
		} catch (error) {
			console.error(error);
		}
	};


	useEffect(() => {
		fetchMail();
	}, []);

	const handleSubmit = async (values, actions) => {
		try {
			await http.put(`notificaciones`, values);

			setMailForm({ ...mailForm, password: '' });

			await Swal.fire({
				title: i18n.t('notifications.swal_update'),
				icon: 'success',
				confirmButtonText: i18n.t('form.swal_button'),
				allowOutsideClick: false,
				customClass: {
					confirmButton: 'btn btn-primary px-5',
				},
				buttonsStyling: false,
			});
		} catch (error) {
			apiErrorHandler('Empleados', error);
		}
	};

	return (
		<section id='notificaciones'>
			<h3 className='font-weight-bold'>{i18n.t('sidebar.notifications')}</h3>
			<Card>
				<Card.Header className='bg-dark-orange'></Card.Header>
				<Card.Body>
					<Card className="my-3">
						<Card.Body>
							<Card.Title>{i18n.t('notifications.instructions')}</Card.Title>
							<Card.Text>
								{i18n.t('notifications.instructions1')}
							</Card.Text>
							<Card.Text>
								{i18n.t('notifications.instructions2')}
							</Card.Text>
							<a
								href="https://support.google.com/mail/answer/185833?hl=es-419"
								target="_blank"
								rel="noopener noreferrer"
								className="btn btn-link p-0"
							>
								<FontAwesomeIcon icon={faExternalLinkAlt} className='mr-2' fixedWidth />
								<span>{i18n.t('notifications.link')}</span>
							</a>
						</Card.Body>
					</Card>
					<Formik
						initialValues={mailForm}
						enableReinitialize={true}
						validationSchema={validationSchema}
						onSubmit={handleSubmit}
					>
						{({ isSubmitting, errors, touched }) => (
							<Form className="align-items-center">
								<Row className="g-2 align-items-center">
									<Col md={4}>
										<RBForm.Group controlId="formEmail">
											<RBForm.Label>{i18n.t('notifications.email')}</RBForm.Label>
											<Field
												type="email"
												name="email"
												autoComplete="new-email"
												className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
											/>
											{touched.email && errors.email ? (
												<RBForm.Text className="text-danger">{errors.email}</RBForm.Text>
											) : null}
										</RBForm.Group>
									</Col>
									<Col md={4}>
										<RBForm.Group controlId="formPassword">
											<RBForm.Label>{i18n.t('notifications.password')}</RBForm.Label>
											<Field
												type="password"
												name="password"
												autoComplete="new-password"
												className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''}`}
											/>
											{touched.password && errors.password ? (
												<RBForm.Text className="text-danger">{errors.password}</RBForm.Text>
											) : null}
										</RBForm.Group>
									</Col>
									<Col md={4} className="d-flex align-items-end">
										<Button type="submit" disabled={isSubmitting} variant="primary" style={{ 'marginTop': '15px' }}>
											<FontAwesomeIcon icon={faCheck} className='mr-2' fixedWidth />
											{i18n.t('notifications.button')}
										</Button>
									</Col>
								</Row>
							</Form>
						)}
					</Formik>
				</Card.Body>
			</Card>
		</section >
	);
};

export default NotificacionesPage;
