import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from 'components/Auth/Login';
import ForgotPassword from 'components/Auth/ForgotPassword';
// import ChangePassword from 'components/Auth/ChangePassword';

const AuthRoutes: React.FC = () => {
   return (
      <Switch>
         {/* Auth */}
         {/* <Route path='/login/recuperar-password/:token' component={ChangePassword} /> */}
         <Route path='/login/recuperar-password' component={ForgotPassword} />
         <Route path='/login' component={Login} />
      </Switch>
   );
};

export default AuthRoutes;
