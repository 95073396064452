import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Tab, Tabs, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faSave, faSearch } from '@fortawesome/free-solid-svg-icons';
import differenceBy from 'lodash/differenceBy';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import Select from 'react-virtualized-select';
import moment from 'moment';
import 'moment/locale/es';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
// Mis Componentes
import TablaHorarios from './TablaHorarios/TablaHorarios';
import TablaProyecciones from './TablaProyecciones';
import DescansosList from './DescansosList';
import http from 'services/http.service';
import apiErrorHandler from 'services/apiErrorHandler.service';
import { hasPermissionTo } from 'services/authorization.service';
import { CELL_STATUS, HORAS_JORNADA_LABORAL, WEEKDAY_NAME } from 'utils/constants';
import { generarSemanaOptions } from 'utils';
import GuiaHorariosTabContext from './GuiaHorariosTabContext';
// Mis Types
import { Option } from 'typings';
import './GuiaHorariosTab.css';

import i18n from '../../../../utils/i18n';

const TABLA_PROYECCIONES_INITIAL_STATE = {
	tickets_5am: 0,
	tickets_6am: 0,
	tickets_7am: 0,
	tickets_8am: 0,
	tickets_9am: 0,
	tickets_10am: 0,
	tickets_11am: 0,
	tickets_12pm: 0,
	tickets_1pm: 0,
	tickets_2pm: 0,
	tickets_3pm: 0,
	tickets_4pm: 0,
	tickets_5pm: 0,
	tickets_6pm: 0,
	tickets_7pm: 0,
	tickets_8pm: 0,
	tickets_9pm: 0,
	tickets_10pm: 0,
	tickets_11pm: 0,
	operadores_5am: 0,
	operadores_diff_5am: '0',
	operadores_6am: 0,
	operadores_diff_6am: '0',
	operadores_7am: 0,
	operadores_diff_7am: '0',
	operadores_8am: 0,
	operadores_diff_8am: '0',
	operadores_9am: 0,
	operadores_diff_9am: '0',
	operadores_10am: 0,
	operadores_diff_10am: '0',
	operadores_11am: 0,
	operadores_diff_11am: '0',
	operadores_12pm: 0,
	operadores_diff_12pm: '0',
	operadores_1pm: 0,
	operadores_diff_1pm: '0',
	operadores_2pm: 0,
	operadores_diff_2pm: '0',
	operadores_3pm: 0,
	operadores_diff_3pm: '0',
	operadores_4pm: 0,
	operadores_diff_4pm: '0',
	operadores_5pm: 0,
	operadores_diff_5pm: '0',
	operadores_6pm: 0,
	operadores_diff_6pm: '0',
	operadores_7pm: 0,
	operadores_diff_7pm: '0',
	operadores_8pm: 0,
	operadores_diff_8pm: '0',
	operadores_9pm: 0,
	operadores_diff_9pm: '0',
	operadores_10pm: 0,
	operadores_diff_10pm: '0',
	operadores_11pm: 0,
	operadores_diff_11pm: '0',
	productividad_5am: 0,
	productividad_6am: 0,
	productividad_7am: 0,
	productividad_8am: 0,
	productividad_9am: 0,
	productividad_10am: 0,
	productividad_11am: 0,
	productividad_12pm: 0,
	productividad_1pm: 0,
	productividad_2pm: 0,
	productividad_3pm: 0,
	productividad_4pm: 0,
	productividad_5pm: 0,
	productividad_6pm: 0,
	productividad_7pm: 0,
	productividad_8pm: 0,
	productividad_9pm: 0,
	productividad_10pm: 0,
	productividad_11pm: 0,
};
const TOTALES_PROYECCIONES_INITIAL_STATE = {
	totalTickets: 0,
	totalOperadores: 0,
	totalProductividad: 0,
};
const HORAS_X_COLUMNA = {
	'5am': 0,
	'6am': 0,
	'7am': 0,
	'8am': 0,
	'9am': 0,
	'10am': 0,
	'11am': 0,
	'12pm': 0,
	'1pm': 0,
	'2pm': 0,
	'3pm': 0,
	'4pm': 0,
	'5pm': 0,
	'6pm': 0,
	'7pm': 0,
	'8pm': 0,
	'9pm': 0,
	'10pm': 0,
	'11pm': 0,
};
const HORAS_JORNADA_MEDIO_TIEMPO = 6;
const CURRENT_WEEK = `${moment().startOf('week').toISOString()},${moment().endOf('week').toISOString()}`;

const getCurrentDayTab = () => {
	const weekday = moment().day();
	return WEEKDAY_NAME[weekday];
};

const calcularOperadores = operadores => {
	const aux = [...operadores];
	const half = Math.ceil(aux.length / 2);
	const morning = aux.splice(0, half);
	const afternoon = aux.splice(-half);
	const result = Math.max(...morning) + Math.max(...afternoon);
	return result <= 20 ? result : 20;
};

const getEmpleadosDisponiblesOptions = (): JSX.Element[] => {
	const empleadosDisponiblesOptions: JSX.Element[] = [];
	for (let i = 1; i <= 20; i++) {
		empleadosDisponiblesOptions.push(<option key={i}>{i}</option>);
	}
	return empleadosDisponiblesOptions;
};

export interface GuiaHorariosTabProps {
	drive: any;
	empleadosData: { list: any[]; options: Option[] };
	isActiveTab: boolean;
}

const GuiaHorariosTab: React.FC<GuiaHorariosTabProps> = ({ drive, empleadosData, isActiveTab }) => {
	let { id } = useParams();
	const [tabDiasKey, setTabDiasKey] = useState<string>(getCurrentDayTab());
	const [descansosSemana, setDescansosSemana] = useState<any>([]);
	const [currentDate, setCurrentDate] = useState<string>('');
	const [semanaGuia, setSemanaGuia] = useState<string>(CURRENT_WEEK);
	const [metadata, setMetadata] = useState<any>({
		recomendados: 0,
		horasNecesarias: 0,
		horasProgramadas: 0,
	});
	const [empleadosDisponibles, setEmpleadosDisponibles] = useState<number>(0);
	const [horarioAperturaDrive, setHorarioAperturaDrive] = useState<any>();

	// TABLA PROYECCIONES
	const [tablaProyeccionesOriginal, setTablaProyeccionesOriginal] = useState<any>({
		...TABLA_PROYECCIONES_INITIAL_STATE,
	});
	const [tablaProyecciones, setTablaProyecciones] = useState<any>({
		...TABLA_PROYECCIONES_INITIAL_STATE,
	});
	const [totalesProyecciones, setTotalesProyecciones] = useState<any>({
		...TOTALES_PROYECCIONES_INITIAL_STATE,
	});

	// TABLA HORARIO
	const [tablaHorarioOriginal, setTablaHorarioOriginal] = useState<any>({});
	const [tablaHorario, setTablaHorario] = useState<any>({});
	const [totalesHorario, setTotalesHorario] = useState<any>({});
	const [totalesHorasxSemanaHorario, setTotalesHorasxSemanaHorario] = useState<any>({});
	const [empleadosAsignados, setEmpleadosAsignados] = useState<any[]>([]);
	const [horarioSemanal, setHorarioSemanal] = useState<any[]>([]);
	const [horarioSemanalPasado, setHorarioSemanalPasado] = useState<any[]>([]);
	const [guiasHistorial, setGuiasHistorial] = useState<any>();
	const [empleadosOptions, setEmpleadosOptions] = useState<{
		lists: any[][];
		selected: any[];
	}>({
		lists: [],
		selected: [],
	});

	// Llenar las opciones de los selects de empleados
	useEffect(() => {
		let empleadosSelectedOptions: Option[] = [];
		let empleadoOptionsLibres: Option[] = [];
		if (empleadosAsignados.length > 0) {
			empleadosAsignados.forEach(empleado => {
				const optionFound = empleadosData.options.find(option => option.value === empleado.empleado_id);
				if (optionFound) {
					empleadosSelectedOptions.push(optionFound);
				}
			});
			empleadoOptionsLibres = differenceBy(empleadosData.options, empleadosSelectedOptions, 'value');
		}
		const empleadosOptionsLists: any[][] = [];
		for (let i = 0; i < empleadosDisponibles; i++) {
			if (empleadosSelectedOptions.length > 0 && empleadosSelectedOptions[i]) {
				empleadosOptionsLists[i] = [empleadosSelectedOptions[i], ...empleadoOptionsLibres];
			} else if (empleadosSelectedOptions.length > 0 && empleadosDisponibles > empleadosSelectedOptions.length) {
				empleadosOptionsLists[i] = empleadoOptionsLibres;
			} else {
				empleadosOptionsLists[i] = empleadosData.options;
			}
		}
		setEmpleadosOptions({
			lists: empleadosOptionsLists,
			selected: empleadosSelectedOptions,
		});
	}, [empleadosData.options, empleadosDisponibles, empleadosAsignados]);

	// LISTA DESCANSOS
	const [descansosAsignados, setDescansosAsignados] = useState<any[]>([]);

	// Recalcular el total de la productividad
	useEffect(() => {
		let totalTickets = 0;
		let totalOperadores = 0;
		Object.entries<number | string>(tablaProyecciones).forEach(([key, value]) => {
			if (!key.includes('operadores_diff')) {
				if (key.includes('tickets')) {
					totalTickets = totalTickets + Number(value);
				} else if (key.includes('operadores')) {
					totalOperadores += Number(value);
				}
			}
		});

		let totalProductividad = totalOperadores > 0 ? Number((totalTickets / totalOperadores).toFixed(1)) : 0;
		setTotalesProyecciones(() => {
			return {
				totalTickets,
				totalOperadores,
				totalProductividad,
			};
		});
	}, [tablaProyecciones]);

	useEffect(() => {
		function calcularEmpleadosDisponibles() {
			// Originales por que si no automaticamente cambie el numero de recomendados en cuanto das clic a una hora extra.
			const operadoresxHora: any[] = Object.entries(tablaProyeccionesOriginal)
				.filter(([key, value]) => key.includes('operadores') && !key.includes('operadores_diff'))
				.map(([key, value]) => value);

			const newEmpleadosDisponibles = calcularOperadores(operadoresxHora);
			if (newEmpleadosDisponibles > empleadosDisponibles) {
				setEmpleadosDisponibles(newEmpleadosDisponibles);
			}
		}
		calcularEmpleadosDisponibles();
	}, [tablaProyeccionesOriginal]);

	useEffect(() => {
		if (empleadosDisponibles && empleadosAsignados.length > 0) {
			calcularTablaHorarioWithProyecciones(false);
		} else {
			calcularTablaHorarioWithProyecciones();
		}
	}, [empleadosDisponibles]);
	const calcularTablaHorarioWithProyecciones = (ignoreEmpleados: boolean = true) => {
		let tablaHorarioData = {};
		let totalesHorarioData = {};
		let horasxRenglon = {};
		let horasxColumna = cloneDeep(HORAS_X_COLUMNA);
		const horas: string[] = Object.keys(tablaProyecciones)
			.filter(keyName => keyName.startsWith('operadores') && !keyName.includes('diff'))
			.map(horaOperadorKey => horaOperadorKey.split('_')[1]);

		for (let i = 0; i < empleadosDisponibles; i++) {
			horasxRenglon[i] = 0;
			totalesHorarioData = {
				...totalesHorarioData,
				[`row-${i}`]: 0,
			};
			const empleado_id = ignoreEmpleados ? tablaHorario[i]?.empleado_id || null : empleadosAsignados[i]?.empleado_id || null;
			const rol_id = ignoreEmpleados ? tablaHorario[i]?.rol_id || null : empleadosAsignados[i]?.rol_id || null;
			const actividad_secundaria = ignoreEmpleados
				? tablaHorario[i]?.actividad_secundaria || ''
				: empleadosAsignados[i]?.actividad_secundaria || '';
			/* eslint-disable no-loop-func */
			horas.forEach(hora => {
				// NOTE: Si el horario ya tiene empleados asignados se deben usar las horas guardadas a cada usuario
				if (empleado_id) {
					let horaValue = false;
					if (empleadosAsignados[i] && empleadosAsignados[i][`_${hora}`]) {
						horaValue = empleadosAsignados[i][`_${hora}`];
						horasxRenglon[i]++;
						horasxColumna[`${hora}`]++;
					} else if (!empleadosAsignados[i] && tablaHorario[i] && tablaHorario[i][hora]) {
						horaValue = tablaHorario[i][hora].value;
						horasxRenglon[i]++;
						horasxColumna[`${hora}`]++;
					}

					let disponibilidad = {};
					if (empleadosAsignados[i]) {
						disponibilidad = getDisponibilidadEmpleadoDelDia(empleadosAsignados[i].empleado.disponibilidad[0]);
					} else {
						disponibilidad = tablaHorario[i].disponibilidad;
					}
					tablaHorarioData[`${i}`] = {
						...tablaHorarioData[`${i}`],
						empleado_id,
						rol_id,
						actividad_secundaria,
						disponibilidad,
						[hora]: {
							value: horaValue,
							status: CELL_STATUS.Recomendado,
						},
					};
					totalesHorarioData = {
						...totalesHorarioData,
						[`row-${i}`]: horasxRenglon[i],
					};
					// NOTE: Sino entonces se debe llenar la tabla en base a las proyecciones
				} else if (
					horasxRenglon[i] < HORAS_JORNADA_LABORAL &&
					horasxColumna[hora] < tablaProyecciones[`operadores_${hora}`] &&
					!isHoraInactiva(hora)
				) {
					horasxRenglon[i]++;
					horasxColumna[`${hora}`]++;
					tablaHorarioData[`${i}`] = {
						...tablaHorarioData[`${i}`],
						empleado_id,
						rol_id,
						actividad_secundaria,
						disponibilidad: {},
						[hora]: {
							value: true,
							status: CELL_STATUS.Recomendado,
						},
					};
					totalesHorarioData = {
						...totalesHorarioData,
						[`row-${i}`]: horasxRenglon[i],
					};
				} else {
					tablaHorarioData[`${i}`] = {
						...tablaHorarioData[`${i}`],
						empleado_id,
						rol_id,
						actividad_secundaria,
						disponibilidad: {},
						[hora]: {
							value: false,
							status: CELL_STATUS.Recomendado,
						},
					};
				}
			});
			/* eslint-enable no-loop-func */
		}

		setTablaHorarioOriginal({
			...tablaHorarioData,
		});
		setTablaHorario({
			...tablaHorarioData,
		});
		setTotalesHorario({
			...totalesHorarioData,
		});
	};

	const fetchHorarioSemanaPasada = useCallback(async () => {
		try {
			console.log("entró en fetchHorarioSemanaPasada")
			const params = {
				drive_id: id,
				fecha_inicio: moment(semanaGuia.split(',')[0]).subtract(7, 'days').format('YYYY-MM-DD'),
				fecha_fin: moment(semanaGuia.split(',')[1]).subtract(7, 'days').format('YYYY-MM-DD'),
			};
			const horarioSemanaPasada: any = await http.get('guia', { params });
			setHorarioSemanalPasado(horarioSemanaPasada);
		} catch (error) {
			apiErrorHandler('Guia', error);
		}
	}, [id, semanaGuia]);
	useEffect(() => {
		fetchHorarioSemanaPasada();
	}, [fetchHorarioSemanaPasada]);

	const fetchGuiaHorarioSemanal = useCallback(async () => {
		try {
			console.log("tabxd: ", tabDiasKey)
			const params = {
				drive_id: id,
				fecha_inicio: moment(semanaGuia.split(',')[0]).format('YYYY-MM-DD'),
				fecha_fin: moment(semanaGuia.split(',')[1]).format('YYYY-MM-DD'),
			};
			const horarioSemanalData: any = await http.get('guia', { params });
			setHorarioSemanal(horarioSemanalData);
		} catch (error) {
			apiErrorHandler('Guia', error);
		}
	}, [id, semanaGuia]);
	useEffect(() => {
		fetchGuiaHorarioSemanal();
	}, [fetchGuiaHorarioSemanal]);

	const fetchGuias = useCallback(async () => {
		try {
			const params = {
				drive_id: id,
				fecha_inicio: moment(semanaGuia.split(',')[0]).format('YYYY-MM-DD'),
				fecha_fin: moment(semanaGuia.split(',')[1]).format('YYYY-MM-DD'),
			};
			const guiasHistorialData: any = await http.get('guia/dias', { params });
			setGuiasHistorial(guiasHistorialData);
		} catch (error) {
			console.error(error);
		}
	}, [id, semanaGuia]);
	useEffect(() => {
		fetchGuias();
	}, [fetchGuias, semanaGuia]);

	const fetchDescansosSemana = useCallback(async () => {
		const params = {
			dia: tabDiasKey,
			fecha_inicio: moment(semanaGuia.split(',')[0]).format('YYYY-MM-DD'),
			fecha_fin: moment(semanaGuia.split(',')[1]).format('YYYY-MM-DD'),
		};
		const diaGuiaHorarioData: any = await http.get(`drive/${id}/dia-guias`, { params });
		getDescansosSemana(diaGuiaHorarioData);
	}, [id, semanaGuia]);
	useEffect(() => {
		fetchDescansosSemana();
	}, [fetchDescansosSemana]);


	// Recalcula las horas programadas
	useEffect(() => {
		if (Object.keys(tablaHorario).length > 0) {
			const tempTotalesHorasxSemanaHorario = cloneDeep(totalesHorasxSemanaHorario);

			Object.values<any>(tablaHorario).forEach((horarioEmpleado, index) => {
				if (horarioEmpleado.empleado_id) {
					const horasSemanales = calcularHorasSemanales(horarioEmpleado.empleado_id, index);
					tempTotalesHorasxSemanaHorario[`row-${index}`] = horasSemanales;
				}
			});

			setTotalesHorasxSemanaHorario({
				...tempTotalesHorasxSemanaHorario,
			});
		}

		const horasProgramadas = Object.values<number>(totalesHorario).reduce((total, currentValue) => (total += currentValue), 0);
		setMetadata(prev => ({
			...prev,
			horasProgramadas,
		}));
	}, [tablaHorario]);
	const calcularHorasSemanales = (empleadoId: number, row: number) => {
		let horasSemanales = 0;
		horarioSemanal.forEach((horarioxDia: any) => {
			if (horarioxDia.dia !== tabDiasKey) {
				const empleadoFoundOnWeek = horarioxDia.empleados_asignados.find(empleado => empleado.empleado_id === empleadoId);
				let horasxDia = 0;
				if (empleadoFoundOnWeek) {
					Object.entries<boolean>(empleadoFoundOnWeek).forEach(([key, value]) => {
						if (!key.includes('empleado_id') && value) {
							horasxDia++;
						}
					});
				}
				horasSemanales += horasxDia;
			} else {
				horasSemanales += totalesHorario[`row-${row}`];
			}
		});
		return horasSemanales;
	};

	const isHoraInactiva = useCallback(
		(hora: string) => (horarioAperturaDrive && !horarioAperturaDrive[`_${hora}`] ? true : false),
		[horarioAperturaDrive],
	);
	// TABLA HORARIO
	const mapProyeccionesToHorariosStateModel = useCallback(
		(proyecciones: any, disponibles: number, original: boolean) => {
			let tablaHorarioData = {};
			let totalesHorarioData = {};
			let horasxRenglon = {};
			let horasxColumna = cloneDeep(HORAS_X_COLUMNA);
			const horas: string[] = Object.keys(proyecciones)
				.filter(keyName => keyName.startsWith('operadores') && !keyName.includes('diff'))
				.map(horaOperadorKey => horaOperadorKey.split('_')[1]);

			for (let i = 0; i < disponibles; i++) {
				horasxRenglon[i] = 0;
				totalesHorarioData = {
					...totalesHorarioData,
					[`row-${i}`]: 0,
				};

				// eslint-disable-next-line no-loop-func
				horas.forEach(hora => {
					if (
						horasxRenglon[i] < HORAS_JORNADA_LABORAL &&
						horasxColumna[hora] < proyecciones[`operadores_${hora}`] &&
						!isHoraInactiva(hora)
					) {
						horasxRenglon[i]++;
						horasxColumna[`${hora}`]++;
						tablaHorarioData[`${i}`] = {
							...tablaHorarioData[`${i}`],
							empleado_id: null,
							rol_id: null,
							actividad_secundaria: '',
							[hora]: {
								value: true,
								status: CELL_STATUS.Recomendado,
							},
						};
						totalesHorarioData = {
							...totalesHorarioData,
							[`row-${i}`]: horasxRenglon[i],
						};
					} else {
						tablaHorarioData[`${i}`] = {
							...tablaHorarioData[`${i}`],
							empleado_id: null,
							rol_id: null,
							actividad_secundaria: '',
							[hora]: {
								value: false,
								status: CELL_STATUS.Recomendado,
							},
						};
					}
				});
			}

			if (original) {
				setTablaHorarioOriginal({
					...tablaHorarioData,
				});
			} else {
				setTablaHorario({
					...tablaHorarioData,
				});
			}

			setTotalesHorario({
				...totalesHorarioData,
			});
		},
		[isHoraInactiva],
	);
	const mapEmpleadosHorariosToTablaHorariosState = (empleadosHorarios: any[]) => {
		let tablaHorarioData = {};
		let totalesHorarioData = {};
		let horasxRenglon = {};

		empleadosHorarios.forEach((empleadoHorario, index) => {
			let row = index;
			horasxRenglon[row] = 0;
			totalesHorarioData = {
				...totalesHorarioData,
				[`row-${row}`]: 0,
			};

			for (const [hora, value] of Object.entries(empleadoHorario)) {
				if (value === true) horasxRenglon[row]++;

				if (['empleado_id', 'rol_id', 'actividad_secundaria'].includes(hora)) {
					tablaHorarioData[`${row}`] = {
						...tablaHorarioData[`${row}`],
						[hora]: value,
					};
				} else if (['empleado'].includes(hora)) {
					tablaHorarioData[`${row}`] = {
						...tablaHorarioData[`${row}`],
						disponibilidad: getDisponibilidadEmpleadoDelDia(empleadoHorario.empleado.disponibilidad[0]),
					};
				} else {
					tablaHorarioData[`${row}`] = {
						...tablaHorarioData[`${row}`],
						[hora.split('_')[1]]: {
							value,
							status: CELL_STATUS.Recomendado,
						},
					};
					totalesHorarioData = {
						...totalesHorarioData,
						[`row-${row}`]: horasxRenglon[row],
					};
				}
			}
		});

		setEmpleadosDisponibles(empleadosHorarios.length);
		setTablaHorarioOriginal({
			...tablaHorarioData,
		});
		setTablaHorario({
			...tablaHorarioData,
		});
		setTotalesHorario({
			...totalesHorarioData,
		});
	};

	const mapDescansosAsignadosToViewModel = (descansosAsignadosData: any[]) => {
		return descansosAsignadosData.map(data => ({
			dia_guia_descanso_id: data.dia_guia_descanso_id,
			empleado_id: data.empleado.empleado_id,
			operador: data.empleado.nombre,
			descanso_id: data.descanso.descanso_id,
			tipoDescanso: data.descanso.nombre,
		}));
	};
	// TABLA PROYECCIONES
	const mapProyeccionesToStateModel = useCallback(
		(guiaHorario: any, empleados: any[]) => {
			let proyeccionOriginal = { ...TABLA_PROYECCIONES_INITIAL_STATE };
			let totalOperadores = 0;
			let operadoresxHora: number[] = [];

			const horariosCalculados: any = guiaHorario?.proyecciones;
			for (const [key, horario] of Object.entries<any>(horariosCalculados)) {
				proyeccionOriginal[`tickets${key}`] = Number(horario.tickets_proyectados);
				proyeccionOriginal[`operadores${key}`] = horario.empleados;
				totalOperadores += horario.empleados;
				operadoresxHora.push(Number(horario.empleados));
				proyeccionOriginal[`productividad${key}`] = Number(horario.productividad.toFixed(1));
			}

			let proyeccionActual = { ...proyeccionOriginal };
			if (guiaHorario?.historial) {
				const horariosHistorial: any = guiaHorario?.historial;
				for (const [key, horario] of Object.entries<any>(horariosHistorial)) {
					proyeccionActual[`tickets${key}`] = Number(horario.tickets_proyectados);
					proyeccionActual[`operadores${key}`] = horario.empleados;
					proyeccionActual[`productividad${key}`] = Number(horario.productividad.toFixed(1));
					const diff = horario.empleados - proyeccionOriginal[`operadores${key}`];
					proyeccionActual[`operadores_diff${key}`] = diff > 0 ? `+${diff}` : diff;
				}
			}

			setMetadata(metadata => ({
				...metadata,
				recomendados: calcularOperadores(operadoresxHora),
				horasNecesarias: Number(totalOperadores),
			}));
			setTablaProyeccionesOriginal({
				...proyeccionOriginal,
			});
			setTablaProyecciones({
				...proyeccionActual,
			});

			if (empleados?.length === 0) {
				const empleadosDisponiblesData = calcularOperadores(operadoresxHora);
				setEmpleadosDisponibles(empleadosDisponiblesData);
				mapProyeccionesToHorariosStateModel(proyeccionActual, empleadosDisponiblesData, false);
			}
		},
		[mapProyeccionesToHorariosStateModel],
	);
	const populateTables = useCallback(
		(guiaHorario: any) => {
			mapProyeccionesToStateModel(guiaHorario, guiaHorario?.empleados_asignados);
			if (guiaHorario?.empleados_asignados?.length > 0) {
				setEmpleadosAsignados(guiaHorario?.empleados_asignados);
				mapEmpleadosHorariosToTablaHorariosState(guiaHorario?.empleados_asignados);
			}
			setDescansosAsignados(mapDescansosAsignadosToViewModel(guiaHorario?.descansos));
		},
		[mapProyeccionesToStateModel],
	);
	useEffect(() => {
		if (!isActiveTab || !drive) return;
		setEmpleadosDisponibles(0);
		setTotalesHorasxSemanaHorario({});
		setEmpleadosAsignados([]);
		const semana = semanaGuia.split(',')[0];
		moment.locale(i18n.language);
		switch (tabDiasKey) {
			case 'LUNES':
				setCurrentDate(moment(semana).day(1).format(i18n.language == 'es' ? 'dddd, DD [de] MMMM [de] YYYY' : 'dddd, MMMM DD, YYYY'));
				break;
			case 'MARTES':
				setCurrentDate(moment(semana).day(2).format(i18n.language == 'es' ? 'dddd, DD [de] MMMM [de] YYYY' : 'dddd, MMMM DD, YYYY'));
				break;
			case 'MIERCOLES':
				setCurrentDate(moment(semana).day(3).format(i18n.language == 'es' ? 'dddd, DD [de] MMMM [de] YYYY' : 'dddd, MMMM DD, YYYY'));
				break;
			case 'JUEVES':
				setCurrentDate(moment(semana).day(4).format(i18n.language == 'es' ? 'dddd, DD [de] MMMM [de] YYYY' : 'dddd, MMMM DD, YYYY'));
				break;
			case 'VIERNES':
				setCurrentDate(moment(semana).day(5).format(i18n.language == 'es' ? 'dddd, DD [de] MMMM [de] YYYY' : 'dddd, MMMM DD, YYYY'));
				break;
			case 'SABADO':
				setCurrentDate(moment(semana).day(6).format(i18n.language == 'es' ? 'dddd, DD [de] MMMM [de] YYYY' : 'dddd, MMMM DD, YYYY'));
				break;
			case 'DOMINGO':
				setCurrentDate(moment(semana).day(7).format(i18n.language == 'es' ? 'dddd, DD [de] MMMM [de] YYYY' : 'dddd, MMMM DD, YYYY'));
				break;
			default:
				setCurrentDate(moment(semana).day(1).format(i18n.language == 'es' ? 'dddd, DD [de] MMMM [de] YYYY' : 'dddd, MMMM DD, YYYY'));
				break;
		}

		const horarioAperturaDriveFound = drive.horario_empresa.find(horarioEmpresa => horarioEmpresa.dia_semana === tabDiasKey);
		setHorarioAperturaDrive(horarioAperturaDriveFound);

		const getTranslations = async () => {
			return {
				processing: i18n.t('drives.swal_processing'),
				waiting: i18n.t('drives.swal_wait')
			}
		}

		const fetchProyecciones = async () => {
			try {
				const { processing, waiting } = await getTranslations();
				Swal.fire(processing, waiting, 'info');
				Swal.showLoading();

				const params = {
					drive_id: id,
					dia: tabDiasKey,
					fecha_inicio: moment(semanaGuia.split(',')[0]).format('YYYY-MM-DD'),
					fecha_fin: moment(semanaGuia.split(',')[1]).format('YYYY-MM-DD'),
				};
				const proyeccionesGuiaHorario: any[] = await http.get('guia', { params });
				populateTables(proyeccionesGuiaHorario);

				Swal.close();
			} catch (error) {
				apiErrorHandler('Guia', error);
			}
		};
		fetchProyecciones();
	}, [id, isActiveTab, tabDiasKey, semanaGuia, drive]);

	// METADATA EVENT HANDLERS
	const handleSemanaGuiaChange = (option: Option) => {
		setSemanaGuia(option.value as string);
		setTabDiasKey('LUNES');
		setEmpleadosAsignados([]);
	};

	const handleEmpleadosDisponibleChange = ({ target: input }: React.ChangeEvent<HTMLSelectElement>) => {
		setEmpleadosDisponibles(Number(input.value));
	};

	const handleTabDiaKeySelect = async key => {
		console.log("valor de key: ", key)
		if (isEqual(tablaProyeccionesOriginal, tablaProyecciones) && isEqual(tablaHorarioOriginal, tablaHorario)) {
			console.log("entró aquí en el if de isEqual")
			setTabDiasKey(key);
			return;
		}
		console.log("entró en handlediakeyselect xd")

		const getTranslations = async () => {
			return {
				title: i18n.t('drives.swal_warning_title'),
				text: i18n.t('drives.swal_warning_proceed'),
				accept: i18n.t('drives.swal_accept_button'),
				cancel: i18n.t('drives.swal_cancel_button'),
			}
		}

		const { title, text, accept, cancel } = await getTranslations();

		const result = await Swal.fire({
			title,
			text,
			icon: 'warning',
			confirmButtonText: accept,
			cancelButtonText: cancel,
			showCancelButton: true,
			focusCancel: true,
			allowOutsideClick: false,
			customClass: {
				confirmButton: 'btn btn-success mr-3 px-4',
				cancelButton: 'btn btn-danger px-4',
			},
			buttonsStyling: false,
		});
		if (result.value) {
			setTabDiasKey(key);
		}
	};

	// GUARDAR CAMBIOS
	const handleGuardarCambiosClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		const getTranslations = async () => {
			return {
				title: i18n.t('drives.swal_warning_title'),
				text_schedule: i18n.t('drives.swal_employee_without_schedule'),
				text_hours: i18n.t('drives.swal_employee_without_schedule'),
				accept: i18n.t('drives.swal_accept_button'),
				cancel: i18n.t('drives.swal_cancel_button'),
			}
		}

		const { title, text_schedule, text_hours, accept, cancel } = await getTranslations();
		if (hasHorarioNoAsignado()) {
			Swal.fire(title, text_schedule, 'warning');
			return;
		}
		if (hasEmpleadoWithLessThanSixHours()) {
			const result = await Swal.fire({
				title,
				text: text_hours,
				icon: 'warning',
				confirmButtonText: accept,
				cancelButtonText: cancel,
				showCancelButton: true,
				focusCancel: true,
				allowOutsideClick: false,
				customClass: {
					confirmButton: 'btn btn-success mr-3 px-4',
					cancelButton: 'btn btn-danger px-4',
				},
				buttonsStyling: false,
			});
			if (result.value) {
				saveGuiaHorarios();
			}
		} else {
			saveGuiaHorarios();
		}
	};
	const hasHorarioNoAsignado = (): boolean => {
		const rows: any[] = Object.values(tablaHorario);
		const horarioSinEmpleadoFound = rows.find(row => row.empleado_id === null);
		if (horarioSinEmpleadoFound) {
			const hasHoras = Object.entries(horarioSinEmpleadoFound).reduce((accumulator, [key, x]: any) => {
				return x && x.value ? true : accumulator;
			}, false);
			if (descansosAsignados.length == empleadosOptions.lists[0].length) {
				return false;
			}
			return hasHoras;
			// return true;
		}
		return false;
	};
	const hasEmpleadoWithLessThanSixHours = (): boolean => {
		let hasLessThanSixHours = false;
		Object.keys(tablaHorario).some(key => {
			let horasxEmpleado = 0;
			Object.entries(tablaHorario[key])
				.filter(([key]) => !key.includes('empleado_id'))
				.forEach(([, value]) => {
					if (value) {
						horasxEmpleado++;
					}
				});
			if (horasxEmpleado < HORAS_JORNADA_MEDIO_TIEMPO) {
				hasLessThanSixHours = true;
				return true; // Se utiliza para romper el ciclo
			}
			return false;
		});
		return hasLessThanSixHours;
	};
	const saveGuiaHorarios = async () => {
		try {
			const getTranslations = async () => {
				return {
					processing: i18n.t('drives.swal_processing'),
					waiting: i18n.t('drives.swal_wait'),
					completed: i18n.t('drives.swal_completed'),
					saved: i18n.t('drives.swal_saved_changes')
				}
			}

			const { processing, waiting, completed, saved } = await getTranslations();
			Swal.fire(processing, waiting, 'info');
			Swal.showLoading();

			const body = {
				fecha_inicio: moment(semanaGuia.split(',')[0]).format('YYYY-MM-DD'),
				fecha_fin: moment(semanaGuia.split(',')[1]).format('YYYY-MM-DD'),
				drive_id: Number(id),
				dia: tabDiasKey,
				calculo_dia: mapTablaProyeccionesToBodyModel(),
				dia_horario: mapTablaHorariosToBodyModel(),
				descansos: mapDescansosToBodyModel(),
			};

			const guia: any = await http.post('guia', body);

			//AQUIIIII
			fetchGuiaHorarioSemanal();
			fetchHorarioSemanaPasada();



			const params = {
				drive_id: Number(id),
				dia: tabDiasKey,
				fecha_inicio: moment(semanaGuia.split(',')[0]).format('YYYY-MM-DD'),
				fecha_fin: moment(semanaGuia.split(',')[1]).format('YYYY-MM-DD'),
			};
			const proyeccionesGuiaHorario: any[] = await http.get('guia', { params });
			populateTables(proyeccionesGuiaHorario);

			Swal.fire(completed, saved, 'success');

			// Actualizar los datos originales con los datos guardados
			// setTablaProyeccionesOriginal({ ...tablaProyecciones });
			setTablaHorarioOriginal({ ...tablaHorario });
			setDescansosAsignados(mapDescansosAsignadosToViewModel(guia.descansos));
		} catch (error) {
			console.error(error);
			apiErrorHandler('Guia', error);
		}
	};
	const mapTablaProyeccionesToBodyModel = () => {
		let body: any[] = [{}];

		for (const [key, value] of Object.entries<number | string>(tablaProyecciones)) {
			const hora = key.split('_')[1];
			if (!key.includes('operadores_diff')) {
				if (key.includes('operadores')) {
					if (isHoraInactiva(hora)) {
						continue;
					}
					body[0] = {
						...body[0],
						[`empleados_programados_${hora}`]: value,
					};
					continue;
				} else if (key.includes('tickets')) {
					if (isHoraInactiva(hora)) {
						continue;
					}
					body[0] = {
						...body[0],
						[`tickets_programados_${hora}`]: value,
					};
					continue;
				}
				body[0] = {
					...body[0],
					[key]: value,
				};
			}
		}

		const tablaProyeccionesOriginalCopy = cloneDeep(tablaProyeccionesOriginal);
		for (const [key, value] of Object.entries<number | string>(tablaProyeccionesOriginalCopy)) {
			const hora = key.split('_')[1];
			if (!key.includes('operadores_diff')) {
				if (key.includes('tickets')) {
					if (isHoraInactiva(hora)) {
						delete body[0][key];
						continue;
					}
					body[0] = {
						...body[0],
						[`tickets_proyectados_${hora}`]: value,
					};
					delete body[0][key];
					continue;
				} else if (key.includes('operadores')) {
					if (isHoraInactiva(hora)) {
						continue;
					}
					body[0] = {
						...body[0],
						[`empleados_proyectados_${hora}`]: value,
					};
					continue;
				}
			}
		}
		return body;
	};
	const mapTablaHorariosToBodyModel = () => {
		const tablaHorarioClone = cloneDeep(tablaHorario);
		const newTablaHorario = Object.entries(tablaHorarioClone).reduce((accumulator, [rowNumber, horario]: any) => {
			return {
				...accumulator,
				...(horario.empleado_id && { [rowNumber]: horario }),
			};
		}, {});
		const body = Object.values<any>(newTablaHorario).map(horario => {
			for (const [key, value] of Object.entries<any>(horario)) {
				if (!['empleado_id', 'rol_id', 'actividad_secundaria'].includes(key)) {
					if (isHoraInactiva(key)) {
						delete horario[key];
						continue;
					}
					horario[`_${key}`] = value.value;
					delete horario[key];
				}
			}
			return horario;
		});
		return body;
	};
	const mapDescansosToBodyModel = () => {
		return descansosAsignados
			.filter(descanso => !descanso.dia_guia_descanso_id)
			.map(descanso => ({
				empleado_id: descanso.empleado_id,
				descanso_id: descanso.descanso_id,
			}));
	};

	// UTILS
	const obtenerDiferenciaDeOperadores = (oldValue: string, newValue: string): string => {
		if (oldValue !== newValue) {
			if (newValue === '0') {
				return `${-oldValue}`;
			} else if (oldValue < newValue) {
				let diff = parseInt(newValue) - parseInt(oldValue);
				return `${diff < 0 ? diff : '+' + diff}`;
			} else {
				let diff = parseInt(newValue) - parseInt(oldValue);
				return `${diff < 0 ? diff : '+' + diff}`;
			}
		}
		return '0';
	};
	const obtenerDiferenciaEnTotales = (oldValue: number, newValue: number): number => {
		if (oldValue !== newValue) {
			if (newValue === 0) {
				return -Math.abs(oldValue);
			} else if (oldValue < newValue) {
				return newValue - oldValue;
			} else {
				return newValue - oldValue;
			}
		}
		return oldValue;
	};
	const calcularTotales = (rowName: string, fieldValue: number) => {
		switch (rowName) {
			case 'tickets':
				setTotalesProyecciones(prevTotales => {
					return {
						...totalesProyecciones,
						totalTickets: prevTotales.totalTickets + fieldValue,
					};
				});
				break;
			case 'operadores':
				setTotalesProyecciones(prevTotales => {
					return {
						...totalesProyecciones,
						totalOperadores: prevTotales.totalOperadores + fieldValue,
					};
				});
				break;
		}
	};
	const calcularProductividadxHora = (rowName: string, columnName: string, fieldValue: number): number => {
		if (rowName === 'tickets') {
			return fieldValue !== 0 ? Number((fieldValue / tablaProyecciones[`operadores_${columnName}`]).toFixed(1)) : 0;
		}
		return fieldValue !== 0 ? Number((tablaProyecciones[`tickets_${columnName}`] / fieldValue).toFixed(1)) : 0;
	};

	const onRecalcularProyeccionesClick = async () => {
		try {
			const getTranslations = async () => {
				return {
					processing: i18n.t('drives.swal_processing'),
					waiting: i18n.t('drives.swal_wait'),
					calculation: i18n.t('drives.swal_calculation'),
					calculation_text: i18n.t('drives.swal_calculation_text'),
				}
			}

			const { processing, waiting, calculation, calculation_text } = await getTranslations();
			Swal.fire(processing, waiting, 'info');
			Swal.showLoading();

			const ticketsOriginales = Object.entries(tablaProyeccionesOriginal).filter(([key, value]) => key.includes('tickets'));
			const tickets = Object.entries(tablaProyecciones).filter(([key, value]) => key.includes('tickets'));
			if (isEqual(ticketsOriginales, tickets)) {
				calcularTablaHorarioWithProyecciones(false);
				Swal.fire(calculation, calculation_text, 'success');
			} else {
				const body = mapTicketsToBodyModel();
				const proyeccionesRecalculadas: any = await http.post(`guia/recalcular`, body);
				Swal.fire(calculation, calculation_text, 'success');
				populateTables(proyeccionesRecalculadas);
			}
		} catch (error) {
			apiErrorHandler('Guia', error);
		}
	};
	const mapTicketsToBodyModel = () => {
		const body = {
			drive_id: id,
			dia: tabDiasKey,
			tickets: {},
		};
		Object.entries(tablaProyecciones).forEach(([key, value]) => {
			if (key.includes('tickets')) {
				body.tickets[`_${key.split('_')[1]}`] = value;
			}
		});
		return body;
	};

	const [zoom, setZoom] = useState(100);
	const [zoomActive, setZoomActive] = useState(true);
	const calculateZoom = useCallback((elementId: string = 'guia-container') => {
		const element = document.getElementById(elementId)!;
		if (element.scrollWidth > element.clientWidth) {
			setZoom((element.clientWidth * 100) / element.scrollWidth);
		} else {
			setZoom(100);
		}
	}, []);
	useEffect(() => {
		if (isActiveTab && zoomActive) {
			calculateZoom();
		} else {
			setZoom(100);
		}
	}, [calculateZoom, isActiveTab, zoomActive]);

	const handleDownloadReporteClick = async () => {
		try {
			const getTranslations = async () => {
				return {
					processing: i18n.t('drives.swal_processing'),
					waiting: i18n.t('drives.swal_wait'),
				}
			}

			const { processing, waiting } = await getTranslations();
			Swal.fire(processing, waiting, 'info');
			Swal.showLoading();

			const body = {
				fecha_inicio: moment(semanaGuia.split(',')[0]).format('YYYY-MM-DD'),
				fecha_fin: moment(semanaGuia.split(',')[1]).format('YYYY-MM-DD'),
				drive_id: drive.drive_id,
			};
			const response: any = await http.post('reporte/tabla-piso', body, { responseType: 'blob' });
			saveAs(response.data, 'tabla-piso.xlsx');

			Swal.close();
		} catch (error) {
			apiErrorHandler('Guia', error);
		}
	};

	const getDisponibilidadEmpleadoDelDia = useCallback((disponibilidadEmpleado: object) => {
		const mappedDisponibilidad = {};
		Object.entries(disponibilidadEmpleado).forEach(([key, value]) => {
			if (key.startsWith('_')) {
				let hora = key.substring(1);
				mappedDisponibilidad[hora] = value;
			}
		});
		return mappedDisponibilidad;
	}, []);

	const getDisponibilidadEmpleadosDelDia = useCallback(() => {
		return empleadosData.list.map((empleado: any) => {
			const disponibilidadDelDia = empleado.disponibilidad.filter(disp => disp.dia === tabDiasKey);
			const mappedDisponibilidad = {};
			Object.entries(disponibilidadDelDia[0]).forEach(([key, value]) => {
				if (key.startsWith('_')) {
					let hora = key.substring(1);
					mappedDisponibilidad[hora] = value;
				}
			});
			return {
				...empleado,
				disponibilidad: mappedDisponibilidad,
			};
		});
	}, [empleadosData.list, tabDiasKey]);

	const descansoEmpleadoOptions: Option[] = useMemo(() => {
		return differenceBy(empleadosData.options, empleadosOptions.selected, 'value');
	}, [empleadosData.options, empleadosOptions.selected]);

	const getDescansosSemana = async diaGuiaHorario => {
		let descansosSemana = [];
		descansosSemana = await http.get(`/guia/descansosSemana?guia_id=` + diaGuiaHorario?.guia_horarios[0]?.guia_id);
		setDescansosSemana(descansosSemana);
	};

	return (
		<>
			<div className='row mb-3'>
				<div className='col-md-3'>
					<div className='form-group'>
						<label className='font-weight-bold' htmlFor='semana-select'>
							{i18n.t('drives.select_week')}
						</label>
						<Select
							name='semanaGuia'
							options={generarSemanaOptions(4, 2)}
							searchable={false}
							clearable={false}
							onChange={handleSemanaGuiaChange}
							value={semanaGuia}
						/>
					</div>
				</div>
				<div className='col-md-9'>
					<div className='form-group'>
						<label className='font-weight-bold'>{i18n.t('drives.select_day')}</label>
						<div>
							<Tabs id='dias' className='tabs-dias bg-light' activeKey={tabDiasKey} onSelect={handleTabDiaKeySelect}>
								<Tab eventKey='LUNES' title={i18n.t('weekdays.monday')}></Tab>
								<Tab eventKey='MARTES' title={i18n.t('weekdays.tuesday')}></Tab>
								<Tab eventKey='MIERCOLES' title={i18n.t('weekdays.wednesday')}></Tab>
								<Tab eventKey='JUEVES' title={i18n.t('weekdays.thursday')}></Tab>
								<Tab eventKey='VIERNES' title={i18n.t('weekdays.friday')}></Tab>
								<Tab eventKey='SABADO' title={i18n.t('weekdays.saturday')}></Tab>
								<Tab eventKey='DOMINGO' title={i18n.t('weekdays.sunday')}></Tab>
							</Tabs>
						</div>
					</div>
				</div>
			</div>

			<p className='font-weight-bold'>{currentDate.charAt(0).toUpperCase() + currentDate.substr(1)}</p>

			<div className='row mb-3'>
				<div className='col-md-2'>
					<label className='font-weight-bold'>{i18n.t('drives.recommended')}</label>
					<p>{metadata.recomendados}</p>
				</div>
				<div className='col-md-2'>
					<label className='font-weight-bold' htmlFor='disponibles-select'>
						{i18n.t('drives.available')}
					</label>
					<select
						id='disponibles-select'
						className='form-control'
						name='disponibles'
						onChange={handleEmpleadosDisponibleChange}
						value={empleadosDisponibles}>
						{getEmpleadosDisponiblesOptions()}
					</select>
				</div>
				<div className='col-md-2'>
					<label className='font-weight-bold'>{i18n.t('drives.needed_hours')}</label>
					<p>{metadata.horasNecesarias}</p>
				</div>
				<div className='col-md-3'>
					<label className='font-weight-bold'>{i18n.t('drives.scheduled_hours')}</label>
					<p>{metadata.horasProgramadas}</p>
				</div>
				<div className='col-md-3 text-right'>
					<Button variant='primary' className='mr-2' onClick={handleDownloadReporteClick}>
						<FontAwesomeIcon icon={faFileExcel} size='lg' />
					</Button>
					{hasPermissionTo('UPDATE_GUIA_HORARIO') && (
						<Button variant='success' className='' onClick={handleGuardarCambiosClick}>
							<FontAwesomeIcon icon={faSave} size='lg' className='mr-2' />
							{i18n.t('form.save_button')}
						</Button>
					)}
				</div>
			</div>
			<div className='bg-light p-3' style={zoomActive ? { zoom: `${zoom}%` } : {}}>
				<Button variant={zoomActive ? 'primary' : 'secondary'} className='ml-1' onClick={() => setZoomActive(prev => !prev)}>
					<FontAwesomeIcon icon={faSearch} size='lg' />
				</Button>
				<Button variant='danger' className='ml-1' onClick={onRecalcularProyeccionesClick}>
					{i18n.t('drives.calculate_operators')}
				</Button>

				<div id='guia-container' className='tables-container'>
					<GuiaHorariosTabContext.Provider
						value={{
							tabDiasKey,
							horarioAperturaDrive,
							tablaProyecciones,
							setTablaProyecciones,
							tablaProyeccionesOriginal,
							totalesProyecciones,
							tablaHorario,
							setTablaHorario,
							tablaHorarioOriginal,
							totalesHorario,
							setTotalesHorario,
							horarioSemanal,
							horarioSemanalPasado,
							guiasHistorial,
							totalesHorasxSemanaHorario,
							empleadosDisponibles,
							empleados: getDisponibilidadEmpleadosDelDia(),
							empleadosOptions,
							setEmpleadosOptions,
							// UTILS
							isHoraInactiva,
							obtenerDiferenciaDeOperadores,
							obtenerDiferenciaEnTotales,
							calcularTotales,
							calcularProductividadxHora,
						}}>
						<TablaProyecciones />
						<TablaHorarios />
					</GuiaHorariosTabContext.Provider>
					<DescansosList
						empleadoOptions={descansoEmpleadoOptions}
						descansosAsignados={descansosAsignados}
						setDescansosAsignados={setDescansosAsignados}
						descansosSemana={descansosSemana}
					/>
				</div>
			</div>
		</>
	);
};

export default GuiaHorariosTab;
