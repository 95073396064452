import axios from 'axios';
import Swal from 'sweetalert2';
import { getToken } from './authentication.service';
import apiErrorHandler from './apiErrorHandler.service';
import i18n from '../utils/i18n';

async function settingToken() {
	// Obtener el token
	let token = getToken();
	return token;
}

// Se crea instancia http con valores default
const httpInstance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	// timeout: 4000,
});

// Interceptor para setear el token en el request
httpInstance.interceptors.request.use(async config => {
	try {
		const token = await settingToken();
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`;
		}
		return config;
	} catch (error) {
		return Promise.reject(error);
	}
});

// Interceptor para enviar datos recibidos y checar errores
httpInstance.interceptors.response.use(response => {
	if (response.headers['content-type'].indexOf('application/json') !== -1) {
		return response.data;
	}
	return response;
}, axiosErrorHandler);

async function axiosErrorHandler(error) {
	const getTranslations = async () => {
		return {
			title: i18n.t('system.connection_error_title'),
			text: i18n.t('system.connection_error_text'),
			unexpected_title: i18n.t('system.unexpected_error_title'),
			unexpected_text: i18n.t('system.unexpected_error_text'),
		}
	}
	const { title, text, unexpected_title, unexpected_text } = await getTranslations();
	if (error.response) {
		if (error.response.data instanceof Blob) {
			const jsonError = await convertBlobResponseToJSON(error);
			return Promise.reject(jsonError);
		}
		const expectedError = error.response.status >= 400 && error.response.status < 500;
		// Error de server
		if (!expectedError && process.env.NODE_ENV === 'production') {
			apiErrorHandler('Server', error);
			return Promise.reject(error.response);
		}
		// Error de cliente
		return Promise.reject(error.response.data);
	} else {
		error = error.toJSON();
		if (error.message === 'Network Error') {
			// Error de conexion
			if (!Swal.isVisible()) {
				Swal.fire(title, text, 'error');
			}
		} else {
			if (!Swal.isVisible()) {
				Swal.fire(unexpected_title, unexpected_text, 'error');
			}
		}
	}
	// Se reenvia el error al bloque catch donde se realizo la llamada
	return Promise.reject(error);
}
function convertBlobResponseToJSON(error) {
	return new Promise((resolve, reject) => {
		let reader = new FileReader();
		reader.onload = () => {
			error.response.data = JSON.parse(reader.result as any);
			resolve(error.response.data);
		};

		reader.onerror = () => {
			reject(error);
		};

		reader.readAsText(error.response.data);
	});
}

export default httpInstance;
