import React, { useState, useCallback, memo } from 'react';
import Select from 'react-virtualized-select';
import { Row, Col, Button, OverlayTrigger, Tooltip, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import isEqual from 'lodash/isEqual';
// Mis Componentes
import http from 'services/http.service';
// Mis Typings
import { Option } from 'typings';

import i18n from '../../../../utils/i18n';

const DESCANSO_OPTIONS = [
	{ value: 1, label: i18n.t('drives.break') },
	{ value: 2, label: i18n.t('drives.vacations') },
	{ value: 3, label: i18n.t('drives.paid_time_off') },
	{ value: 4, label: i18n.t('drives.time_off') },
	{ value: 5, label: i18n.t('drives.inability') },
];

export interface DescansosListProp {
	empleadoOptions: Option[];
	descansosAsignados: any[];
	setDescansosAsignados: React.Dispatch<React.SetStateAction<any[]>>;
	descansosSemana: any[];
}

const DescansosList: React.FC<DescansosListProp> = ({
	empleadoOptions,
	descansosAsignados,
	setDescansosAsignados,
	descansosSemana,
}) => {
	const [empleado, setEmpleado] = useState<any>({
		empleado_id: null,
		nombre: '',
	});
	const [tipoDescanso, setTipoDescanso] = useState<any>({
		tipoDescansoID: null,
		nombre: '',
	});

	const handleEmpleadoChange = (option: Option) => {
		if (option?.value)
			setEmpleado({
				empleado_id: option?.value as number,
				nombre: option?.label,
			});
	};

	const handleDescansoChange = (option: Option) => {
		if (option?.value)
			setTipoDescanso({
				tipoDescansoID: option?.value as number,
				nombre: option?.label,
			});
	};

	const handleAsignarDescansoClick = async () => {
		const getTranslations = async () => {
			return {
				attention: i18n.t('drives.swal_attention'),
				text: i18n.t('drives.swal_holiday_type'),
			}
		}

		const { attention, text } = await getTranslations();
		if (!empleado.empleado_id || !tipoDescanso.tipoDescansoID) {
			Swal.fire(attention, text, 'warning');
			return;
		}

		const descansoFound = descansosAsignados.find((descanso: any) => descanso.empleado_id === empleado.empleado_id);
		if (!descansoFound) {
			const newDescanso = {
				dia_guia_descanso_id: null,
				empleado_id: empleado.empleado_id,
				operador: empleado.nombre,
				descanso_id: tipoDescanso.tipoDescansoID,
				tipoDescanso: tipoDescanso.nombre,
			};
			setDescansosAsignados(descansosAsignados.concat(newDescanso));
		}
	};

	const handleEliminarDescansoClick = useCallback(
		async (descanso: any) => {
			try {
				if (descanso?.dia_guia_descanso_id) {
					const descansoFound = descansosAsignados.find(
						descansoAsignado => descansoAsignado.dia_guia_descanso_id === descanso.dia_guia_descanso_id,
					);
					if (descansoFound) {
						await http.delete(`guia/descansos/${descanso.dia_guia_descanso_id}`);
					}
				}
				const newDescansosAsignados = descansosAsignados.filter(
					descansoAsignado => descansoAsignado.empleado_id !== descanso.empleado_id,
				);
				setDescansosAsignados(newDescansosAsignados);
			} catch (error) {
				const getTranslations = async () => {
					return {
						text: i18n.t('drives.swal_error'),
					}
				}

				const { text } = await getTranslations();
				Swal.fire('Error', text, 'error');
				console.error(error);
			}
		},
		[descansosAsignados, setDescansosAsignados],
	);

	return (
		<>
			<div className='font-weight-bold mb-2'>{i18n.t('drives.holiday_title')}</div>
			<Row className='my-3'>
				<Col md='2'>
					<Select
						name='empleado'
						noResultsText={i18n.t('datatable.nodata')}
						placeholder={i18n.t('drives.select_operator')}
						options={empleadoOptions}
						clearable={false}
						searchable
						onChange={handleEmpleadoChange}
						value={empleado.empleado_id}
					/>
				</Col>
				<Col md='2'>
					<Select
						name='tipoDescanso'
						noResultsText={i18n.t('datatable.nodata')}
						placeholder={i18n.t('drives.select_holiday_type')}
						options={DESCANSO_OPTIONS}
						clearable={false}
						searchable={false}
						onChange={handleDescansoChange}
						value={tipoDescanso.tipoDescansoID}
					/>
				</Col>
				<Col md='2'>
					<Button variant='danger' className='px-5' onClick={handleAsignarDescansoClick}>
						{i18n.t('drives.assign_button')}
					</Button>
				</Col>
			</Row>

			<Row>
				<Col md='6'>
					<div className='bg-white'>
						<Table hover>
							<thead>
								<tr>
									<th className='text-center'>{i18n.t('drives.operator')}</th>
									<th className='text-center'>{i18n.t('drives.type')}</th>
									<th className='text-center'>{i18n.t('datatable.options')}</th>
								</tr>
							</thead>
							<tbody>
								{descansosAsignados.length === 0 && (
									<tr>
										<td className='text-center' colSpan={3}>
											{i18n.t('datatable.nodata')}
										</td>
									</tr>
								)}
								{descansosAsignados.map(descanso => (
									<tr key={descanso.empleado_id} className='text-center'>
										<td className='align-middle'>{descanso.operador}</td>
										<td className='align-middle'>{descanso.tipoDescanso}</td>
										<td className='align-middle'>
											<OverlayTrigger
												key={'tooltip-eliminar'}
												placement={'top'}
												overlay={<Tooltip id={`tooltip-filtros`}>{i18n.t('datatable.delete')}</Tooltip>}>
												<Button variant='danger' className='' onClick={() => handleEliminarDescansoClick(descanso)}>
													<FontAwesomeIcon icon={faTimes} size='lg' />
												</Button>
											</OverlayTrigger>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</div>
				</Col>
				<Col md='6'>
					<div className='bg-white'>
						<Table hover>
							<thead>
								<tr>
									<th className='text-center'>LUNES</th>
									<th className='text-center'>MARTES</th>
									<th className='text-center'>MIÉRCOLES</th>
									<th className='text-center'>JUEVES</th>
									<th className='text-center'>VIERNES</th>
									<th className='text-center'>SÁBADO</th>
									<th className='text-center'>DOMINGO</th>
								</tr>
							</thead>
							<tbody>
								{descansosSemana.length === 0 && (
									<tr>
										<td className='text-center' colSpan={7}>
											No se han agregado descansos en esta semana.
										</td>
									</tr>
								)}
								<tr className='text-center'>
									<td className='align-middle'>
										{descansosSemana
											.filter(descansosSemana => descansosSemana.dia_semana === 'LUNES')
											.map(descanso => (
												<React.Fragment>
													<span>{descanso.empleado_nombre}</span>
													<br />
													<br />
												</React.Fragment>
											))}
									</td>
									<td className='align-middle'>
										{descansosSemana
											.filter(descansosSemana => descansosSemana.dia_semana === 'MARTES')
											.map(descanso => (
												<React.Fragment>
													<span>{descanso.empleado_nombre}</span>
													<br />
													<br />
												</React.Fragment>
											))}
									</td>
									<td className='align-middle'>
										{descansosSemana
											.filter(descansosSemana => descansosSemana.dia_semana === 'MIERCOLES')
											.map(descanso => (
												<React.Fragment>
													<span>{descanso.empleado_nombre}</span>
													<br />
													<br />
												</React.Fragment>
											))}
									</td>
									<td className='align-middle'>
										{descansosSemana
											.filter(descansosSemana => descansosSemana.dia_semana === 'JUEVES')
											.map(descanso => (
												<React.Fragment>
													<span>{descanso.empleado_nombre}</span>
													<br />
													<br />
												</React.Fragment>
											))}
									</td>
									<td className='align-middle'>
										{descansosSemana
											.filter(descansosSemana => descansosSemana.dia_semana === 'VIERNES')
											.map(descanso => (
												<React.Fragment>
													<span>{descanso.empleado_nombre}</span>
													<br />
													<br />
												</React.Fragment>
											))}
									</td>
									<td className='align-middle'>
										{descansosSemana
											.filter(descansosSemana => descansosSemana.dia_semana === 'SABADO')
											.map(descanso => (
												<React.Fragment>
													<span>{descanso.empleado_nombre}</span>
													<br />
													<br />
												</React.Fragment>
											))}
									</td>
									<td className='align-middle'>
										{descansosSemana
											.filter(descansosSemana => descansosSemana.dia_semana === 'DOMINGO')
											.map(descanso => (
												<React.Fragment>
													<span>{descanso.empleado_nombre}</span>
													<br />
													<br />
												</React.Fragment>
											))}
									</td>
								</tr>
							</tbody>
						</Table>
					</div>
				</Col>
			</Row>
		</>
	);
};

export default memo(DescansosList, (prevProps, nextProps) => {
	if (!isEqual(prevProps.empleadoOptions, nextProps.empleadoOptions)) {
		return false;
	} else if (!isEqual(prevProps.descansosAsignados, nextProps.descansosAsignados)) {
		return false;
	}
	return true;
});
