import React from 'react';
import Select from 'react-virtualized-select';
import { Option } from 'typings';

export interface SelectInputProps {
   name: string;
   placeholder: string;
   options: Option[];
   clearable?: boolean;
   searchable?: boolean;
   isDisabled?: boolean;
   setFieldValue?: any;
   fetchData?: any;
   handleCustomSelect?: (option: any) => void;
   value: number | string | null;
}

const SelectInput: React.FC<SelectInputProps> = ({
   name,
   placeholder,
   options,
   clearable,
   searchable,
   isDisabled,
   setFieldValue,
   fetchData,
   handleCustomSelect,
   value,
}) => {
   const handleSelectChange = (option: Option) => {
      if (option) {
         setFieldValue(name, option.value);
         if (name === 'drive_id' && fetchData) {
            fetchData(option.value);
         }
      } else {
         setFieldValue(name, option);
      }
   };

   return (
      <Select
         name={name}
         placeholder={placeholder}
         options={options}
         noResultsText={'No se encontraron resultados'}
         clearable={clearable || false}
         searchable={searchable || false}
         disabled={isDisabled || false}
         onChange={handleCustomSelect ? handleCustomSelect(name) : handleSelectChange}
         {...(value !== null ? { value } : { value: null })}
      />
   );
};

export default SelectInput;
