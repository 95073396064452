import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import AuthRoutes from 'routes/auth.routes';
import { Card } from 'react-bootstrap';
import { getToken } from 'services/authentication.service';
import './AuthPage.css';
import logo from 'assets/images/logo_caffenio_vertical.png';
import { useTranslation } from 'react-i18next';

export interface AuthPageProps { }

const AuthPage: React.FC<AuthPageProps> = () => {
	const { t } = useTranslation();
	const [toDashboard, setToDashboard] = useState<boolean>(false);
	useEffect(() => {
		const token = getToken();
		if (token) {
			setToDashboard(true);
		}
	}, []);

	if (toDashboard) {
		return <Redirect to='/r/drives' />;
	}

	return (
		<section id='auth'>
			<Card className='card-login mx-auto my-auto p-0'>
				<Card.Body>
					<Card.Img className='img-fluid mx-auto mb-4' src={logo} />
					<h4 className='text-uppercase font-weight-bold text-center'>{t('auth.text')}</h4>
					<AuthRoutes />
				</Card.Body>
			</Card>
		</section>
	);
};

export default AuthPage;
