import i18n from './i18n';

export const CELL_COLORS = {
	recomendado: 'bg-green-recomendado',
	horaLibre: 'bg-white',
	horaPico: 'bg-green-horas-pico',
	manual: 'bg-green-manual',
	horaExtra: 'bg-green-hora-extra',
	disabled: 'bg-disabled',
};

export const DAYS = [i18n.t('weekdays.monday'), i18n.t('weekdays.tuesday'), i18n.t('weekdays.wednesday'), i18n.t('weekdays.thursday'), i18n.t('weekdays.friday'), i18n.t('weekdays.saturday'), i18n.t('weekdays.sunday')];

export const ROLES = {
	Admin: 'administrador',
	Supervisor: 'supervisor',
	Lider: 'lider',
};

export const CELL_STATUS = {
	Recomendado: 'Recomendado',
	HoraExtra: 'HoraExtra',
	HoraLibre: 'HoraLibre',
	Manual: 'Manual',
};

export const HORAS_JORNADA_LABORAL = 8;

export const SEMANA_DIAS = ['LUNES', 'MARTES', 'MIERCOLES', 'JUEVES', 'VIERNES', 'SABADO', 'DOMINGO'];

export const WEEKDAY_NAME = {
	0: 'DOMINGO',
	1: 'LUNES',
	2: 'MARTES',
	3: 'MIERCOLES',
	4: 'JUEVES',
	5: 'VIERNES',
	6: 'SABADO',
};

export const REPORTES = {
	HorarioEmpleados: 1,
	TablaPiso: 2,
	Productividad: 3,
	HorasTrabajadasEmpleado: 4,
};

export const PUESTO_OPTIONS = [
	{ value: 1, label: i18n.t('roles.cash_register') + ' 1' },
	{ value: 2, label: i18n.t('roles.cash_register') + ' 2' },
	{ value: 3, label: i18n.t('roles.bartender') + ' 1' },
	{ value: 4, label: i18n.t('roles.bartender') + ' 2' },
	{ value: 5, label: i18n.t('roles.bartender') + ' 3' },
	{ value: 6, label: i18n.t('roles.bartender') + ' 4' },
	{ value: 7, label: i18n.t('roles.tablet') },
	{ value: 8, label: i18n.t('roles.hallway') },
	{ value: 9, label: i18n.t('roles.screen') },
	{ value: 10, label: i18n.t('roles.preparer') },
];

export const PUESTOS = {
	Caja1: 1,
	Caja2: 2,
	Barista1: 3,
	Barista2: 4,
	Barista3: 5,
	Barista4: 6,
	'Tablet/Corredor': 7,
	Corredor: 8,
	Pantalla: 9,
	Preparador: 10,
};

export const HORAS = [
	'1pm',
	'2pm',
	'3pm',
	'4pm',
	'5am',
	'5pm',
	'6am',
	'6pm',
	'7am',
	'7pm',
	'8am',
	'8pm',
	'9am',
	'9pm',
	'10am',
	'10pm',
	'11am',
	'11pm',
	'12pm',
];

export const fakeEmpleado = {
	empleado_id: 1000,
	activo: true,
	apellidom: null,
	apellidop: null,
	apellidos: 'Empleado',
	caffenio_post_idEmpleado: null,
	correo: 'sinasignar@caffenio.com',
	disponibilidad: [
		{
			dia: 'LUNES',
			disponibilidad_id: 0,
			_1pm: false,
			_2pm: false,
			_3pm: false,
			_4pm: false,
			_5am: false,
			_5pm: false,
			_6am: false,
			_6pm: false,
			_7am: false,
			_7pm: false,
			_8am: false,
			_8pm: false,
			_9am: false,
			_9pm: false,
			_10am: false,
			_10pm: false,
			_11am: false,
			_11pm: false,
			_12pm: false,
		},
		{
			dia: 'MARTES',
			disponibilidad_id: 0,
			_1pm: false,
			_2pm: false,
			_3pm: false,
			_4pm: false,
			_5am: false,
			_5pm: false,
			_6am: false,
			_6pm: false,
			_7am: false,
			_7pm: false,
			_8am: false,
			_8pm: false,
			_9am: false,
			_9pm: false,
			_10am: false,
			_10pm: false,
			_11am: false,
			_11pm: false,
			_12pm: false,
		},
		{
			dia: 'MIERCOLES',
			disponibilidad_id: 0,
			_1pm: false,
			_2pm: false,
			_3pm: false,
			_4pm: false,
			_5am: false,
			_5pm: false,
			_6am: false,
			_6pm: false,
			_7am: false,
			_7pm: false,
			_8am: false,
			_8pm: false,
			_9am: false,
			_9pm: false,
			_10am: false,
			_10pm: false,
			_11am: false,
			_11pm: false,
			_12pm: false,
		},
		{
			dia: 'JUEVES',
			disponibilidad_id: 0,
			_1pm: false,
			_2pm: false,
			_3pm: false,
			_4pm: false,
			_5am: false,
			_5pm: false,
			_6am: false,
			_6pm: false,
			_7am: false,
			_7pm: false,
			_8am: false,
			_8pm: false,
			_9am: false,
			_9pm: false,
			_10am: false,
			_10pm: false,
			_11am: false,
			_11pm: false,
			_12pm: false,
		},
		{
			dia: 'VIERNES',
			disponibilidad_id: 0,
			_1pm: false,
			_2pm: false,
			_3pm: false,
			_4pm: false,
			_5am: false,
			_5pm: false,
			_6am: false,
			_6pm: false,
			_7am: false,
			_7pm: false,
			_8am: false,
			_8pm: false,
			_9am: false,
			_9pm: false,
			_10am: false,
			_10pm: false,
			_11am: false,
			_11pm: false,
			_12pm: false,
		},
		{
			dia: 'SABADO',
			disponibilidad_id: 0,
			_1pm: false,
			_2pm: false,
			_3pm: false,
			_4pm: false,
			_5am: false,
			_5pm: false,
			_6am: false,
			_6pm: false,
			_7am: false,
			_7pm: false,
			_8am: false,
			_8pm: false,
			_9am: false,
			_9pm: false,
			_10am: false,
			_10pm: false,
			_11am: false,
			_11pm: false,
			_12pm: false,
		},
		{
			dia: 'DOMINGO',
			disponibilidad_id: 0,
			_1pm: false,
			_2pm: false,
			_3pm: false,
			_4pm: false,
			_5am: false,
			_5pm: false,
			_6am: false,
			_6pm: false,
			_7am: false,
			_7pm: false,
			_8am: false,
			_8pm: false,
			_9am: false,
			_9pm: false,
			_10am: false,
			_10pm: false,
			_11am: false,
			_11pm: false,
			_12pm: false,
		},
	],
	drive: null,
	drive_id: 0,
	fechaactualizacion: null,
	idempintelexion: null,
	nombre: 'Sin asignar',
	usuario: null,
};
