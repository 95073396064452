import React, { useCallback, useContext, useState } from 'react';
import Select from 'react-virtualized-select';
import { Form } from 'react-bootstrap';
import cloneDeep from 'lodash/cloneDeep';
import Swal from 'sweetalert2';
// Mis Componentes
import HoraCell from './HoraCell';
import GuiaHorariosTabContext from '../GuiaHorariosTabContext';
// Mis Typings
import { Option } from 'typings';
import { PUESTO_OPTIONS, HORAS } from 'utils/constants';

type EmpleadoOptionValue = number | null;
export interface HorarioEmpleadoRowProp {
	rowIndex: number;
}

const HorarioEmpleadoRow: React.FC<HorarioEmpleadoRowProp> = ({ rowIndex }) => {
	const {
		tabDiasKey,
		tablaProyeccionesOriginal,
		tablaHorario,
		tablaProyecciones,
		setTablaHorario,
		setTablaProyecciones,
		totalesHorario,
		horarioSemanal,
		guiasHistorial,
		totalesHorasxSemanaHorario,
		empleados,
		empleadosOptions,
		setEmpleadosOptions,
		// UTILS
		obtenerDiferenciaDeOperadores,
		obtenerDiferenciaEnTotales,
		calcularTotales,
		calcularProductividadxHora,
	} = useContext(GuiaHorariosTabContext);

	const [checkBoxChecked, setCheckBoxChecked] = useState(false);

	const handleCheckbox = () => {
		let newTablaHorario = cloneDeep(tablaHorario);
		HORAS.forEach(hora => {
			newTablaHorario[rowIndex][hora].value = false;
		});
		setTablaHorario(newTablaHorario);
		setCheckBoxChecked(!checkBoxChecked);

		let newTablaProyecciones = cloneDeep(tablaProyecciones);
		HORAS.forEach(hora => {
			let empleadosxHora = 0;
			Object.keys(newTablaHorario).forEach(key => {
				if (newTablaHorario[key][hora].value) {
					empleadosxHora++;
				}
			});

			let oldValue: number = tablaProyeccionesOriginal[`operadores_${hora}`];
			let operadoresDifference = obtenerDiferenciaDeOperadores(oldValue.toString(), empleadosxHora.toString());
			let totalesDifference = obtenerDiferenciaEnTotales(oldValue, empleadosxHora);
			calcularTotales(rowIndex.toString(), totalesDifference);
			let newProductividad = calcularProductividadxHora(rowIndex.toString(), hora, empleadosxHora);

			newTablaProyecciones[`operadores_${hora}`] = empleadosxHora;
			newTablaProyecciones[`operadores_diff_${hora}`] = operadoresDifference;
			newTablaProyecciones[`productividad_${hora}`] = newProductividad;
		});
		setTablaProyecciones(newTablaProyecciones);
	};

	const handleEmpleadoChange = (inputName, option: Option) => {
		/*if (option && hasDiesDiasSinDescanso(option?.value as number)) {
			Swal.fire('Atención!', 'El empleado seleccionado tendrá 10 dias consecutivos sin descansar.', 'warning');
		}
		if (option && !hasDayOff(option?.value as number)) {
			Swal.fire('Atención!', 'El empleado seleccionado no tiene día de descanso en semana.', 'warning');
		}*/

		const row = inputName.split('-')[1];
		filterEmpleadosOptions(Number(row), option?.value as EmpleadoOptionValue);

		const empleadoFound = empleados.find((empleado: any) => empleado.empleado_id === option?.value);
		const tablaHorarioData = cloneDeep(tablaHorario);
		tablaHorarioData[row].empleado_id = option?.value || null;
		tablaHorarioData[row].disponibilidad = empleadoFound?.disponibilidad || [];
		setTablaHorario({
			...tablaHorario,
			[row]: tablaHorarioData[row],
		});
	};
	const hasDiesDiasSinDescanso = useCallback(
		(empleado_id: number) => {
			let guiasHistorialClone: any[] = cloneDeep(guiasHistorial?.historial);
			let diaIndexConGuiaID: number | null = null;
			let diaIndexNoGuiaID: number | null = null;
			if (guiasHistorial.guia_id_actual) {
				diaIndexConGuiaID = guiasHistorialClone.findIndex(
					diaGuiaHorario =>
						diaGuiaHorario.guia_id === guiasHistorial.guia_id_actual && diaGuiaHorario.dia_semana === tabDiasKey,
				);
			} else {
				diaIndexNoGuiaID = guiasHistorialClone.findIndex(diaGuiaHorario => diaGuiaHorario.dia_semana === 'DOMINGO');
			}
			if (diaIndexConGuiaID && diaIndexConGuiaID > -1) {
				// Remover dias despues del actual
				guiasHistorialClone = guiasHistorialClone.slice(diaIndexConGuiaID);
				// Seleccionar los ultimos 9 dias
				guiasHistorialClone = guiasHistorialClone.slice(1, 10);
			} else {
				// Remover dias despues del actual
				guiasHistorialClone = guiasHistorialClone.slice(diaIndexNoGuiaID!);
				// Seleccionar los ultimos 9 dias
				guiasHistorialClone = guiasHistorialClone.slice(0, 10);
			}
			return (
				guiasHistorialClone.length >= 9 &&
				guiasHistorialClone.every(diaGuiaHorario => {
					const empleadoFound = diaGuiaHorario.empleados_dia_horario.find(empleado => empleado.empleado_id === empleado_id);
					if (empleadoFound) {
						return true;
					}
					return false;
				})
			);
		},
		[guiasHistorial, tabDiasKey],
	);
	const filterEmpleadosOptions = useCallback(
		(row: number, empleado_id: EmpleadoOptionValue) => {
			const empleadosOptionsClone: any[][] = cloneDeep(empleadosOptions.lists);
			const selectedEmpleadoOptions: any[] = cloneDeep(empleadosOptionsClone[row]);

			const newEmpleadosOptions: any[] = [];
			let newSelectedOptions: any[] = [];
			if (empleado_id) {
				const selectedOptionFound = empleadosOptionsClone[row].find(option => option.value === empleado_id);
				const previousEmployeeId = tablaHorario[row].empleado_id;
				if (previousEmployeeId && previousEmployeeId !== empleado_id) {
					const previousSelectedOptionFound = empleadosOptionsClone[row].find(option => option.value === previousEmployeeId);
					newSelectedOptions = empleadosOptions.selected.filter(
						selectedOption => selectedOption.value !== previousEmployeeId,
					);
					empleadosOptionsClone[row] = [];
					empleadosOptionsClone.forEach(empleadoOptions => {
						newEmpleadosOptions.push(empleadoOptions.filter(empleadoOption => empleadoOption?.value !== empleado_id));
					});
					newEmpleadosOptions.forEach((empleadoOptions, index) => {
						if (index !== row) {
							newEmpleadosOptions[index] = empleadoOptions.concat(previousSelectedOptionFound);
						}
					});
					newSelectedOptions = newSelectedOptions.concat(selectedOptionFound);
				} else {
					empleadosOptionsClone[row] = [];
					empleadosOptionsClone.forEach(empleadoOptions => {
						newEmpleadosOptions.push(empleadoOptions.filter(empleadoOption => empleadoOption?.value !== empleado_id));
					});
					newSelectedOptions = empleadosOptions.selected.concat(selectedOptionFound);
				}

				newEmpleadosOptions[row] = selectedEmpleadoOptions;
			} else {
				const selectedOptionFound = empleadosOptions.selected.find(option => option.value === tablaHorario[row].empleado_id);
				empleadosOptionsClone[row] = [];
				empleadosOptionsClone.forEach(empleadoOptions => {
					newEmpleadosOptions.push(empleadoOptions.concat(selectedOptionFound));
				});
				newEmpleadosOptions[row] = selectedEmpleadoOptions;
				newSelectedOptions = empleadosOptions.selected.filter(
					selectedOption => selectedOption.value !== tablaHorario[row].empleado_id,
				);
			}

			setEmpleadosOptions({
				lists: newEmpleadosOptions,
				selected: newSelectedOptions,
			});
		},
		[empleadosOptions, setEmpleadosOptions, tablaHorario],
	);
	const hasDayOff = (empleado_id: number) => {
		let diasTrabajados: number = 0;
		horarioSemanal.forEach(dia => {
			if (dia.empleados_asignados.length > 0) {
				const hasBeenAssigned = dia.empleados_asignados.some(empleado => empleado.empleado_id === empleado_id);
				if (hasBeenAssigned) {
					diasTrabajados++;
				}
			}
		});
		if (diasTrabajados >= 6) {
			return false;
		}
		return true;
	};

	const handlePuestoChange = (inputName, option: Option) => {
		const row = inputName.split('-')[1];
		const tablaHorarioData = cloneDeep(tablaHorario);

		// Actualizar el valor
		tablaHorarioData[row].rol_id = option?.value || null;
		setTablaHorario({
			...tablaHorario,
			[row]: tablaHorarioData[row],
		});
	};

	const handleActividadChange = ({ target: input }: React.ChangeEvent<HTMLInputElement>) => {
		const row = input.name.split('-')[1];
		const tablaHorarioData = cloneDeep(tablaHorario);

		// Actualizar el valor
		tablaHorarioData[row].actividad_secundaria = input.value || '';
		setTablaHorario({
			...tablaHorario,
			[row]: tablaHorarioData[row],
		});
	};

	const getHorasColumns = (rowNumber: number, checkBoxChecked: boolean): JSX.Element[] => {
		let horaEmpleado: JSX.Element[] = [];
		let totalHorasxDia = 0;
		const horas: string[] = Object.keys(tablaProyeccionesOriginal)
			.filter(keyName => keyName.startsWith('operadores') && !keyName.includes('diff'))
			.map(horaOperadorKey => horaOperadorKey.split('_')[1]);
		horas.forEach((hora, columnNumber) => {
			// Checar si el renglon tiene al menos 8 horas acumuladas y si la columna tiene al menos la cantidad de operadores calculados
			if (tablaHorario[rowNumber][hora]?.value) totalHorasxDia += 1;
			horaEmpleado.push(
				<HoraCell
					key={`${rowNumber}-${columnNumber}`}
					rowNumber={rowNumber}
					hora={hora}
					totalHorasxDia={totalHorasxDia}
					checkBoxChecked={checkBoxChecked}
				/>,
			);
		});
		return horaEmpleado;
	};

	return (
		<tr>
			{/*<td>
            <div className=''>
               <input onChange={handleCheckbox} type='checkbox' name='' id='' />
            </div>
         </td>*/}
			<td className='text-left'>
				<div className='nombre-empleado bg-white h-100'>
					<Select
						key={`${rowIndex}-empleado`}
						name={`empleado_id-${rowIndex}`}
						noResultsText={'No se encontraron resultados'}
						placeholder='Seleccionar operador'
						options={empleadosOptions.lists[rowIndex]}
						searchable
						clearable
						onChange={(option: Option) => handleEmpleadoChange(`empleado_id-${rowIndex}`, option)}
						value={tablaHorario[rowIndex]?.empleado_id || null}
					/>
				</div>
			</td>
			<td className='text-left'>
				<div className='bg-white h-100'>
					<Select
						key={`${rowIndex}-puesto`}
						name={`rol_empleado_id-${rowIndex}`}
						noResultsText={'No se encontraron resultados'}
						placeholder='Puesto'
						options={PUESTO_OPTIONS}
						searchable
						clearable
						onChange={(option: Option) => handlePuestoChange(`rol_empleado_id-${rowIndex}`, option)}
						value={tablaHorario[rowIndex]?.rol_id || null}
					/>
				</div>
			</td>
			<td>
				<Form.Control
					key={`${rowIndex}-actividad`}
					id='actividad-secundaria'
					name={`actividad_secundaria_empleado_id-${rowIndex}`}
					className='text-left'
					onChange={handleActividadChange}
					type='text'
					value={tablaHorario[rowIndex]?.actividad_secundaria || ''}
				/>
			</td>
			{getHorasColumns(rowIndex, checkBoxChecked)}
			<td>
				<Form.Control
					key={`${rowIndex}-19`}
					name={`totalHoras_row-${rowIndex}`}
					type='text'
					value={totalesHorario[`row-${rowIndex}`]}
					disabled
				/>
			</td>
			<td>
				<Form.Control
					key={`${rowIndex}-20`}
					name={`totalHorasSemanales_row-${rowIndex}`}
					type='text'
					value={totalesHorasxSemanaHorario[`row-${rowIndex}`] ? totalesHorasxSemanaHorario[`row-${rowIndex}`] : '0'}
					disabled
				/>
			</td>
		</tr>
	);
};

export default HorarioEmpleadoRow;
