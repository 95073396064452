import React, { useContext } from 'react';
import Select from 'react-virtualized-select';
import { Form } from 'react-bootstrap';
// Mis Componentes
import HoraCell from './HoraCell';
import GuiaHorariosTabContext from '../GuiaHorariosTabContext';
// Mis Typings
import { PUESTO_OPTIONS } from 'utils/constants';

export interface HorarioEmpleadoRowProp {
   rowIndex: number;
}

const HorarioEmpleadoRow: React.FC<HorarioEmpleadoRowProp> = ({ rowIndex }) => {
   const { tablaProyeccionesOriginal, tablaHorario, totalesHorario, totalesHorasxSemanaHorario, empleadosOptions } = useContext(
      GuiaHorariosTabContext,
   );

   const getHorasColumns = (rowNumber: number): JSX.Element[] => {
      let horaEmpleado: JSX.Element[] = [];
      let totalHorasxDia = 0;
      const horas: string[] = Object.keys(tablaProyeccionesOriginal)
         .filter(keyName => keyName.startsWith('operadores') && !keyName.includes('diff'))
         .map(horaOperadorKey => horaOperadorKey.split('_')[1]);
      horas.forEach((hora, columnNumber) => {
         // Checar si el renglon tiene al menos 8 horas acumuladas y si la columna tiene al menos la cantidad de operadores calculados
         if (tablaHorario[rowNumber][hora]?.value) totalHorasxDia += 1;
         horaEmpleado.push(
            <HoraCell key={`${rowNumber}-${columnNumber}`} rowNumber={rowNumber} hora={hora} totalHorasxDia={totalHorasxDia} />,
         );
      });
      return horaEmpleado;
   };

   return (
      <tr>
         <td className='text-left'>
            <div className='nombre-empleado bg-white h-100'>
               <Select
                  key={`${rowIndex}-empleado`}
                  name={`empleado_id-${rowIndex}`}
                  noResultsText={'No se encontraron resultados'}
                  placeholder='Seleccionar operador'
                  options={empleadosOptions.lists[rowIndex]}
                  disabled
                  value={tablaHorario[rowIndex]?.empleado_id || null}
               />
            </div>
         </td>
         <td className='text-left'>
            <div className='bg-white h-100'>
               <Select
                  key={`${rowIndex}-puesto`}
                  name={`rol_empleado_id-${rowIndex}`}
                  noResultsText={'No se encontraron resultados'}
                  placeholder='Puesto'
                  options={PUESTO_OPTIONS}
                  disabled
                  value={tablaHorario[rowIndex]?.rol_id || null}
               />
            </div>
         </td>
         <td>
            <Form.Control
               key={`${rowIndex}-actividad`}
               id='actividad-secundaria'
               name={`actividad_secundaria_empleado_id-${rowIndex}`}
               className='text-left'
               type='text'
               disabled={true}
               value={tablaHorario[rowIndex]?.actividad_secundaria || ''}
            />
         </td>
         {getHorasColumns(rowIndex)}
         <td>
            <Form.Control
               key={`${rowIndex}-19`}
               name={`totalHoras_row-${rowIndex}`}
               type='text'
               disabled
               value={totalesHorario[`row-${rowIndex}`]}
            />
         </td>
         <td>
            <Form.Control
               key={`${rowIndex}-20`}
               name={`totalHorasSemanales_row-${rowIndex}`}
               type='text'
               disabled
               value={totalesHorasxSemanaHorario[`row-${rowIndex}`] ? totalesHorasxSemanaHorario[`row-${rowIndex}`] : '0'}
            />
         </td>
      </tr>
   );
};

export default HorarioEmpleadoRow;
