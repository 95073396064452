import React, { useState } from 'react';
import { Redirect /* Link */ } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
import { Alert as RBAlert, InputGroup, FormControl, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faKey } from '@fortawesome/free-solid-svg-icons';
import ErrorFeedback from 'components/shared/ErrorFeedback';
import { updateAbilities } from 'services/authorization.service';
import { storeToken, fetchUserInfo } from 'services/authentication.service';
import http from 'services/http.service';
import { Alert } from 'typings';

import { useTranslation } from 'react-i18next';

const initialValues = {
	usuario: '',
	password: '',
};

const Login: React.FC = () => {
	const { t } = useTranslation();
	const [toDashboard, setToDashboard] = useState<boolean>(false);
	const [genericAlert, setGenericAlert] = useState<Alert>({
		isOpen: false,
		variant: undefined,
		message: '',
	});

	const loginSchema = Yup.object().shape({
		usuario: Yup.string().min(6, t('login.minchar')).required(t('login.required')),
		password: Yup.string().min(6, t('login.minchar')).max(50, t('login.maxchar')).required(t('login.required')),
	});

	const handleFormSubmit = async (values, actions) => {
		try {
			const body = {
				usuario: values.usuario,
				password: values.password,
			};
			const resp: any = await http.post('auth', body);

			storeToken(resp.access_token);
			await fetchUserInfo();
			updateAbilities();

			// Redirect
			setToDashboard(true);
		} catch (error) {
			actions.setSubmitting(false);
			if (error.type === 'Auth/BadEmailOrPassword' || error.type === 'CaffenioAuth') {
				setGenericAlert({
					isOpen: true,
					variant: 'danger',
					message: error.message,
				});
			}
		}
	};

	if (toDashboard) {
		return <Redirect to='/r/drives' />;
	}

	return (
		<>
			{genericAlert.isOpen ? (
				<RBAlert className='text-left' variant={genericAlert.variant}>
					{genericAlert.message}
				</RBAlert>
			) : null}
			<Formik initialValues={initialValues} validationSchema={loginSchema} onSubmit={handleFormSubmit}>
				{({ isSubmitting }: FormikProps<any>) => (
					<Form className='login-form'>
						<div className='mb-3'>
							<Field name='usuario'>
								{({ field }) => (
									<InputGroup>
										<InputGroup.Prepend>
											<InputGroup.Text id='usuario'>
												<FontAwesomeIcon icon={faUserCircle} />
											</InputGroup.Text>
										</InputGroup.Prepend>
										<FormControl
											{...field}
											type='text'
											placeholder={t('login.username')}
											aria-label='usuario'
											aria-describedby='usuario'
										/>
									</InputGroup>
								)}
							</Field>
							<ErrorMessage name='usuario' component={ErrorFeedback} />
						</div>

						<div className='mb-3'>
							<Field name='password'>
								{({ field }) => (
									<InputGroup className=''>
										<InputGroup.Prepend>
											<InputGroup.Text id='password'>
												<FontAwesomeIcon icon={faKey} />
											</InputGroup.Text>
										</InputGroup.Prepend>
										<FormControl
											{...field}
											type='password'
											placeholder={t('login.password')}
											aria-label='password'
											aria-describedby='password'
										/>
									</InputGroup>
								)}
							</Field>
							<ErrorMessage name='password' component={ErrorFeedback} />
						</div>

						<Button variant='danger' type='submit' block disabled={isSubmitting}>
							{t('login.button')}
						</Button>

						{/* <hr className='my-3' />

                  <Link to='/login/recuperar-password' className='text-danger'>
                     Recuperar contraseña
                  </Link> */}
					</Form>
				)}
			</Formik>
		</>
	);
};

export default Login;
