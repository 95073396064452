import React, { memo } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import isEqual from 'lodash/isEqual';
// Mis Componentes
// Mis Typings
import { Option } from 'typings';

import i18n from '../../../utils/i18n';

export interface DescansosListProp {
	empleadoOptions: Option[];
	descansosAsignados: any[];
	setDescansosAsignados: React.Dispatch<React.SetStateAction<any[]>>;
}

const DescansosList: React.FC<DescansosListProp> = ({ descansosAsignados }) => {
	return (
		<>
			<div className='font-weight-bold mb-2'>{i18n.t('drives.holiday_title')}</div>

			<Row>
				<Col md='6'>
					<div className='bg-white'>
						<Table hover>
							<thead>
								<tr>
									<th className='text-center'>{i18n.t('drives.operator')}</th>
									<th className='text-center'>{i18n.t('drives.type')}</th>
								</tr>
							</thead>
							<tbody>
								{descansosAsignados.length === 0 && (
									<tr>
										<td className='text-center' colSpan={3}>
											{i18n.t('drives.no_holidays')}
										</td>
									</tr>
								)}
								{descansosAsignados.map(descanso => (
									<tr key={descanso.empleado_id} className='text-center'>
										<td className='align-middle'>{descanso.operador}</td>
										<td className='align-middle'>{descanso.tipoDescanso}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</div>
				</Col>
			</Row>
		</>
	);
};

export default memo(DescansosList, (prevProps, nextProps) => {
	if (!isEqual(prevProps.empleadoOptions, nextProps.empleadoOptions)) {
		return false;
	} else if (!isEqual(prevProps.descansosAsignados, nextProps.descansosAsignados)) {
		return false;
	}
	return true;
});
