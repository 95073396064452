import { createContext } from 'react';

interface GuiaHorarioTabContext {
   tablaProyecciones: any;
   tablaProyeccionesOriginal: any;
   totalesProyecciones: any;
   tablaHorario: any;
   tablaHorarioOriginal: any;
   totalesHorario: any;
   totalesHorasxSemanaHorario: any;
   empleadosDisponibles: number;
   empleadosOptions: {
      lists: any[][];
      selected: any[];
   };
   // UTILS
   isHoraInactiva: (hora: string) => boolean;
}
const GuiaHorariosTabContext = createContext<GuiaHorarioTabContext>(null!);

export default GuiaHorariosTabContext;
