import React, { memo, useCallback, useContext } from 'react';
import { Table, Form } from 'react-bootstrap';
// Mis Componentes
import GuiaHorariosTabContext from './GuiaHorariosTabContext';

import { useTranslation } from 'react-i18next';

export interface TablaProyeccionesProps { }

const TablaProyecciones: React.FC<TablaProyeccionesProps> = () => {
	const {
		horarioAperturaDrive,
		tablaProyeccionesOriginal,
		tablaProyecciones,
		setTablaProyecciones,
		totalesProyecciones,
		// UTILS
		obtenerDiferenciaDeOperadores,
		obtenerDiferenciaEnTotales,
		calcularTotales,
		calcularProductividadxHora,
	} = useContext(GuiaHorariosTabContext);

	const { t } = useTranslation();

	const handleInputChange = useCallback(
		({ target: input }: React.ChangeEvent<HTMLInputElement>) => {
			// Validar solo numeros
			const value = isNaN(parseInt(input.value)) ? 0 : parseInt(input.value);

			let rowName = input.name.split('_')[0];
			let columnName = input.name.split('_')[1];
			let oldValue: number = tablaProyecciones[input.name];
			let operadoresDifference = obtenerDiferenciaDeOperadores(
				tablaProyeccionesOriginal[input.name].toString(),
				value.toString(),
			);
			let totalesDifference = obtenerDiferenciaEnTotales(oldValue, value);
			calcularTotales(rowName, totalesDifference);
			let newProductividad = calcularProductividadxHora(rowName, columnName, value);

			setTablaProyecciones({
				...tablaProyecciones,
				[input.name]: value,
				[`productividad_${columnName}`]: newProductividad,
				...(rowName === 'operadores' ? { [`operadores_diff_${columnName}`]: operadoresDifference } : {}),
			});
		},
		[
			tablaProyecciones,
			setTablaProyecciones,
			tablaProyeccionesOriginal,
			obtenerDiferenciaDeOperadores,
			obtenerDiferenciaEnTotales,
			calcularProductividadxHora,
			calcularTotales,
		],
	);

	return (
		<Table className='tabla-proyecciones' borderless size='sm'>
			<thead className='text-center'>
				<tr>
					<th></th>
					<th></th>
					<th></th>
					<th>5am</th>
					<th>6am</th>
					<th>7am</th>
					<th>8am</th>
					<th>9am</th>
					<th>10am</th>
					<th>11am</th>
					<th>12pm</th>
					<th>1pm</th>
					<th>2pm</th>
					<th>3pm</th>
					<th>4pm</th>
					<th>5pm</th>
					<th>6pm</th>
					<th>7pm</th>
					<th>8pm</th>
					<th>9pm</th>
					<th>10pm</th>
					<th>11pm</th>
					<th>Total</th>
					<th></th>
				</tr>
			</thead>
			<tbody className='text-center'>
				{/* Tickets proyectados */}
				<tr>
					<td></td>
					<td></td>
					<td className='font-weight-bold text-right'>
						<div className='nombre-tr bg-white p-2 ml-auto'>{t('drives.projected_tickets')}</div>
					</td>
					{Object.keys(tablaProyecciones)
						.filter(key => /tickets/.test(key))
						.map(key => (
							<td key={key}>
								<Form.Control
									name={key}
									type='text'
									onChange={handleInputChange}
									isValid={tablaProyeccionesOriginal[key] !== tablaProyecciones[key]}
									disabled={horarioAperturaDrive && !horarioAperturaDrive[`_${key.split('_')[1]}`]}
									value={tablaProyecciones[key]}
								/>
							</td>
						))}
					<td>
						<Form.Control
							name='totalTickets'
							type='text'
							className='font-weight-bold'
							disabled
							value={totalesProyecciones.totalTickets}
						/>
					</td>
				</tr>
				{/* Operadores */}
				<tr>
					<td></td>
					<td></td>
					<td className='font-weight-bold text-right'>
						<div className='nombre-tr bg-white p-2 ml-auto'>{t('drives.operators')}</div>
					</td>
					{Object.keys(tablaProyecciones)
						.filter(key => /(?!operadores_diff)operadores/.test(key))
						.map(key => (
							<td key={key}>
								<Form.Control
									name={key}
									type='text'
									onChange={handleInputChange}
									isValid={tablaProyeccionesOriginal[key] !== tablaProyecciones[key]}
									disabled={horarioAperturaDrive && !horarioAperturaDrive[`_${key.split('_')[1]}`]}
									value={tablaProyecciones[key]}
								/>
							</td>
						))}
					<td>
						<Form.Control
							name='totalOperadores'
							type='text'
							className='font-weight-bold'
							disabled
							value={totalesProyecciones.totalOperadores}
						/>
					</td>
				</tr>
				{/* Diferencia de operadores */}
				<tr>
					<td></td>
					<td></td>
					<td className='font-weight-bold text-right'>
						<div className='nombre-tr bg-white p-2 ml-auto'>+/-</div>
					</td>
					{Object.keys(tablaProyecciones)
						.filter(key => /operadores_diff(?!operadores)/.test(key))
						.map(key => (
							<td key={key}>
								<Form.Control name={key} type='text' disabled value={tablaProyecciones[key]} />
							</td>
						))}
					<td></td>
				</tr>
				{/* Productividad */}
				<tr>
					<td></td>
					<td></td>
					<td className='font-weight-bold text-right'>
						<div className='nombre-tr bg-white p-2 ml-auto'>{t('drives.productivity')}</div>
					</td>
					{Object.keys(tablaProyecciones)
						.filter(key => /productividad/.test(key))
						.map(key => (
							<td key={key}>
								<Form.Control name={key} type='text' disabled value={tablaProyecciones[key]} />
							</td>
						))}
					<td>
						<Form.Control
							name='totalProductividad'
							type='text'
							className='font-weight-bold'
							disabled
							value={totalesProyecciones.totalProductividad}
						/>
					</td>
				</tr>
			</tbody>
		</Table>
	);
};

export default TablaProyecciones;
