import React from 'react';
import { Form, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export interface EmpleadoCapacitacionesProps {
	capacitaciones: any[];
	setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const EmpleadoCapacitaciones: React.FC<EmpleadoCapacitacionesProps> = ({ capacitaciones, setFieldValue }) => {
	const { t } = useTranslation();

	const handleNivelChange = (rolID: number, value: number) => {
		const newCapacitaciones = capacitaciones.map(capacitacion => {
			if (capacitacion.rol_id === rolID) {
				return {
					...capacitacion,
					nivel: value,
				};
			}
			return capacitacion;
		});
		setFieldValue('capacitaciones', newCapacitaciones);
	};

	return (
		<>
			<p className='font-weight-bold text-center'>{t('employees.trainings')}</p>
			<div className='row mb-4 justify-content-center'>
				<div className='col-md-10'>
					<Table className='tabla-capacitacion-empleado' responsive size='sm'>
						<thead className='text-center'>
							<tr>
								<th>{t('employees.role')}</th>
								<th>{t('employees.level')} 1</th>
								<th>{t('employees.level')} 2</th>
								<th>{t('employees.level')} 3</th>
								<th>{t('employees.level')} 4</th>
								<th>{t('employees.level')} 5</th>
							</tr>
						</thead>
						<tbody className='text-center'>
							{capacitaciones?.map(capacitacion => (
								<tr key={capacitacion.rol_id}>
									<td>{capacitacion.rol}</td>
									<td>
										<Form.Check
											type='radio'
											id='nivel-1'
											// id={`${capacitacion.rol}-nivel-1`}
											name={`rol-${capacitacion.rol}-nivel`}
											onChange={({ currentTarget: input }) =>
												handleNivelChange(capacitacion.rol_id, Number(input.value))
											}
											checked={capacitacion.nivel === 1}
											value={1}
										/>
									</td>
									<td>
										<Form.Check
											type='radio'
											id='nivel-2'
											// id={`${capacitacion.rol}-nivel-2`}
											name={`rol-${capacitacion.rol}-nivel`}
											onChange={({ currentTarget: input }) =>
												handleNivelChange(capacitacion.rol_id, Number(input.value))
											}
											checked={capacitacion.nivel === 2}
											value={2}
										/>
									</td>
									<td>
										<Form.Check
											type='radio'
											id='nivel-3'
											// id={`${capacitacion.rol}-nivel-3`}
											name={`rol-${capacitacion.rol}-nivel`}
											onChange={({ currentTarget: input }) =>
												handleNivelChange(capacitacion.rol_id, Number(input.value))
											}
											checked={capacitacion.nivel === 3}
											value={3}
										/>
									</td>
									<td>
										<Form.Check
											type='radio'
											id='nivel-4'
											// id={`${capacitacion.rol}-nivel-4`}
											name={`rol-${capacitacion.rol}-nivel`}
											onChange={({ currentTarget: input }) =>
												handleNivelChange(capacitacion.rol_id, Number(input.value))
											}
											checked={capacitacion.nivel === 4}
											value={4}
										/>
									</td>
									<td>
										<Form.Check
											type='radio'
											id='nivel-5'
											// id={`${capacitacion.rol}-nivel-5`}
											name={`rol-${capacitacion.rol}-nivel`}
											onChange={({ currentTarget: input }) =>
												handleNivelChange(capacitacion.rol_id, Number(input.value))
											}
											checked={capacitacion.nivel === 5}
											value={5}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>
			</div>
		</>
	);
};

export default EmpleadoCapacitaciones;
