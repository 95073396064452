import React, { useState, useEffect } from 'react';
// Components
import DashboardRoutes from 'routes/dashboard.routes';
import { getCurrentUser } from 'services/authentication.service';
import TopNavbar from './navbar/Navbar';
import Sidebar from './sidebar/Sidebar';
import './Dashboard.css';

export interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
   const [toggled, setToggled] = useState<boolean>(true);
   const [user, setUser] = useState<object>({});

   useEffect(() => {
      const user = getCurrentUser();
      setUser(user);

      const mq = window.matchMedia('(min-width: 1200px)');
      if (mq.matches) {
         setToggled(false);
      }
   }, []);

   const toggleSidebar = () => setToggled(toggled => !toggled);

   return (
      <div className='panel-admin'>
         <div className='panel-body'>
            <div className='left'>
               <Sidebar toggled={toggled} onToggleClick={toggleSidebar} />
            </div>
            <main className='right'>
               <TopNavbar user={user} />
               <div className='container-fluid pages-container'>
                  <DashboardRoutes />
               </div>
            </main>
         </div>
      </div>
   );
};

export default Dashboard;
